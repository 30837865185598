import React, { PropsWithChildren } from 'react';
// import CssBaseline from '@material-ui/core/CssBaseline';
import Pagination from 'material-ui-flat-pagination';

interface IProps {
	limit: number;
	offset: number;
	total: number;
	onClick: (e: any, offset: number) => void;
}

export default ({ limit, offset, total, onClick }: PropsWithChildren<IProps>) => {
	return <Pagination limit={limit} offset={offset} total={total} onClick={onClick} />;
};

import { Reducer } from 'redux';
import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/workspace';
import * as _ from 'lodash';
import { IWorkspacePayload, IAgendaJobPayload, IWorkspaceResponse } from 'types/workspaces';

export interface IState {
	workspaces: IWorkspacePayload[];
	loading: boolean;
	createWorkspace: boolean | null;
	jobNames: IAgendaJobPayload[];
	workspaceDetail: IWorkspaceResponse;
}

export const initialState: IState = {
	workspaces: [],
	loading: false,
	createWorkspace: false,
	jobNames: [],
	workspaceDetail: {
		totalActiveUsers: 0,
		totalSignups: 0,
		createdAt: null,
		lastActivityDate: null,
		totalPosts: 0,
		totalTransactions: 0,
		totalSubscriptions: 0,
		success: false,
	},
};

export const reducer: Reducer<IState, Actions> = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LOAD_WORKSPACES:
			return { ...state, loading: true };
		case ActionTypes.LOAD_WORKSPACES_SUCCESS:
			return {
				...state,
				loading: false,
				workspaces: action.payload.workspaces,
			};
		case ActionTypes.LOAD_WORKSPACES_FAIL:
			return { ...state, loading: false };
		case ActionTypes.ADD_WORKSPACE_FEATURE:
			return { ...state, loading: true };
		case ActionTypes.ADD_WORKSPACE_FEATURE_SUCCESS:
			return { ...state, loading: false };
		case ActionTypes.ADD_WORKSPACE_FEATURE_FAIL:
			return { ...state, loading: false };
		case ActionTypes.REMOVE_WORKSPACE:
			return { ...state, loading: true };
		case ActionTypes.REMOVE_WORKSPACE_SUCCESS:
			return { ...state, loading: false };
		case ActionTypes.REMOVE_WORKSPACE_FAIL:
			return { ...state, loading: false };
		case ActionTypes.CREATE_WORKSPACE:
			return { ...state, createWorkspace: true };
		case ActionTypes.CREATE_WORKSPACE_SUCCESS:
			return { ...state, createWorkspace: null };
		case ActionTypes.CREATE_WORKSPACE_FAIL:
			return { ...state, createWorkspace: false };
		case ActionTypes.UPDATE_WORKSPACE:
			return { ...state, loading: true };
		case ActionTypes.UPDATE_WORKSPACE_SUCCESS:
			return { ...state, loading: true };
		case ActionTypes.LOAD_WORKSPACE_DETAIL_SUCCESS:
			const {
				totalActiveUsers,
				totalSignups,
				createdAt,
				lastActivityDate,
				totalPosts,
				totalTransactions,
				totalSubscriptions,
			} = action.payload;
			return {
				...state,
				workspaceDetail: {
					totalActiveUsers,
					totalSignups,
					createdAt,
					lastActivityDate,
					totalPosts,
					totalTransactions,
					totalSubscriptions,
				},
			};
		case ActionTypes.UPDATE_WORKSPACE_FAIL:
			return { ...state, loading: false };
		case ActionTypes.GET_AGENDA_JOB_NAMES:
			return { ...state, loading: true };
		case ActionTypes.GET_AGENDA_JOB_NAMES_SUCCESS:
			return { ...state, loading: false, jobNames: action.payload.jobs };
		case ActionTypes.GET_AGENDA_JOB_NAMES_FAIL:
			return { ...state, loading: false };
	}
	return state;
};

export const getWorkSpace = (s: IState) => s.workspaces;
export const getActiveWorkSpace = (s: IState) => s.workspaces.filter(x => x.active);
export const getLoading = (s: IState) => s.loading;
export const getCreated = (s: IState) => s.createWorkspace;
export const jobNames = (s: IState) => s.jobNames;
export const getWorkspaceDetail = (s: IState) => s.workspaceDetail;

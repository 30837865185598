import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import {
	ActionTypes,
	LoadProductsRevenueAction,
	LoadProductsRevenueSuccessAction,
	LoadProductsRevenueFailAction,
} from 'store/actions/productsRevenue';
import RevenueService from 'services/revenue';
import StatsService from 'services/stats';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';

export const loadProductsRevenueEpic: Epic<
	Actions,
	LoadProductsRevenueSuccessAction | LoadProductsRevenueFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadProductsRevenueAction>(ActionTypes.LOAD_PRODUCTS_REVENUE),
		switchMap(action =>
			from(
				Promise.all([
					RevenueService.loadProductsRevenue(action.period),
					StatsService.loadSalesStats(action.period),
				]),
			).pipe(
				map(
					([revenue, stats]) =>
						new LoadProductsRevenueSuccessAction(
							action.period,
							revenue.subscriptions,
							stats.byDay,
						),
				),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadProductsRevenueFailAction(action.period, error));
				}),
			),
		),
	);

export default [loadProductsRevenueEpic];

import { createSelector } from 'reselect';
import * as stats from 'store/reducers/stats';
import { IState } from 'store/reducers';

const statsState = (s: IState) => s.stats;

/* users */

export const getUsersLoading = createSelector(statsState, stats.usersLoading);

export const getUsersLoadingError = createSelector(statsState, stats.usersLoadingError);

export const getUsers = createSelector(statsState, stats.users);

export const getUsersTotal = createSelector(getUsers, stats.usersTotal);

export const getUsersData = createSelector(getUsers, stats.usersData);

/* signups */

export const getSignupsLoading = createSelector(statsState, stats.signupsLoading);

export const getSignupsLoadingError = createSelector(statsState, stats.signupsLoadingError);

const getSignups = createSelector(statsState, stats.signups);

export const getSignupsTotal = createSelector(getSignups, stats.signupsTotal);

export const getAbandonSignupsTotal = createSelector(getSignups, stats.signupsAbandonTotal);

export const getHealthStatsLoading = createSelector(statsState, stats.healthStatsLoading);

export const getHealthStats = createSelector(statsState, stats.healthStats);

export const getSignupsDayData = createSelector(getSignups, stats.signupsDayData);

export const getAbandonSignupsData = createSelector(getSignups, stats.signupsAbandonData);

/* sales */

export const getSalesLoading = createSelector(statsState, stats.salesLoading);

export const getSalesLoadingError = createSelector(statsState, stats.salesLoadingError);

export const getSales = createSelector(statsState, stats.sales);

export const getSalesTotal = createSelector(getSales, stats.salesTotal);

export const getSalesData = createSelector(getSales, stats.salesData);

/* revenue */

export const getRevenueLoading = createSelector(statsState, stats.revenueLoading);

export const getRevenueLoadingError = createSelector(statsState, stats.revenueLoadingError);

export const getRevenue = createSelector(statsState, stats.revenue);

export const getRevenueTotal = createSelector(getRevenue, stats.revenueTotal);

export const getRevenueData = createSelector(getRevenue, stats.revenueData);

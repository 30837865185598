import React from 'react';
import { ProductContainer } from 'scenes/Product/style';
import Avatar from '@material-ui/core/Avatar';
import { theme, colors } from 'utils/theme';
import Button from '@material-ui/core/Button';
import {
	Months,
	ButtonArea,
	Detail,
	Revenue,
	Edit,
	AvatarArea,
	ProductDetail,
	ProductDescription,
	EditArea,
	DetailArea,
	TotalReveneArea,
	SoldArea,
} from './style';
import { IProductPayload } from 'types/product';
import { Link } from 'react-router-dom';

interface IProps {
	item: IProductPayload;
	onPressEdit: (...ags: any) => void;
	path: string;
	selectedItem: (item: any) => void;
}

const items = [
	{
		backgroundColor: '#dfe3ff',
		color: '#6173fe',
		marginLeft: 0,
		title: undefined,
	},
	{
		backgroundColor: '#ffe3d8',
		color: '#f87e51',
		marginLeft: 20,
		title: 'Price',
	},
	{
		backgroundColor: '#fff5dc',
		color: '#fcce54',
		marginLeft: 20,
		title: 'Store',
	},
];
const ProductItem = ({ item, path, onPressEdit, selectedItem }: IProps) => {
	const {
		name,
		type,
		months,
		image,
		description,
		price,
		coins,
		store,
		archived,
		sale,
		revenue,
	} = item;
	const typeText = type == 'subscription' ? 'Subscription' : 'Coins';

	return (
		<ProductContainer>
			<div style={{ flex: 0.2, display: 'flex' }}>
				<div style={{ flex: 1, display: 'flex' }}>
					<AvatarArea>
						<Avatar alt="Remy Sharpp" src={image} style={{ width: 64, height: 64 }} />
					</AvatarArea>
					<ProductDetail>
						<div style={{ flex: 0.5, alignItems: 'center', display: 'flex' }}>
							<p
								style={{
									fontSize: 17,
									color: archived ? 'red' : theme.colors.dimBlack,
								}}
							>
								{name}
							</p>
						</div>
						<div style={{ flex: 0.5, display: 'flex' }}>
							<p style={{ fontSize: 13 }}>{typeText}</p>
						</div>
					</ProductDetail>
				</div>
			</div>
			<ProductDescription>
				<div style={{ flex: 1, display: 'flex' }}>
					{items.map((c, i) => (
						<div
							style={{
								flex: 1,
								display: 'flex',
								flexDirection: 'column',
								marginLeft: c.marginLeft,
							}}
						>
							<div style={{ flex: 0.5, display: 'flex', alignItems: 'center' }}>
								<Months>
									{c.title
										? c.title
										: type === 'subscription'
										? 'Months'
										: '# of coins'}
								</Months>
							</div>
							<div style={{ flex: 0.5, display: 'flex' }}>
								<ButtonArea
									style={{
										backgroundColor: c.backgroundColor,
										color: c.color,
									}}
								>
									{i == 2
										? store
										: i == 1
										? price
										: i == 0 && type === 'subscription'
										? months
										: coins}
								</ButtonArea>
							</div>
						</div>
					))}
					<div style={{ flex: 7, display: 'flex' }}></div>
				</div>
			</ProductDescription>
			<DetailArea>
				<Detail>{description}</Detail>
			</DetailArea>
			<div style={{ flex: 0.15, display: 'flex', paddingLeft: 24 }}>
				<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
					<TotalReveneArea>
						<Revenue>Total Revenue</Revenue>
						<Revenue>$ {revenue}</Revenue>
					</TotalReveneArea>
					<SoldArea>
						<Revenue>Sold today</Revenue>
						<Revenue>{sale}</Revenue>
					</SoldArea>
					<div style={{ flex: 0.6, display: 'flex' }} />
				</div>
			</div>

			<EditArea>
				<Link style={{ color: colors.white, textDecoration: 'none' }} to={path}>
					<Button
						style={{ backgroundColor: '#6173fe', width: 104 }}
						onClick={() => {
							onPressEdit();
							selectedItem(item);
						}}
					>
						<Edit>Edit</Edit>
					</Button>
				</Link>
			</EditArea>
		</ProductContainer>
	);
};
export default ProductItem;

import ApiService from 'services/api';
import { IProductsRevenueResponse } from 'types/revenue';
import { LoadItemsPeriod, getDateQueriesByPeriod } from 'utils/dateQueries';

class RevenueService {
	loadProductsRevenue(period: LoadItemsPeriod): Promise<IProductsRevenueResponse> {
		const query = getDateQueriesByPeriod(period);
		return ApiService.get<IProductsRevenueResponse>('admin/dashboard/revenue/by/product', {
			query,
			authorized: true,
		});
	}
}

export default new RevenueService();

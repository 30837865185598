import ApiService from 'services/api';
import {
	IUsersResponse,
	IActiveUserResponse,
	RequestLoadActiveUserParams,
	RequestMarkUserArchiveParams,
	IAppUserArchiveResponse,
	UserSearchRequestParams,
	RequestNewEmailUserParams,
} from 'types/users';
import { getRequestedDateQueries } from 'utils/dateQueries';

class UsersService {
	loadUsers(): Promise<IUsersResponse> {
		const query = getRequestedDateQueries({ months: 1 });
		return ApiService.get<IUsersResponse>('admin/dashboard/users', { query, authorized: true });
	}
	loadActiveUsers(params: RequestLoadActiveUserParams): Promise<IActiveUserResponse> {
		return ApiService.get<IActiveUserResponse>('admin/dashboard/users/list', {
			query: { ...params },
			authorized: true,
		});
	}
	searchUsers(params: UserSearchRequestParams): Promise<IActiveUserResponse> {
		return ApiService.get<IActiveUserResponse>('admin/dashboard/users/list/search', {
			query: { ...params },
			authorized: true,
		});
	}
	MarkUserArchive(params: RequestMarkUserArchiveParams): Promise<IAppUserArchiveResponse> {
		return ApiService.post<IAppUserArchiveResponse>(
			'admin/dashboard/users/archive',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}
	AddNewEmailUser(params: RequestNewEmailUserParams): Promise<IAppUserArchiveResponse> {
		return ApiService.post<IAppUserArchiveResponse>(
			'admin/account/email',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}
	UpdateUser(params: RequestNewEmailUserParams): Promise<IAppUserArchiveResponse> {
		return ApiService.put<IAppUserArchiveResponse>(
			'admin/account',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}
}

export default new UsersService();

import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import {
	ActionTypes,
	LoadCoinsSalesAction,
	LoadCoinsSalesSuccessAction,
	LoadCoinsSalesFailAction,
} from 'store/actions/coinsSales';
import CoinsService from 'services/coins';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';

export const loadCoinsSalesEpic: Epic<
	Actions,
	LoadCoinsSalesSuccessAction | LoadCoinsSalesFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadCoinsSalesAction>(ActionTypes.LOAD_COINS_SALES),
		switchMap(() =>
			from(CoinsService.loadCoinsSales()).pipe(
				map(response => new LoadCoinsSalesSuccessAction(response)),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadCoinsSalesFailAction(error));
				}),
			),
		),
	);

export default [loadCoinsSalesEpic];

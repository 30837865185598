import ApiService from 'services/api';
import { IFundraiserDonationsResponse } from '../types/fundraiserDonations';

class FundraisersService {
	getFundraiserDonations(): Promise<IFundraiserDonationsResponse> {
		return ApiService.get<IFundraiserDonationsResponse>(
			`fundraising/alldonations?returnAllWorkspaces=false`,
			{
				authorized: true,
			},
		);
	}
}

export default new FundraisersService();

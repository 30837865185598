import styled from 'styled-components';
import { colors } from '@constants';

export const Container = styled.div`
	flex: 1;
	display: flex;
	overflow: auto;
`;
export const LeftSection = styled.div`
	display: flex;
	flex: 0.15;
`;

export const RightSection = styled.div`
	display: flex;
	flex: 0.85;
	background: ${colors.white};
`;

export const InnerRightSection = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: scroll;
	background-color: rgb(242, 243, 248);
`;

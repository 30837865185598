import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import { ISummaryResponse } from 'types/summary';

export enum ActionTypes {
	LOAD_SUMMARY = '[summary] LOAD_SUMMARY',
	LOAD_SUMMARY_SUCCESS = '[summary] LOAD_SUMMARY_SUCCESS',
	LOAD_SUMMARY_FAIL = '[summary] LOAD_SUMMARY_FAIL',
}

export class LoadSummaryAction implements Action {
	readonly type = ActionTypes.LOAD_SUMMARY;
}

type LoadSummarySuccessPayload = ISummaryResponse['summary'];

export class LoadSummarySuccessAction implements Action {
	readonly type = ActionTypes.LOAD_SUMMARY_SUCCESS;
	constructor(public payload: LoadSummarySuccessPayload) {}
}

export class LoadSummaryFailAction implements Action {
	readonly type = ActionTypes.LOAD_SUMMARY_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export type Actions = LoadSummaryAction | LoadSummarySuccessAction | LoadSummaryFailAction;

import React, { Component } from 'react';
import { Container } from 'scenes/Users/styles';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';

import {
	getGroupLoading,
	getAllSubscriptions,
	getCheckedValue,
	getSearchedValue,
} from 'store/selectors/group';

import { theme } from 'utils/theme';
import { Notice } from 'modules/Dashboard/Widgets/NewUsersWidget/styles';
import ContainerCenter from 'components/ContainerCenter';
import { CircleSpinner } from 'react-spinners-kit';
import SubHeader from 'components/SubHeader';
import {
	FetchGroupAction,
	updateChecked,
	updateSearchValue,
	updateSaveType,
	updateGroupName,
	updateGroupImage,
	updateGroupArchive,
	updateGroupDescription,
	updateGroupId,
} from 'store/actions/group';
import { GroupItem } from 'components/GroupItem';
import { SAVE_TYPE } from 'store/reducers/group';
import { GroupsPayload } from 'types/group';

interface IProps {}

interface IStoreProps {
	loading: ReturnType<typeof getGroupLoading>;
	data: ReturnType<typeof getAllSubscriptions>;

	searchValue: ReturnType<typeof getSearchedValue>;
	checked: ReturnType<typeof getCheckedValue>;
}

interface IDispatchProps {
	fetchAllGroups: () => void;
	updateSearchedValue: (value: string) => void;
	updateCheckedValue: (value: boolean) => void;
	updateSaveType: (value: string) => void;
	updateGroupName: (value: string) => void;
	updateGroupImage: (value: string) => void;
	updateGroupArchive: (value: boolean) => void;
	updateGroupDescription: (value: string) => void;
	updateGroupId: (value: string) => void;
}

interface IProps extends IStoreProps, IDispatchProps {}

class GroupScene extends Component<IProps> {
	componentDidMount() {
		this.props.fetchAllGroups();
	}

	render() {
		const {
			updateSearchedValue,
			checked,
			updateCheckedValue,
			data,
			updateSaveType,
		} = this.props;
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader title={''} shadow={false} style={{ flex: 1 }} />
				</div>
				<div style={{ width: '100%' }}>
					<SubHeader
						title={'Groups'}
						searchbar
						searchPlaceHolder={'Search'}
						// searchValue={searchValue}
						buttonText={'Add Groups'}
						total={data.length || null}
						link={'/add/subscription'}
						showCheckbox
						checkBoxText={'Include archived Groups'}
						checkboxValue={checked}
						onChangeCheckbox={() => {
							if (!checked) updateCheckedValue(true);
							if (checked) updateCheckedValue(false);
						}}
						onChangeSearchBarText={e => updateSearchedValue(e.target.value)}
						onPressButton={() => updateSaveType(SAVE_TYPE.NEW)}
					/>
				</div>
				<div style={{ display: 'flex', flex: 0.8, flexDirection: 'column' }}>
					{this.renderContent()}
				</div>
			</Container>
		);
	}

	private renderContent = () => {
		const { loading, data } = this.props;
		if (loading) {
			return (
				<ContainerCenter>
					<CircleSpinner size={25} color={theme.colors.cornflowerBlue} />
				</ContainerCenter>
			);
		}
		if (!data || !data.length) {
			return (
				<ContainerCenter>
					<Notice>Don't have any subscriptions</Notice>
				</ContainerCenter>
			);
		}
		return this.renderGroupList();
	};

	private renderGroupList = () => {
		const {
			data,
			updateSaveType,
			updateGroupName,
			updateGroupImage,
			updateGroupArchive,
			updateGroupDescription,
			updateGroupId,
		} = this.props;
		return data.map(s => (
			<GroupItem
				item={s}
				onPressItem={(item: GroupsPayload) => {
					const { name, description, image, archived, subscriptionId } = item;
					updateSaveType(SAVE_TYPE.UPDATE);
					updateGroupName(name);
					updateGroupDescription(description);
					updateGroupImage(image);
					updateGroupId(subscriptionId);
					updateGroupArchive(archived);
				}}
			/>
		));
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getGroupLoading(state),
		data: getAllSubscriptions(state),
		searchValue: getSearchedValue(state),
		checked: getCheckedValue(state),
	}),
	dispatch => ({
		fetchAllGroups: () => dispatch(new FetchGroupAction()),
		updateSearchedValue: (value: string) => dispatch(updateSearchValue(value)),
		updateCheckedValue: (value: boolean) => dispatch(updateChecked(value)),
		updateSaveType: (value: string) => dispatch(updateSaveType(value)),
		updateGroupName: (value: string) => dispatch(updateGroupName(value)),
		updateGroupImage: (value: string) => dispatch(updateGroupImage(value)),
		updateGroupArchive: (value: boolean) => dispatch(updateGroupArchive(value)),
		updateGroupId: (value: string) => dispatch(updateGroupId(value)),
		updateGroupDescription: (value: string) => dispatch(updateGroupDescription(value)),
	}),
)(GroupScene);

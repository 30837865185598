import { IUsersResponse } from 'types/users';

const successResponse: IUsersResponse = {
	users: {
		today: [
			{
				name: 'Rushi Bah',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/9fa97d90-ccf0-491f-8c5a-dde02a84b5b1_IMG_0111.HEIC',
				jobTitle: null,
			},
			{
				name: 'baja bab',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/5f0a534a-9b16-45c2-aed2-7aa66ae91da2_5b72385e-308b-4846-b695-26f0e7a92ff8.jpg',
				jobTitle: null,
			},
		],
		monthly: [
			{
				name: 'Ali Bawa',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1561484040913_1561484030330_20190625_151445.jpg',
				jobTitle: null,
			},
			{
				name: 'Ömñér Īliâ',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1561548962018_1561548832543_fbPhoto1408231292628022',
				jobTitle: null,
			},
			{
				name: 'Vvbh Cdddd',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1561550058438_1561550040381_fbPhoto875000395951117',
				jobTitle: null,
			},
			{
				name: 'Fredrick Roby',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562086964070_626280337_memegenerator-crop-583779762.277721.png',
				jobTitle: null,
			},
			{
				name: 'Shs Hshd',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562087407976_1562087395900_fbPhoto1408231292628022',
				jobTitle: null,
			},
			{
				name: 'Hhg Hhf',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562088027812_1562087998273_magazine-unlock-01-2.3.1390-_53A80E8DFDB9DCB137C18BCD58B26A1C.jpg',
				jobTitle: null,
			},
			{
				name: 'Hhh Yyy',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562095281056_1562095268376_magazine-unlock-05-2.3.1390-_8880E9535347ABB6C85AECB1651B3DF5.jpg',
				jobTitle: null,
			},
			{
				name: 'Lex Storm',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562148222143_1562148179976_Ultimate-Chocolate-Cake-Feature-new.jpg',
				jobTitle: null,
			},
			{
				name: 'Artem Gordeev',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562587905755_1775334987_IMG_2278.JPG',
				jobTitle: null,
			},
			{
				name: 'This Here',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562213631986_1562213617864_fbPhoto10158540106707796',
				jobTitle: null,
			},
			{
				name: 'Jennie Jenkins',
				picture: null,
				jobTitle: null,
			},
			{
				name: 'Jennie Jenkins',
				picture: null,
				jobTitle: null,
			},
			{
				name: 'Jennie Jenkins',
				picture: null,
				jobTitle: null,
			},
			{
				name: 'Jennie Jenkins',
				picture: null,
				jobTitle: null,
			},
			{
				name: 'New User',
				picture: null,
				jobTitle: null,
			},
			{
				name: 'Aida Mann',
				picture: null,
				jobTitle: null,
			},
			{
				name: 'Aida Mann',
				picture: null,
				jobTitle: null,
			},
			{
				name: 'New User',
				picture: null,
				jobTitle: null,
			},
			{
				name: 'Rushi03 Bharad53',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562325558835_1562325450822_IMG_0005.JPG',
				jobTitle: null,
			},
			{
				name: 'Blocked Account',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562378188211_1562378158105_fbPhoto10157986834387796',
				jobTitle: null,
			},
			{
				name: 'Anothe Blocked',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562378842758_1562378831839_fbPhoto10157986834387796',
				jobTitle: null,
			},
			{
				name: 'Anothe Testin',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562379628972_1562379613757_fbPhoto10157073950957796',
				jobTitle: null,
			},
			{
				name: 'Sda Asd',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562495252724_174246199_Screenshot_20190703-195432_vyoo.jpg',
				jobTitle: null,
			},
			{
				name: 'Fc Fg',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562527565986_1562527543992_magazine-unlock-01-2.3.1397-_02f2f2462d1d4dc09acf38d4f96a53c8.jpg',
				jobTitle: null,
			},
			{
				name: 'Gegsghs Hhheheheh',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562531936797_1562531931417_thumb-e85a98cd-71e9-4eea-9d08-1af14b01920f.jpeg',
				jobTitle: null,
			},
			{
				name: 'Dfygghh Ugfbh',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562534651273_1562534632035_thumb-e85a98cd-71e9-4eea-9d08-1af14b01920f.jpeg',
				jobTitle: null,
			},
			{
				name: 'Test Test',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/7e05f06a-1be6-4efc-b561-235b1630a6e0_2415b4b1-8ac0-4343-a938-17247dfe4cf5.jpg',
				jobTitle: null,
			},
			{
				name: 'bss vshh',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562591424534_1798999785_IMG-20190708-WA0053.jpg',
				jobTitle: null,
			},
			{
				name: 'Karim Khamwani',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562591998650_1562591988295_IMG_4613.PNG',
				jobTitle: 'Software Enigneer, Makeen',
			},
			{
				name: 'Test Testtest',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562606940670_1562606926311_magazine-unlock-01-2.3.1397-_c1c7074e730c4735930fd3f640e0f9ed.jpg',
				jobTitle: null,
			},
			{
				name: 'Test2 Test',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/fbaa28e1-236f-4046-aebf-5abf7fb3148c_5986a7a9-740e-4e0d-9c22-ae03aa8668d9.jpg',
				jobTitle: null,
			},
			{
				name: 'Mabelle Fisher',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/curiousoffice/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Kaylie Cremin',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/SULiik/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Nico Schultz',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/kylefoundry/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Filiberto Ernser',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/chandlervdw/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Emelie Block',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/thinkleft/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Kylee McKenzie',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/aiiaiiaii/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Cletus Rowe',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/klimmka/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Travis Harber',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/jacobbennett/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Imogene VonRueden',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/juangomezw/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Emelie Block',
				picture: 'https://s3.amazonaws.com/uifaces/faces/twitter/thinkleft/128.jpg',
				jobTitle: null,
			},
			{
				name: 'Artem Gordeev',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/3148ef71-af41-415a-acf8-26f1280de508_IMG_0577.JPG',
				jobTitle: null,
			},
			{
				name: 'Hello123 baah',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562845033673_1339857566_IMG-20190711-WA0295.jpg',
				jobTitle: null,
			},
			{
				name: 'nnsja nJ',
				picture:
					'https://dha939bkqu76d.cloudfront.net/uploads/1562845207358_1562845198604_IMG-20190711-WA0296.jpg',
				jobTitle: null,
			},
			{
				name: 'Test Wesy',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/8d4d1327-5334-4bc7-9847-27ff9d4e42f0_thumb-8800aaf1-e92c-469a-b5fa-b9021121ceab.jpeg',
				jobTitle: null,
			},
			{
				name: 'Teeee Frrr',
				picture: null,
				jobTitle: null,
			},
			{
				name: 'Ghvvg Vhvvv',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/76675744-8cad-420e-abc6-7b89e6d9df24_thumb-8800aaf1-e92c-469a-b5fa-b9021121ceab.jpeg',
				jobTitle: null,
			},
			{
				name: 'Mister Love',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/307a5982-7ac8-4743-9a33-65adfe7a8bb9_IMG_0111.HEIC',
				jobTitle: null,
			},
			{
				name: 'One Oleg',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/c632ac0c-652c-4888-b410-38705bcaa0f1_D966AFBD-E4A1-4228-B02B-4E2E2D42EEF3.jpg',
				jobTitle: null,
			},
			{
				name: 'Mario Test',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/f2b5cd97-78e8-4823-b649-b7997bbcd1a8_12FCBA24-3CE8-4520-945E-B282A3D4347A.jpg',
				jobTitle: null,
			},
			{
				name: 'Crash Debugger',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/a4b9c777-31dc-4e0f-aac5-5fadd84dcd88_IMG_0111.HEIC',
				jobTitle: null,
			},
			{
				name: 'Ksjjsjs Ooiiue',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/06576d5a-4720-4086-81a3-c3bd54923773_IMG_0005.JPG',
				jobTitle: null,
			},
			{
				name: 'Deed Goods',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/782ea4ca-779f-4d52-bf75-c1b15bb866e9_IMG_0003.JPG',
				jobTitle: null,
			},
			{
				name: 'Another Tester',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/cb9ab026-1c97-42b2-9460-438941dd9c47_IMG_0001.JPG',
				jobTitle: null,
			},
			{
				name: 'Sfsdfds Sdfghfhfgh',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/8ed4fe04-a310-4394-9459-d9d6d1e529f6_IMG_0002.JPG',
				jobTitle: null,
			},
			{
				name: 'Lex Luthor',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/7bf16b96-8d01-490b-a2a4-1dec8fbde740_Screenshot_20190716-194736_Facebook.jpg',
				jobTitle: null,
			},
			{
				name: 'Fox Bear',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/fc2734e1-507d-42c5-bb98-dc7db5727d7a_memegenerator-crop-585040254.031046.png',
				jobTitle: null,
			},
			{
				name: 'Asd Asd',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/13badc72-6fd1-423d-8522-f8eb702ae2d2_IMG_0111.HEIC',
				jobTitle: null,
			},
			{
				name: 'baha bBa',
				picture:
					'https://vyoo-staging.s3.us-west-2.amazonaws.com/uploads/7fa73706-8ad8-40bb-9a3e-a67ab559a0a2_58507954037__A3B51763-DA8D-4935-A302-396D282DD45A.JPG',
				jobTitle: null,
			},
		],
	},
};

export default {
	users: {
		get: successResponse,
	},
};

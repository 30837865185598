import styled from 'styled-components';

export const LeftContainer = styled.div`
    flex: 0.5
	display:flex
	align-items:center
`;

export const RightContainer = styled.div`
    flex: 0.5
    justify-content: flex-end;
	align-items:center;
    display:flex
`;

export const AddUsers = styled.p`
	color: #6173fe;
	font-size: 14px;
	text-transform: none;
`;

export const Users = styled.p`
	color: #434349;
	font-size: 16px;
	margin-left: 15px;
	font-family: Poppins;
	padding-right: 16px;
`;

export const Total = styled.p`
	color: #959cb6;
	font-size: 13px;
	padding-left: 16px;
	min-width: 60px;
	padding-right: 16px;
`;

export const Divider = styled.div`
	border-left: 1px solid #f2f3f8;
	border-right: 1px solid #f2f3f8;
	// right: 249px;
	top: 10px;
	height: 25px;
	background-color: #f2f3f8;
`;

export const SubTitle = styled.p`
	width: 220px;
	height: 19px;
	margin-left: 10px;
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #959cb6;
`;
export const ArchivedUserText = styled.p`
	min-width: 200px;
	font-family: Poppins;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #434349;
	padding-top: 10px;
`;

import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import {
	ActionTypes,
	LoadUsersAction,
	LoadUsersSuccessAction,
	LoadUsersFailAction,
	loadActiveUserAction,
	LoadActiveUsersSuccessAction,
	LoadActiveUsersFailAction,
	MarkUserArchiveAction,
	MarkUserArchiveSuccessAction,
	MarkUserArchiveFailAction,
	UserSearchAction,
	UserSearchSuccessAction,
	UserSearchFailAction,
	CreateEmailUserActionSuccessAction,
	CreateEmailUserActionFailAction,
	CreateEmailUserAction,
	UpdateUserSuccessAction,
	UpdateUserFailAction,
	UpdateUserAction,
} from 'store/actions/users';
import UsersService from 'services/users';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';

export const loadUsersEpic: Epic<
	Actions,
	LoadUsersSuccessAction | LoadUsersFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadUsersAction>(ActionTypes.LOAD_USERS),
		switchMap(() =>
			from(UsersService.loadUsers()).pipe(
				map(response => new LoadUsersSuccessAction(response.users)),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadUsersFailAction(error));
				}),
			),
		),
	);

export const loadActiveUsersEpic: Epic<
	Actions,
	LoadActiveUsersSuccessAction | LoadActiveUsersFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, loadActiveUserAction>(ActionTypes.LOAD_ACTIVE_USERS),
		switchMap(action =>
			from(UsersService.loadActiveUsers(action.params)).pipe(
				map(response => new LoadActiveUsersSuccessAction(response)),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadActiveUsersFailAction(error));
				}),
			),
		),
	);

export const SearchUsersEpic: Epic<
	Actions,
	UserSearchSuccessAction | UserSearchFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, UserSearchAction>(ActionTypes.USER_SEARCH),
		switchMap(action =>
			from(UsersService.searchUsers(action.params)).pipe(
				map(response => new UserSearchSuccessAction(response)),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new UserSearchFailAction(error));
				}),
			),
		),
	);

export const MarkUserArchiveEpic: Epic<
	Actions,
	MarkUserArchiveSuccessAction | MarkUserArchiveFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, MarkUserArchiveAction>(ActionTypes.MARK_USER_ARCHIVE),
		switchMap(action =>
			from(UsersService.MarkUserArchive(action.params)).pipe(
				map(response => {
					const text = response.success ? 'archived' : 'unarchived';
					toast.success('User ' + text + ' successfully', { position: 'top-right' });
					return new MarkUserArchiveSuccessAction(response.success);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new MarkUserArchiveFailAction(error));
				}),
			),
		),
	);

export const CreateEmailUser: Epic<
	Actions,
	CreateEmailUserActionSuccessAction | CreateEmailUserActionFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, CreateEmailUserAction>(ActionTypes.ADD_EMAIL_USER),
		switchMap(action =>
			from(UsersService.AddNewEmailUser(action.params)).pipe(
				map(response => {
					toast.success('User created successfully', { position: 'top-right' });
					return new CreateEmailUserActionSuccessAction(response.success);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new CreateEmailUserActionFailAction(error));
				}),
			),
		),
	);

export const UpdateUser: Epic<
	Actions,
	UpdateUserSuccessAction | UpdateUserFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, UpdateUserAction>(ActionTypes.UPDATE_USER),
		switchMap(action =>
			from(UsersService.UpdateUser(action.params)).pipe(
				map(response => {
					toast.success('User updated successfully', { position: 'top-right' });
					return new UpdateUserSuccessAction(response.success);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new UpdateUserFailAction(error));
				}),
			),
		),
	);

export default [
	loadUsersEpic,
	loadActiveUsersEpic,
	MarkUserArchiveEpic,
	SearchUsersEpic,
	CreateEmailUser,
	UpdateUser,
];

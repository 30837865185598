import { ofType, Epic } from 'redux-observable';
import { map } from 'rxjs/operators';
import { ActionTypes, SetThemeAction } from 'store/actions/settings';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { THEME } from 'utils/theme';
import { getTheme } from 'store/selectors/settings';

export const switchThemeEpic: Epic<Actions, SetThemeAction, IState> = (action$, state$) =>
	action$.pipe(
		ofType(ActionTypes.TOOGLE_THEME),
		map(
			() =>
				new SetThemeAction(
					getTheme(state$.value) === THEME.LIGHT ? THEME.DARK : THEME.LIGHT,
				),
		),
	);

export default [switchThemeEpic];

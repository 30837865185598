import React, { Component, Fragment } from 'react';
import moment from 'moment';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';
import { Container } from 'scenes/Users/styles';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import SubHeader from 'components/SubHeader';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { LoadFundraisersAction } from 'store/actions/fundraisers';
import { getLoading, getFundraisers } from 'store/selectors/fundraisers';
import { CircleSpinner } from 'react-spinners-kit';
import ContainerCenter from 'components/ContainerCenter';
import { theme } from 'utils/theme';
import { Notice } from 'modules/Dashboard/Widgets/NewUsersWidget/styles';
import { IFundraiserPayload, FundraiserStatus } from 'types/fundraisers';
import { Styles } from 'react-select/src/styles';
import Select from 'react-select';
import AuthService from 'services/auth';

interface IFundraiserStatusOption {
	value: null | FundraiserStatus;
	label: string;
}

interface IProps {}

interface IStoreProps {
	loading: ReturnType<typeof getLoading>;
	fundraisers: ReturnType<typeof getFundraisers>;
}

interface IDispatchProps {
	fetchAllFundraisers: () => void;
}

interface IProps extends IStoreProps, IDispatchProps, RouteComponentProps {}

class FundraisersScene extends Component<IProps> {
	state: {
		sortedBy: keyof IFundraiserPayload;
		sortedOrder: 1 | -1;
		statusFilter: IFundraiserStatusOption;
	} = {
		sortedBy: 'createdAt',
		sortedOrder: -1,
		statusFilter: { value: null, label: 'None' },
	};

	componentDidMount() {
		this.props.fetchAllFundraisers();
	}

	render() {
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader title={''} shadow={false} style={{ flex: 1 }} />
				</div>
				<div style={{ width: '100%' }}>
					<SubHeader title={'Fundraisers'} filter={this.renderFundraiserStatusFilter()} />
				</div>
				{this.renderContent()}
			</Container>
		);
	}

	private sort = ({ label, order }: { label: string; order: number }) => {
		this.setState({
			sortedBy: label,
			sortedOrder: order,
		});
	};

	private renderHeaderCell = ({
		name,
		label,
		minWidth,
	}: {
		name: string;
		label: string;
		minWidth: number;
	}) => {
		const onClick = () => {
			if (this.state.sortedBy === label) {
				this.sort({ label, order: -this.state.sortedOrder });
			} else {
				this.sort({ label, order: -1 });
			}
		};
		return (
			<TableCell style={{ minWidth, cursor: 'pointer' }} align="left" onClick={onClick}>
				{name}
			</TableCell>
		);
	};

	private renderFundraiserStatusFilter = () => {
		const items: IFundraiserStatusOption[] = [
			{ value: null, label: 'None' },
			{ value: FundraiserStatus.archived, label: FundraiserStatus.archived },
			{ value: FundraiserStatus.pending, label: FundraiserStatus.pending },
			{ value: FundraiserStatus.active, label: FundraiserStatus.active },
			{ value: FundraiserStatus.paused, label: FundraiserStatus.paused },
			{ value: FundraiserStatus.ended, label: FundraiserStatus.ended },
		];

		const styles: Styles<any, any> = {
			container: (base: any) => ({
				...base,
				width: 120,
				height: 35,
				'z-index': 10,
			}),
			control: (base: any) => ({
				...base,
				minHeight: 'auto',
				border: 'none',
				backgroundColor: theme.colors.titanWhite,
			}),
			indicatorSeparator: (base: any) => ({
				...base,
				display: 'none',
			}),
			dropdownIndicator: (base: any) => ({
				...base,
				color: theme.colors.cornflowerBlue,
			}),
			singleValue: (base: any) => ({
				...base,
				color: theme.colors.cornflowerBlue,
			}),
		};

		const onChange = async (option: any) => {
			this.setState({
				statusFilter: option,
			});
		};

		return (
			<Fragment>
				<Select
					options={items}
					isSearchable={false}
					value={this.state.statusFilter}
					styles={styles}
					onChange={onChange}
				/>
			</Fragment>
		);
	};

	private renderContent = () => {
		const { loading, fundraisers } = this.props;
		if (loading) {
			return (
				<ContainerCenter>
					<CircleSpinner size={25} color={theme.colors.cornflowerBlue} />
				</ContainerCenter>
			);
		}
		if (!fundraisers || !fundraisers.length) {
			return (
				<ContainerCenter>
					<Notice>Don't have any fundraisers</Notice>
				</ContainerCenter>
			);
		}
		return this.workFundraisersList();
	};

	private workFundraisersList = () => {
		const { fundraisers } = this.props;
		const mappedFundraisers = fundraisers.map(f => ({
			...f,
			totalContacts: f.totalContacts || 0,
			totalGroups: f.groups ? f.groups.length : 0,
		}));
		const filteredFundraisers = this.state.statusFilter.value
			? mappedFundraisers.filter(fr => fr.status === this.state.statusFilter.value)
			: mappedFundraisers;
		const sortedFundraisers = this.state.sortedBy
			? filteredFundraisers.sort((fr1, fr2) =>
					fr1[this.state.sortedBy] > fr2[this.state.sortedBy]
						? this.state.sortedOrder
						: -this.state.sortedOrder,
			  )
			: filteredFundraisers;
		return (
			<Paper style={{ width: '100%', flex: 1, display: 'flex' }}>
				<TableContainer style={{ flex: 1, display: 'flex' }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{this.renderHeaderCell({
									minWidth: 70,
									name: 'Community',
									label: 'workspace',
								})}
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'Name',
									label: 'name',
								})}
								<TableCell style={{ minWidth: 100 }} align="left">
									Created By
								</TableCell>
								{this.renderHeaderCell({
									minWidth: 40,
									name: 'Groups',
									label: 'totalGroups',
								})}
								{this.renderHeaderCell({
									minWidth: 40,
									name: 'Donations',
									label: 'totalDonations',
								})}
								{this.renderHeaderCell({
									minWidth: 40,
									name: 'Contacts',
									label: 'totalContacts',
								})}
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'Raised/Goal',
									label: 'totalDonated',
								})}
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'Start Date',
									label: 'startDate',
								})}
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'End Date',
									label: 'endDate',
								})}
								{this.renderHeaderCell({
									minWidth: 60,
									name: 'Status',
									label: 'status',
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{sortedFundraisers.map((item, index) => (
								<TableRow key={index}>
									<TableCell align="left">
										{item.workspace.length > 10
											? `${item.workspace.substr(0, 10)}...`
											: item.workspace}
									</TableCell>
									<TableCell align="left">
										{item.name.length > 100
											? `${item.name.substr(0, 100)}...`
											: item.name}
									</TableCell>
									<TableCell align="left">
										{item.creator.firstName} {item.creator.lastName}
									</TableCell>
									<TableCell align="center">{item.totalGroups}</TableCell>
									<TableCell align="center">{item.totalDonations}</TableCell>
									<TableCell align="center">{item.totalContacts}</TableCell>
									<TableCell align="left">
										<Link
											to={`/fundraiserDonations/${item._id}`}
											onClick={() => {
												AuthService.saveWorkspace({
													workspace: item.workspace,
												});
											}}
											style={{ textDecoration: 'none' }}
										>
											${item.totalDonated} / ${item.goalAmount}
										</Link>
									</TableCell>
									<TableCell align="left">
										{moment(item.startDate).format('LL')}
									</TableCell>
									<TableCell align="left">
										{moment(item.endDate).format('LL')}
									</TableCell>
									<TableCell align="left">{item.status}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		);
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getLoading(state),
		fundraisers: getFundraisers(state),
	}),
	dispatch => ({
		fetchAllFundraisers: () => dispatch(new LoadFundraisersAction()),
	}),
)(withRouter(FundraisersScene));

import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import {
	ActionTypes,
	LoadSummaryAction,
	LoadSummarySuccessAction,
	LoadSummaryFailAction,
} from 'store/actions/summary';
import SummaryService from 'services/summary';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';

export const loadSummaryEpic: Epic<
	Actions,
	LoadSummarySuccessAction | LoadSummaryFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadSummaryAction>(ActionTypes.LOAD_SUMMARY),
		switchMap(() =>
			from(SummaryService.loadSummary()).pipe(
				map(response => new LoadSummarySuccessAction(response.summary)),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadSummaryFailAction(error));
				}),
			),
		),
	);

export default [loadSummaryEpic];

import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const Layout = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: 1.5rem;
	padding-left: 1.5rem;
	background-color: ${({ theme }) => theme.colors.whisper};
	flex: 1;
`;

export const Column = styled.div`
	flex: 1;
	min-width: 320px;
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { IState as IStoreState } from 'store/reducers';
import {
	updateActiveUsers,
	MarkUserArchiveAction,
	loadActiveUserAction,
	changeSearchedValue,
	updateCheckedValue,
	UserSearchAction,
} from 'store/actions/users';
import {
	getActiveUsers,
	getArchiveUsers,
	getSelectedItems,
	getSelectedItemsArchiveValue,
	getSearchedValue,
	getCheckedValue,
	getPageLimit,
	getActivePageNumber,
} from 'store/selectors/users';
import Paper from '@material-ui/core/Paper';

import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';
import { Header } from 'scenes/Users/styles';
import Button from '@material-ui/core/Button';
import {
	LeftContainer,
	RightContainer,
	AddUsers,
	Users,
	Total,
	Divider,
	ArchivedUserText,
} from './styles';
import { IActiveUser } from 'types/users';
import * as _ from 'lodash';
import { getUsersTotal } from 'store/selectors/stats';

interface IProps {}

interface IStoreProps {
	totalUsers: ReturnType<typeof getUsersTotal>;
	archivedUsers: ReturnType<typeof getArchiveUsers>;
	activeUsers: ReturnType<typeof getActiveUsers>;
	selectedItems: ReturnType<typeof getSelectedItems>;
	archivedValue: ReturnType<typeof getSelectedItemsArchiveValue>;
	searchedValue: ReturnType<typeof getSearchedValue>;
	checked: ReturnType<typeof getCheckedValue>;
	page: ReturnType<typeof getActivePageNumber>;
	limit: ReturnType<typeof getPageLimit>;
}

interface IDispatchProps {
	updateActiveUsers: (data: IActiveUser[]) => void;
	MarkUserArchive: (userIds: string[], value: boolean) => void;
	loadActiveUsers: (offset: number, limit: number) => void;
	changeSearchedValue: (value: string) => void;
	updateCheckedValue: (value: boolean) => void;
	UserSearchAction: (value: string) => void;
}

interface IProps
	extends IStoreProps,
		IDispatchProps,
		RouteComponentProps<{ id?: string }, any, any> {}

interface IState {
	checked: boolean;
}

class UserInfo extends Component<IProps, IState> {
	state: IState = {
		checked: false,
	};
	renderSearchArea = () => {
		return (
			<Paper style={styles.paper}>
				<InputBase
					style={{
						marginLeft: 8,
						flex: 1,
						width: 49,
						height: 17,
						fontFamily: 'Poppins',
						fontSize: 12,
						fontWeight: 'normal',
						fontStretch: 'normal',
						fontStyle: 'normal',
						lineHeight: 'normal',
						letterSpacing: 'normal',
						color: '#868aa8',
					}}
					value={this.props.searchedValue}
					onChange={event => {
						this.props.changeSearchedValue(event.target.value);
						this.props.UserSearchAction(event.target.value);
					}}
					placeholder="Search.."
				/>
				<IconButton style={{ padding: 10, color: '#6173FE' }} aria-label="search">
					<SearchIcon />
				</IconButton>
			</Paper>
		);
	};

	renderDeleteRowsButton() {
		const { archivedValue, selectedItems, page, limit } = this.props;
		if ((archivedValue || archivedValue === false) && selectedItems.length) {
			return (
				<Button
					style={{ minWidth: 0, backgroundColor: '#DFE3FF', right: 25 }}
					disabled={!this.props.selectedItems.length}
					onClick={() => {
						this.props.MarkUserArchive(selectedItems, archivedValue);
						setTimeout(() => {
							this.props.loadActiveUsers(page + 1, limit);
						}, 1000);
					}}
				>
					<AddUsers>
						{archivedValue ? 'Archived' : 'Un archived'} {selectedItems.length} Selected
						Rows
					</AddUsers>
				</Button>
			);
		}
		return;
	}

	render() {
		const { checked, updateCheckedValue } = this.props;
		return (
			<Header style={styles.header}>
				<LeftContainer>
					<Users>Users</Users>
					<Divider />
					<Total>{this.props.totalUsers || 0} total</Total>
					{this.renderSearchArea()}
					<Divider style={{ marginLeft: 20, marginRight: 10 }} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						{' '}
						<Checkbox
							value="checkedC"
							checked={checked}
							style={{ backgroundColor: 'transparent' }}
							onChange={() => {
								updateCheckedValue(!checked);
								if (!checked) {
									this.props.updateActiveUsers([
										...this.props.archivedUsers,
										...this.props.activeUsers,
									]);
								}
								if (checked) {
									const subtractedArray = _.difference(
										this.props.activeUsers,
										this.props.archivedUsers,
									);
									this.props.updateActiveUsers(subtractedArray);
								}
							}}
						/>
						<ArchivedUserText>Include archive users</ArchivedUserText>
					</div>
				</LeftContainer>
				<RightContainer>
					{this.renderDeleteRowsButton()}
					<Button
						onClick={() => this.props.history.push('/add/user')}
						style={{ minWidth: 0, backgroundColor: '#DFE3FF', right: 18 }}
					>
						<AddUsers>Add user</AddUsers>
					</Button>
				</RightContainer>
			</Header>
		);
	}
}
export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		archivedUsers: getArchiveUsers(state),
		activeUsers: getActiveUsers(state),
		totalUsers: getUsersTotal(state),
		selectedItems: getSelectedItems(state),
		archivedValue: getSelectedItemsArchiveValue(state),
		searchedValue: getSearchedValue(state),
		checked: getCheckedValue(state),
		page: getActivePageNumber(state),
		limit: getPageLimit(state),
	}),
	dispatch => ({
		updateCheckedValue: (value: boolean) => dispatch(updateCheckedValue(value)),
		updateActiveUsers: (data: IActiveUser[]) => dispatch(updateActiveUsers(data)),
		UserSearchAction: (value: string) => dispatch(new UserSearchAction({ value })),
		changeSearchedValue: (value: string) => dispatch(changeSearchedValue(value)),
		MarkUserArchive: (userIds: string[], value: boolean) =>
			dispatch(new MarkUserArchiveAction({ userIds, value })),
		loadActiveUsers: (offset: number, limit: number) =>
			dispatch(new loadActiveUserAction({ offset, limit })),
	}),
)(withRouter(UserInfo));

const styles = {
	paper: {
		display: 'flex',
		alignItems: 'center',
		width: 156,
		height: 32,
		backgroundColor: 'rgb(237, 239, 255)',
	},
	header: {
		height: '50px',
		margin: 0,
		display: 'flex',
		boxShadow: '0 4px 2px -2px rgba(69, 65, 78, 0.1)',
	},
};

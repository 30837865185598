import React, { Component, lazy } from 'react';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import { Container, Layout, Column } from './styles';
// import { colors } from '@constants';

const SignupsWidget = lazy(() => import('modules/Dashboard/Widgets/SignupsWidget'));
const TotalSalesWidget = lazy(() => import('modules/Dashboard/Widgets/TotalSalesWidget'));
const SalesWidget = lazy(() => import('modules/Dashboard/Widgets/SalesWidget'));
const TotalUsersWidget = lazy(() => import('modules/Dashboard/Widgets/TotalUsersWidget'));
const TotalRevenueWidget = lazy(() => import('modules/Dashboard/Widgets/TotalRevenueWidget'));
const CoinsSalesWidget = lazy(() => import('modules/Dashboard/Widgets/CoinsSalesWidget'));
const SummaryWidget = lazy(() => import('modules/Dashboard/Widgets/SummaryWidget'));
const NewUsersWidget = lazy(() => import('modules/Dashboard/Widgets/NewUsersWidget'));
const BusinessHealthWidget = lazy(() => import('modules/Dashboard/Widgets/BusinessHealth'));

export default class DashboardScene extends Component {
	render() {
		return (
			<Container>
				<DashboardSceneHeader title={'Dashboard'} shadow />
				<Layout>
					<Column>
						<SignupsWidget />
						<BusinessHealthWidget />
						<TotalSalesWidget />
						<SalesWidget />
					</Column>
					<Column>
						<TotalUsersWidget />
						<TotalRevenueWidget />
						<CoinsSalesWidget />
					</Column>
					<Column>
						<SummaryWidget />
						<NewUsersWidget />
					</Column>
				</Layout>
			</Container>
		);
	}
}

import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import {
	IWorkspacesResponse,
	IAddFeatureResponse,
	IAddFeatureRequestParams,
	IUpdateWorkspaceParams,
	ICreateWorkspaceParams,
	IAgendaJobResponse,
	IRemoveWorkspaceRequestParams,
	IRemoveWorkspaceResponse,
	IWorkspaceResponse,
	IWorkspaceDetailParams,
} from 'types/workspaces';

export enum ActionTypes {
	LOAD_WORKSPACES = '[workspaces] LOAD_WORKSPACES',
	LOAD_WORKSPACES_SUCCESS = '[workspaces] LOAD_WORKSPACES_SUCCESS',
	LOAD_WORKSPACES_FAIL = '[workspaces] LOAD_WORKSPACES_FAIL',

	ADD_WORKSPACE_FEATURE = '[workspaces] ADD_WORKSPACE_FEATURE',
	ADD_WORKSPACE_FEATURE_SUCCESS = '[workspaces] ADD_WORKSPACE_FEATURE_SUCCESS',
	ADD_WORKSPACE_FEATURE_FAIL = '[workspaces] ADD_WORKSPACE_FEATURE_FAIL',

	REMOVE_WORKSPACE = '[workspaces] REMOVE_WORKSPACE',
	REMOVE_WORKSPACE_SUCCESS = '[workspaces] REMOVE_WORKSPACE_SUCCESS',
	REMOVE_WORKSPACE_FAIL = '[workspaces] REMOVE_WORKSPACE_FAIL',

	CREATE_WORKSPACE = '[workspaces] CREATE_WORKSPACE',
	CREATE_WORKSPACE_SUCCESS = '[workspaces] CREATE_WORKSPACE_SUCCESS',
	CREATE_WORKSPACE_FAIL = '[workspaces] CREATE_WORKSPACE_FAIL',

	UPDATE_WORKSPACE = '[workspaces] UPDATE_WORKSPACE',
	UPDATE_WORKSPACE_SUCCESS = '[workspaces] UPDATE_WORKSPACE_SUCCESS',
	UPDATE_WORKSPACE_FAIL = '[workspaces] UPDATE_WORKSPACE_FAIL',

	GET_AGENDA_JOB_NAMES = '[workspaces] GET_AGENDA_JOB_NAMES',
	GET_AGENDA_JOB_NAMES_SUCCESS = '[workspaces] GET_AGENDA_JOB_NAMES_SUCCESS',
	GET_AGENDA_JOB_NAMES_FAIL = '[workspaces] GET_AGENDA_JOB_NAMES_FAIL',

	LOAD_WORKSPACE_DETAIL = '[workspaces] LOAD_WORKSPACE_DETAIL',
	LOAD_WORKSPACE_DETAIL_SUCCESS = '[workspaces] LOAD_WORKSPACE_DETAIL_SUCCESS',
	LOAD_WORKSPACE_DETAIL_FAIL = '[workspaces] LOAD_WORKSPACE_DETAIL_FAIL',
}

export class LoadAgendaJobNames implements Action {
	readonly type = ActionTypes.GET_AGENDA_JOB_NAMES;
}

export class LoadAgendaJobNamesSuccessAction implements Action {
	readonly type = ActionTypes.GET_AGENDA_JOB_NAMES_SUCCESS;
	constructor(public payload: IAgendaJobResponse) {}
}

export class LoadAgendaJobNamesFailAction implements Action {
	readonly type = ActionTypes.GET_AGENDA_JOB_NAMES_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class LoadWorkspaceDetail implements Action {
	constructor(public params: IWorkspaceDetailParams) {}
	readonly type = ActionTypes.LOAD_WORKSPACE_DETAIL;
}

export class LoadWorkspaceDetailSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_WORKSPACE_DETAIL_SUCCESS;
	constructor(public payload: IWorkspaceResponse) {}
}

export class LoadWorkspaceDetailFailAction implements Action {
	readonly type = ActionTypes.LOAD_WORKSPACE_DETAIL_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

///
export class LoadWorkspacesAction implements Action {
	readonly type = ActionTypes.LOAD_WORKSPACES;
}

export class LoadWorkspacesSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_WORKSPACES_SUCCESS;
	constructor(public payload: IWorkspacesResponse) {}
}

export class LoadWorkspacesFailAction implements Action {
	readonly type = ActionTypes.LOAD_WORKSPACES_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class AddFeatureAction implements Action {
	readonly type = ActionTypes.ADD_WORKSPACE_FEATURE;
	constructor(public params: IAddFeatureRequestParams) {}
}

export class AddFeatureSuccessAction implements Action {
	readonly type = ActionTypes.ADD_WORKSPACE_FEATURE_SUCCESS;
	constructor(public payload: IAddFeatureResponse) {}
}

export class AddFeatureFailAction implements Action {
	readonly type = ActionTypes.ADD_WORKSPACE_FEATURE_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class RemoveWorkspaceAction implements Action {
	readonly type = ActionTypes.REMOVE_WORKSPACE;
	constructor(public params: IRemoveWorkspaceRequestParams) {}
}

export class RemoveWorkspaceSuccessAction implements Action {
	readonly type = ActionTypes.REMOVE_WORKSPACE_SUCCESS;
	constructor(public payload: IRemoveWorkspaceResponse) {}
}

export class RemoveWorkspaceFailAction implements Action {
	readonly type = ActionTypes.REMOVE_WORKSPACE_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class CreateWorkspaceAction implements Action {
	readonly type = ActionTypes.CREATE_WORKSPACE;
	constructor(public params: ICreateWorkspaceParams) {}
}

export class CreateWorkspaceActionSuccessAction implements Action {
	readonly type = ActionTypes.CREATE_WORKSPACE_SUCCESS;
	constructor(public payload: IAddFeatureResponse) {}
}

export class CreateWorkspaceActionFailAction implements Action {
	readonly type = ActionTypes.CREATE_WORKSPACE_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class UpdateWorkspaceAction implements Action {
	readonly type = ActionTypes.UPDATE_WORKSPACE;
	constructor(public params: IUpdateWorkspaceParams) {}
}

export class UpdateWorkspaceActionSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_WORKSPACE_SUCCESS;
	constructor(public payload: IAddFeatureResponse) {}
}

export class UpdateWorkspaceActionFailAction implements Action {
	readonly type = ActionTypes.UPDATE_WORKSPACE_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export type Actions =
	| LoadWorkspacesAction
	| LoadWorkspacesSuccessAction
	| LoadWorkspacesFailAction
	| AddFeatureAction
	| RemoveWorkspaceAction
	| RemoveWorkspaceSuccessAction
	| RemoveWorkspaceFailAction
	| AddFeatureSuccessAction
	| AddFeatureFailAction
	| CreateWorkspaceAction
	| CreateWorkspaceActionSuccessAction
	| CreateWorkspaceActionFailAction
	| UpdateWorkspaceAction
	| UpdateWorkspaceActionSuccessAction
	| UpdateWorkspaceActionFailAction
	| LoadAgendaJobNames
	| LoadAgendaJobNamesSuccessAction
	| LoadAgendaJobNamesFailAction
	| LoadWorkspaceDetail
	| LoadWorkspaceDetailSuccessAction
	| LoadWorkspaceDetailFailAction;

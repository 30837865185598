import React, { Component } from 'react';
import moment from 'moment';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';
import { Container } from 'scenes/Users/styles';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import SubHeader from 'components/SubHeader';
import { RouteComponentProps, withRouter } from 'react-router';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { LoadFundraiserDonationsAction } from 'store/actions/fundraiserDonations';
import { getLoading, getFundraiserDonations } from 'store/selectors/fundraiserDonations';
import { CircleSpinner } from 'react-spinners-kit';
import ContainerCenter from 'components/ContainerCenter';
import { theme } from 'utils/theme';
import { Notice } from 'modules/Dashboard/Widgets/NewUsersWidget/styles';
import { IFundraiserDonationPayload } from 'types/fundraiserDonations';
import copyIcon from 'assets/copy.svg';
import linkIcon from 'assets/link.svg';
import { Button } from '@material-ui/core';
import { UtilIcon } from 'modules/User/Tables/UserListing/styles';
import toast from 'cogo-toast';

interface IProps {}

interface IStoreProps {
	loading: ReturnType<typeof getLoading>;
	donations: ReturnType<typeof getFundraiserDonations>;
}

interface IDispatchProps {
	fetchAllFundraiserDonations: () => void;
}

interface IProps extends IStoreProps, IDispatchProps, RouteComponentProps {}

class FundraiserDonationsScene extends Component<IProps> {
	state: { sortedBy: keyof IFundraiserDonationPayload; sortedOrder: 1 | -1 } = {
		sortedBy: 'createdAt',
		sortedOrder: -1,
	};

	componentDidMount() {
		this.props.fetchAllFundraiserDonations();
	}

	render() {
		const { fundraiser } = this.props.match.params;
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader title={''} shadow={false} style={{ flex: 1 }} />
				</div>
				<div style={{ width: '100%' }}>
					<SubHeader title={'Fundraiser Donations'} />
				</div>
				{this.renderContent(fundraiser)}
			</Container>
		);
	}

	private sort = ({ label, order }: { label: string; order: number }) => {
		this.setState({
			sortedBy: label,
			sortedOrder: order,
		});
	};

	private renderHeaderCell = ({
		name,
		label,
		minWidth,
	}: {
		name: string;
		label: string;
		minWidth: number;
	}) => {
		const onClick = () => {
			if (this.state.sortedBy === label) {
				this.sort({ label, order: -this.state.sortedOrder });
			} else {
				this.sort({ label, order: -1 });
			}
		};
		return (
			<TableCell style={{ minWidth, cursor: 'pointer' }} align="left" onClick={onClick}>
				{name}
			</TableCell>
		);
	};

	private renderContent = (fundraiser?: string) => {
		const { loading, donations } = this.props;
		if (loading) {
			return (
				<ContainerCenter>
					<CircleSpinner size={25} color={theme.colors.cornflowerBlue} />
				</ContainerCenter>
			);
		}
		if (!donations || !donations.length) {
			return (
				<ContainerCenter>
					<Notice>Don't have any fundraiser donations in this community yet</Notice>
				</ContainerCenter>
			);
		}
		return this.workFundraiserDonationsList(fundraiser);
	};

	private workFundraiserDonationsList = (fundraiser?: string) => {
		const { donations } = this.props;
		const mappedDonations = donations.map(d => ({
			...d,
			fundraiserName: d.fundraiser.name,
			donatedTo: d.onBehalfPersona
				? `${d.onBehalfPersona.firstName} ${d.onBehalfPersona.lastName}`
				: 'DELETED ACCOUNT',
			donatedBy: d.contact ? d.contact.name : 'NO INFO',
			donatedByContactInfo:
				d.contact && d.contact.contactInfo
					? d.contact.contactInfo.phone ?? d.contact.contactInfo.email
					: 'NO INFO',
			createdAtUTC: new Date(d.createdAt).getTime(),
		}));
		const filteredDonations = fundraiser
			? mappedDonations.filter(d => d.fundraiser._id.trim() === fundraiser.trim())
			: mappedDonations;
		const sortedDonations = filteredDonations.sort(
			(fr1: IFundraiserDonationPayload, fr2: IFundraiserDonationPayload) =>
				fr1[this.state.sortedBy] > fr2[this.state.sortedBy]
					? this.state.sortedOrder
					: -this.state.sortedOrder,
		);

		const onClipboardCopy = (item: IFundraiserDonationPayload) => {
			navigator.clipboard.writeText(JSON.stringify(item, null, 2));
			toast.success('A full donation JSON has been copied to the clipboard', {
				heading: 'Copied',
				position: 'top-right',
			});
		};
		return (
			<Paper style={{ width: '100%', flex: 1, display: 'flex' }}>
				<TableContainer style={{ flex: 1, display: 'flex' }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'Fundraiser',
									label: 'fundraiserName',
								})}
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'Sponsor Name',
									label: 'donatedBy',
								})}
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'Sponsor phone/email',
									label: 'donatedByContactInfo',
								})}
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'Amount',
									label: 'amountRaised',
								})}
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'Donation Timestamp',
									label: 'createdAtUTC',
								})}
								{this.renderHeaderCell({
									minWidth: 100,
									name: 'Donated To',
									label: 'donatedTo',
								})}
								<TableCell style={{ minWidth: 30 }} align="left">
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{sortedDonations.map((item, index: number) => (
								<TableRow key={index}>
									<TableCell align="left">{item.fundraiserName}</TableCell>
									<TableCell align="left">{item.donatedBy}</TableCell>
									<TableCell align="left">{item.donatedByContactInfo}</TableCell>
									<TableCell align="left">${item.amountRaised}</TableCell>
									<TableCell align="left">
										{moment(item.createdAt).format('LL')}
									</TableCell>
									<TableCell align="left">{item.donatedTo}</TableCell>
									<TableCell align="left">
										<a href={item.receiptLink ?? undefined} target="_blank">
											<Button style={{ minWidth: 0 }}>
												<UtilIcon src={linkIcon} />
											</Button>
										</a>
										<Button
											style={{ minWidth: 0 }}
											onClick={() => onClipboardCopy(item)}
										>
											<UtilIcon src={copyIcon} />
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		);
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getLoading(state),
		donations: getFundraiserDonations(state),
	}),
	dispatch => ({
		fetchAllFundraiserDonations: () => dispatch(new LoadFundraiserDonationsAction()),
	}),
)(withRouter(FundraiserDonationsScene));

import React, { Suspense } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'services/router';
import { store, persistor } from 'store';
import Scenes from 'scenes';
import Theme from 'modules/Theme';
import { GlobalStyles } from 'styles';
import Splashscreen from 'components/Splashscreen';
import './Header.css';
import { Helmet } from 'react-helmet';

import AuthService from 'services/auth';

const workspace = AuthService.getWorkspace() || '';
const workspaceName = workspace.charAt(0).toUpperCase() + workspace.slice(1);

export default () => (
	<StoreProvider store={store}>
		<Theme>
			<PersistGate loading={<Splashscreen />} persistor={persistor}>
				{/* TODO: add fallback */}
				<Suspense fallback={null}>
					<GlobalStyles />
					<Helmet>
						<meta charSet="utf-8" />
						<link
							rel="shortcut icon"
							href={workspaceName === 'Vyoo' ? './favicon.ico' : './favicon.ico'}
						/>
						<title>{workspaceName} Admin Dashboard</title>
					</Helmet>
					<ConnectedRouter history={history}>
						<Scenes />
					</ConnectedRouter>
				</Suspense>
			</PersistGate>
		</Theme>
	</StoreProvider>
);

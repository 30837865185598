import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import { IFundraiserDonationsResponse } from 'types/fundraiserDonations';

export enum ActionTypes {
	LOAD_FUNDRAISER_DONATIONS = '[fundraiser donations] LOAD_FUNDRAISER_DONATIONS',
	LOAD_FUNDRAISER_DONATIONS_SUCCESS = '[fundraisers] LOAD_FUNDRAISER_DONATIONS_SUCCESS',
	LOAD_FUNDRAISER_DONATIONS_FAIL = '[fundraisers] LOAD_FUNDRAISER_DONATIONS_FAIL',
}

export class LoadFundraiserDonationsAction implements Action {
	readonly type = ActionTypes.LOAD_FUNDRAISER_DONATIONS;
}

export class LoadFundraiserDonationsSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_FUNDRAISER_DONATIONS_SUCCESS;
	constructor(public payload: IFundraiserDonationsResponse) {}
}

export class LoadFundraiserDonationsFailAction implements Action {
	readonly type = ActionTypes.LOAD_FUNDRAISER_DONATIONS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export type Actions =
	| LoadFundraiserDonationsAction
	| LoadFundraiserDonationsSuccessAction
	| LoadFundraiserDonationsFailAction;

import { IAuthPayload } from 'types/auth';

const successResponse = {
	token:
		'eyJraWQiOiJIbVhJREtHYzdRRW8yV0srQzFBbFo5MWE0QVVzbExlUWJDbVFJcll3b2hFPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJmZmEzMjEwMC1hMjIyLTRkYjYtODBjMy1lY2YwZGRkMzg1NGEiLCJldmVudF9pZCI6ImViMTg0YmFiLWQwODEtNDI3My05ZTM1LWIxY2ZiNWViMDUxZiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE1NjMyODI4NjcsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy13ZXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtd2VzdC0yX21rcGRzWUZXSSIsImV4cCI6MTU2MzI4NjQ2NywiaWF0IjoxNTYzMjgyODY3LCJqdGkiOiI5ZjYzMzczMS01ZGQ2LTQ3NTUtOGM3ZC1mZmI1ZDA2NWJjNTkiLCJjbGllbnRfaWQiOiIzNWxqMDc5MGI5aW1tajRiNzU3dW5jOTgzZSIsInVzZXJuYW1lIjoia2FyaW0ifQ.GQJspJULMFlOvwYmCAe4hPCBjWMhwmxgsAn5QjgVL2NKolrAdFzo1blJmgVHjcXwyFP1HKBu3KjNebVlDk2-0lI_VkoMrsUH3livnLMCSQUA6Pud3s3xNk1FP_xw0oyZF6_jr33C2mMNBan5hKX7x9P_Bk3b1YAufbDMlyKCoaET3gGu7Ypwdr9o6k04JscLp7sKJz6xZx54GV3twNptmeR_SgOIs7QCuonHaoJhMcG61H0b4x0hcKzEFM7BwAykcQw1y75TqYWYq6TgY1bZfQaQ_Kirc5WQgCFvJBAjhGUf6d_nw7f5oXcZN25oE_V-78tP3AJ6v3VrN9EjbnXNsQ',
	sub: 'ffa32100-a222-4db6-80c3-ecf0ddd3854a',
	aud: '35lj0790b9immj4b757unc983e',
	email_verified: true,
	event_id: 'eb184bab-d081-4273-9e35-b1cfb5eb051f',
	token_use: 'id',
	auth_time: 1563282867,
	iss: 'https://cognito-idp.us-west-2.amazonaws.com/us-west-2_mkpdsYFWI',
	'cognito:username': 'karim',
	exp: 1563286467,
	iat: 1563282867,
	email: 'karim@makeen.io',
};

const failResponse = {
	statusCode: 400,
	error: 'Bad Request',
	message: `{"code":"NotAuthorizedException","name":"NotAuthorizedException","message":"Incorrect username or password."}`,
};

const isAuthPayloadValid = ({ username, password }: IAuthPayload) =>
	username === 'test' && password === 'test';

export default {
	auth: {
		post: (payload: string) => {
			const isValid = isAuthPayloadValid(JSON.parse(payload));
			return isValid ? successResponse : failResponse;
		},
	},
};

import React, { Component } from 'react';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import { Container, Header, PaginationArea, RowPerPageArea, ShowingStyle } from './styles';
import Pager from 'components/Pager/Pager';
import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';
import { getUsersTotal } from 'store/selectors/stats';
import { getActivePageNumber, getPageLimit, getSearchedValue } from 'store/selectors/users';
import {
	changePage,
	loadActiveUserAction,
	changePageLimit,
	changeSelectedItems,
} from 'store/actions/users';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import UserInfo from 'modules/User/UserInfo';
import UserListing from 'modules/User/Tables/UserListing';

interface IProps {}

interface IStoreProps {
	totalUsers: ReturnType<typeof getUsersTotal>;
	page: ReturnType<typeof getActivePageNumber>;
	limit: ReturnType<typeof getPageLimit>;
	searchedValue: ReturnType<typeof getSearchedValue>;
}

interface IDispatchProps {
	changeActivePage: (page: number) => void;
	changePageLimit: (limit: number) => void;
	changeSelectedItems: (ids: string[]) => void;
	loadActiveUsers: (offset: number, limit: number) => void;
}

interface IProps extends IStoreProps, IDispatchProps {}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		totalUsers: getUsersTotal(state),
		page: getActivePageNumber(state),
		limit: getPageLimit(state),
		searchedValue: getSearchedValue(state),
	}),
	dispatch => ({
		changeActivePage: page => dispatch(changePage(page)),
		changePageLimit: limit => dispatch(changePageLimit(limit)),
		changeSelectedItems: ids => dispatch(changeSelectedItems(ids)),
		loadActiveUsers: (offset: number, limit: number) =>
			dispatch(new loadActiveUserAction({ offset, limit })),
	}),
)(
	class UserScene extends Component<IProps> {
		componentWillUnmount() {
			this.props.changeActivePage(0);
			this.props.changePageLimit(10);
		}
		render() {
			const { limit, page, totalUsers, changeSelectedItems } = this.props;
			const endingIndex = (page + 1) * limit;
			const startIndex = endingIndex - limit + 1;

			return (
				<Container>
					<div
						style={{
							height: 100,
						}}
					>
						<DashboardSceneHeader
							title={''}
							shadow={false}
							style={{
								borderBottomWidth: 2,
								borderColor: '#f2f3f8',
								borderStyle: 'solid',
							}}
						/>
						<UserInfo />
					</div>
					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							padding: 10,
							paddingTop: 40,
						}}
					>
						<div style={{ flex: 0.8, display: 'flex' }}>
							<UserListing />
						</div>
						<div style={{ flex: 0.2, display: 'flex' }}>
							{this.props.searchedValue == '' && (
								<Header style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
									<PaginationArea>
										<Pager
											limit={limit}
											offset={page * limit}
											total={totalUsers || 0}
											onClick={(_, offset: number) => {
												changeSelectedItems([]);
												this.props.changeActivePage(offset / limit);
												this.props.loadActiveUsers(
													offset / limit + 1,
													limit,
												);
											}}
										/>
									</PaginationArea>
									<RowPerPageArea>
										<FormControl style={{ minWidth: 50, right: 10 }}>
											<Select
												value={limit}
												onChange={(v: any) => {
													this.props.changePageLimit(v.target.value);
													this.props.changeActivePage(0);
													this.props.loadActiveUsers(1, v.target.value);
												}}
												inputProps={{
													name: 'age',
													id: 'age-simple',
												}}
												style={{
													backgroundColor: '#DFE3FF',
													color: '#6173fe',
												}}
											>
												<MenuItem value={10}>10</MenuItem>
												<MenuItem value={20}>20</MenuItem>
												<MenuItem value={30}>30</MenuItem>
												<MenuItem value={100}>100</MenuItem>
												<MenuItem value={500}>500</MenuItem>
											</Select>
										</FormControl>
										<div>
											<ShowingStyle>
												Showing {startIndex} - {endingIndex} of {totalUsers}
											</ShowingStyle>
										</div>
									</RowPerPageArea>
								</Header>
							)}
						</div>
					</div>
				</Container>
			);
		}
	},
);

import { ISummaryResponse } from 'types/summary';

const successResponse: ISummaryResponse = {
	summary: {
		today: {
			_id: '5d3ace15689528002036f311',
			date: '2019-07-26T09:55:33.193Z',
			signups: 1,
			vyooable: 1,
			profileViews: 0,
			connectionRequestsSent: 0,
			acceptedConnectionRequests: 0,
			connectionsExtended: 0,
			eventShared: 0,
			eventInterested: 0,
			eventGoing: 0,
			eventReported: 0,
			abandonedSignups: 10,
			sales: 2,
			revenue: 101,
			coinRedemptions: 0,
			userBlocked: 0,
			updatedAt: '2019-07-26T18:15:24.798Z',
			createdAt: '2019-07-26T09:55:33.197Z',
		},
		monthlySummaryAverage: {
			vyooable: 1,
			profileViews: 0,
			connectionRequestsSent: 0,
			acceptedConnectionRequests: 0,
			connectionsExtended: 0,
			eventShared: 0,
			eventInterested: 0,
			eventGoing: 0,
			eventReported: 0,
			userBlocked: 0,
		},
		monthly: [
			{
				_id: '5d3ac8a08142120020b980cf',
				date: '2019-07-25T00:00:00.000Z',
				signups: 2,
				vyooable: 0,
				profileViews: 0,
				connectionRequestsSent: 0,
				acceptedConnectionRequests: 0,
				connectionsExtended: 0,
				eventShared: 0,
				eventInterested: 0,
				eventGoing: 0,
				eventReported: 0,
				abandonedSignups: 0,
				sales: 0,
				revenue: 0,
				coinRedemptions: 0,
				userBlocked: 0,
				updatedAt: '2019-07-26T09:32:16.715Z',
				createdAt: '2019-07-26T09:32:16.715Z',
			},
		],
	},
};

export default {
	summary: {
		get: successResponse,
	},
};

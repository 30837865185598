import styled from 'styled-components';

export const Months = styled.p`
	width: auto;
	height: 21px;
	font-family: Poppins;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.62;
	letter-spacing: normal;
	color: #595d6e;
`;

export const ButtonArea = styled.div`
	width: 80px;
	height: 26px;
	border-radius: 2px;
	background-color: #dfe3ff;
	align-items: center;
	justify-content: center;
	display: flex;
`;

export const Detail = styled.p`
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.62;
	letter-spacing: normal;
	color: #595d6e;
`;

export const Revenue = styled.p`
	font-size: 13px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.62;
	letter-spacing: normal;
	color: #595d6e;
`;

export const Edit = styled.p`
	font-size: 14px;
	text-transform: none;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
`;

export const AvatarArea = styled.div`
	flex: 0.2;
	justify-content: center;
	align-items: center;
	display: flex;
`;

export const ProductDetail = styled.div`
	flex: 0.8;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin-top: 12px;
`;

export const ProductDescription = styled.div`
	flex: 0.15;
	display: flex;
	padding-left: 24px;
`;

export const EditArea = styled.div`
	flex: 0.25;
	justify-content: flex-end;
	display: flex;
	align-items: center;
	padding-right: 24px;
`;

export const DetailArea = styled.div`
	flex: 0.25;
	overflow: auto;
	padding-left: 24px;
	padding-right: 24px;
	display: flex;
`;

export const TotalReveneArea = styled.div`
	flex: 0.2;
	display: flex;
	flex-direction: column;
`;

export const SoldArea = styled.div`
	flex: 0.2;
	display: flex;
	flex-direction: column;
	padding-left: 30px;
`;

import React from 'react';
import logo from 'assets/logo.svg';
import { Layout, Logo, Title } from './styles';
import ConfirmForgotPasswordForm from 'modules/ForgotPassword/ConfirmForgotPassword';

export default () => (
	<Layout>
		<Logo src={logo} />
		<Title>Forgot Password</Title>
		<ConfirmForgotPasswordForm />
	</Layout>
);

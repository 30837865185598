import styled from 'styled-components';
import bg from 'assets/sign-bg.jpg';

export const Layout = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 60px 2rem 1rem 2rem;
	background-image: url(${bg});
	background-size: cover;
	background-position: center;
`;

export const Logo = styled.img`
	width: 65px;
	height: 45px;
	margin-bottom: 2.5rem;
`;

export const Title = styled.h3`
	font-size: 1.2rem;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.comet};
	margin-bottom: 2.5rem;
`;

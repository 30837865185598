import { Reducer } from 'redux';
import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/fundraiserDonations';
import { IFundraiserDonationPayload } from 'types/fundraiserDonations';

export interface IState {
	donations: IFundraiserDonationPayload[];
	loading: boolean;
}

export const initialState: IState = {
	donations: [],
	loading: false,
};

export const reducer: Reducer<IState, Actions> = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LOAD_FUNDRAISER_DONATIONS:
			return { ...state, loading: true };
		case ActionTypes.LOAD_FUNDRAISER_DONATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				donations: action.payload.donations,
			};
		case ActionTypes.LOAD_FUNDRAISER_DONATIONS_FAIL:
			return { ...state, loading: false };
	}
	return state;
};

export const getFundraiserDonations = (s: IState) => s.donations;
export const getLoading = (s: IState) => s.loading;

export enum FundraiserStatus {
	archived = 'ARCHIVED',
	pending = 'PENDING',
	active = 'ACTIVE',
	paused = 'PAUSED',
	ended = 'ENDED',
}

export interface IFundraiserThumbnailResponse {
	mediaId: string | null;
	url: string | null;
}

export interface IFundraiserTopDonorsResponse {
	name: string;
	amount: number;
	image: string | null;
}

export interface IFundraiserCreatorPhotoResponse {
	id: string;
	name: string;
	profilePicture: string;
	mediaId: string;
	active: boolean;
}

export interface IFundraiserShortPersonaResponse {
	_id: string;
	firstName: string;
	lastName: string;
	photos: IFundraiserCreatorPhotoResponse[];
	personaId: number;
}

export interface IFundraiserGroupResponse {
	_id: string;
	name: string;
}

export interface IFundraiserPayload {
	_id: string;
	name: string;
	description: string;
	startDate: string;
	endDate: string;
	goalAmount: number;
	videoUrl: string;
	thumbnail: IFundraiserThumbnailResponse;
	groupIds: string[];
	creatorPersonaDocId: string;
	status: FundraiserStatus;
	totalViews: number;
	totalDonated: number;
	totalDonations: number;
	totalContacts: number;
	memberPersonaDocIds: string[];
	top: [];
	updatedAt: string;
	createdAt: string;
	creator: IFundraiserShortPersonaResponse;
	groups: IFundraiserGroupResponse[];
	workspace: string;
}

export interface IFundraisersResponse {
	fundraisers: IFundraiserPayload[];
}

import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SCENE, urls } from '@constants/scenes';
import { getAuthorized } from 'store/selectors/user';
import { IState as IStoreState } from 'store/reducers';

import SignInScene from 'scenes/SignIn';
import NotFoundScene from 'scenes/NotFound';
import DashboardScene from 'scenes/Dashboard';
import UserScene from 'scenes/Users';
import ProductScene from 'scenes/Product';
import AddProductScene from 'scenes/AddProduct';
import SubscriptionScene from 'scenes/Groups';
import AddSubscriptionScene from 'scenes/AddGroup';
import ForgotPassword from 'scenes/ForgotPassword';
import ConfirmForgotPassword from 'scenes/ConfirmForgotPassword';
import ConfirmActivation from 'scenes/ConfirmActivation';
import AdminsScene from 'scenes/Admins';
import AddAdminScene from 'scenes/AddAdmin';
import WorkspacesScene from 'scenes/Workspaces';
import AddWorkspaceScene from 'scenes/AddWorkspace';
import AddUserScene from 'scenes/AddUser';
import EditUserScene from 'scenes/EditUser';
import WithdrawRequestScene from 'scenes/WithdrawRequest';
import FundraisersScene from 'scenes/Fundraisers';
import FundraiserDonationsScene from 'scenes/FundraiserDonations';

import Drawer from 'modules/Drawer';
import { Container, LeftSection, RightSection, InnerRightSection } from './style';

const unauthorizedScenes = (
	<Switch>
		<Route path={urls[SCENE.SignIn].pattern} component={SignInScene} />
		<Route path={urls[SCENE.ForgotPassword].pattern} component={ForgotPassword} />
		<Route path={urls[SCENE.ConfirmForgotPassword].pattern} component={ConfirmForgotPassword} />
		<Route path={urls[SCENE.ConfirmActivation].pattern} component={ConfirmActivation} />
		<Redirect to={urls[SCENE.SignIn].pattern} />
	</Switch>
);

const authorizedScenes = (
	<Switch>
		<Container>
			<LeftSection>
				<Drawer />
			</LeftSection>
			<RightSection>
				<InnerRightSection>
					<Route path={urls[SCENE.Dashboard].pattern} component={DashboardScene} />
					<Route path={urls[SCENE.Users].pattern} component={UserScene} />
					<Route path={urls[SCENE.Product].pattern} component={ProductScene} />
					<Route path={urls[SCENE.AddProduct].pattern} component={AddProductScene} />
					<Route path={urls[SCENE.Subscription].pattern} component={SubscriptionScene} />
					<Route path={urls[SCENE.Admins].pattern} component={AdminsScene} />
					<Route path={urls[SCENE.AddAdmin].pattern} component={AddAdminScene} />
					<Route path={urls[SCENE.EditAdmin].pattern} component={AddAdminScene} />
					<Route path={urls[SCENE.Workspaces].pattern} component={WorkspacesScene} />
					<Route path={urls[SCENE.Fundraisers].pattern} component={FundraisersScene} />
					<Route
						path={urls[SCENE.FundraiserDonations].pattern}
						component={FundraiserDonationsScene}
					/>
					<Route path={urls[SCENE.AddWorkspace].pattern} component={AddWorkspaceScene} />
					<Route path={urls[SCENE.EditWorkspace].pattern} component={AddWorkspaceScene} />
					<Route path={urls[SCENE.AddUser].pattern} component={AddUserScene} />
					<Route path={urls[SCENE.EditUser].pattern} component={EditUserScene} />
					<Route
						path={urls[SCENE.AddSubscription].pattern}
						component={AddSubscriptionScene}
					/>
					<Route
						path={urls[SCENE.WithdrawRequest].pattern}
						component={WithdrawRequestScene}
					/>
				</InnerRightSection>
				<Redirect to={urls[SCENE.Dashboard].url({})} />
			</RightSection>
		</Container>
	</Switch>
);

interface IStoreProps {
	authorized: ReturnType<typeof getAuthorized>;
}

interface IProps extends IStoreProps {}

export default connect<IStoreProps, {}, {}, IStoreState>(state => ({
	authorized: getAuthorized(state),
}))(({ authorized }: IProps) => (
	<>
		{authorized ? authorizedScenes : unauthorizedScenes}
		<Route component={NotFoundScene} />
	</>
));

import React, { ReactNode } from 'react';
import { Header } from 'scenes/Users/styles';
import {
	RightContainer,
	Users,
	AddUsers,
	Total,
	SubTitle,
	Divider,
	ArchivedUserText,
} from 'modules/User/UserInfo/styles';
import Button from '@material-ui/core/Button';
import { LeftContainer } from './style';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { colors } from 'utils/theme';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
	title?: string;
	subTitle?: string;
	total?: any;
	searchbar?: boolean;
	button?: string;
	buttonText?: string;
	style?: any;
	link?: any;
	linkCancel?: any;
	onPressButton?: () => void;
	onChangeSearchBarText?: (e: any) => void;
	searchValue?: string;
	searchPlaceHolder?: string;

	showCheckbox?: boolean | false;
	checkBoxText?: string;
	onChangeCheckbox?: (e: any) => void;
	checkboxValue?: boolean;

	cancelText?: string;
	onPressCancel?: () => void;

	button2Text?: string;
	onPressButton2?: () => void;
	linkButton2?: any;
	filter?: ReactNode;
}

export default React.memo(
	({
		style,
		buttonText,
		title,
		total,
		searchbar,
		onPressButton,
		link,
		linkCancel,
		onChangeSearchBarText,
		searchPlaceHolder,
		searchValue,
		showCheckbox,
		checkBoxText,
		onChangeCheckbox,
		checkboxValue,
		subTitle,
		cancelText,
		onPressCancel,
		button2Text,
		onPressButton2,
		linkButton2,
		filter,
	}: IProps) => (
		<Header style={{ ...styles.header, ...style }}>
			<LeftContainer>
				{title && <Users>{title}</Users>}
				<Divider />
				{total && <Total>{total} total</Total>}
				{subTitle && <SubTitle>{subTitle}</SubTitle>}
				{searchbar && (
					<Paper style={styles.paper}>
						<InputBase
							style={styles.searchBar}
							value={searchValue}
							onChange={onChangeSearchBarText}
							placeholder={searchPlaceHolder}
						/>
						<IconButton style={{ padding: 10, color: '#6173FE' }} aria-label="search">
							<SearchIcon />
						</IconButton>
					</Paper>
				)}
				{showCheckbox && <Divider style={{ marginLeft: 20, marginRight: 10 }} />}
				{showCheckbox && (
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Checkbox
							value={checkboxValue}
							checked={checkboxValue}
							onChange={onChangeCheckbox}
							style={{ backgroundColor: 'transparent' }}
						/>
						<ArchivedUserText>{checkBoxText}</ArchivedUserText>
					</div>
				)}
			</LeftContainer>
			<RightContainer style={{ alignItems: 'center' }}>
				{cancelText && (
					<Link
						style={{ color: colors.white, textDecoration: 'none' }}
						to={linkCancel || link}
					>
						<Button onClick={onPressCancel} style={styles.cancelButton}>
							<AddUsers style={{ color: 'black' }}>{cancelText}</AddUsers>
						</Button>
					</Link>
				)}
				{buttonText && (
					<Link style={{ color: colors.white, textDecoration: 'none' }} to={link}>
						<Button
							onClick={onPressButton}
							style={{
								minWidth: 100,
								backgroundColor: '#DFE3FF',
								right: 18,
								height: 40,
							}}
						>
							<AddUsers>{buttonText}</AddUsers>
						</Button>
					</Link>
				)}
				{button2Text && (
					<Link style={{ color: colors.white, textDecoration: 'none' }} to={linkButton2}>
						<Button
							onClick={onPressButton2}
							style={{
								minWidth: 100,
								backgroundColor: '#DFE3FF',
								right: 18,
								height: 40,
								marginLeft: '10px',
							}}
						>
							<AddUsers>{button2Text}</AddUsers>
						</Button>
					</Link>
				)}
				{filter}
			</RightContainer>
		</Header>
	),
);

const styles = {
	header: {
		height: '50px',
		margin: 0,
		display: 'flex',
		boxShadow: '0 4px 2px -2px rgba(69, 65, 78, 0.1)',
	},
	paper: {
		display: 'flex',
		alignItems: 'center',
		width: 156,
		height: 32,
		backgroundColor: 'rgb(237, 239, 255)',
	},
	searchBar: {
		marginLeft: 8,
		flex: 1,
		width: 49,
		height: 17,
		fontFamily: 'Poppins',
		fontSize: 12,
		fontWeight: 'normal',
		fontStretch: 'normal',
		fontStyle: 'normal',
		lineHeight: 'normal',
		letterSpacing: 'normal',
		color: '#868aa8',
	} as React.CSSProperties,
	cancelButton: {
		minWidth: 100,
		backgroundColor: 'white',
		right: 18,
		height: 40,
		marginRight: 10,
		borderWidth: 2,
		borderColor: '#f2f3f8',
		borderStyle: 'solid',
	} as React.CSSProperties,
};

import { createSelector } from 'reselect';
import * as fundraiserDonations from 'store/reducers/fundraiserDonations';
import { IState } from 'store/reducers';

const getFundraiserDonationsState = (s: IState) => s.fundraiserDonations;

export const getFundraiserDonations = createSelector(
	getFundraiserDonationsState,
	fundraiserDonations.getFundraiserDonations,
);

export const getLoading = createSelector(
	getFundraiserDonationsState,
	fundraiserDonations.getLoading,
);

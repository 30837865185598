import { Action } from 'redux';
import { THEME } from 'utils/theme';

export enum ActionTypes {
	SET_THEME = '[settings] SET_THEME',
	TOOGLE_THEME = '[settings] TOOGLE_THEME',
}

export class SetThemeAction implements Action {
	readonly type = ActionTypes.SET_THEME;
	constructor(public payload: THEME) {}
}

export class ToogleThemeAction implements Action {
	readonly type = ActionTypes.TOOGLE_THEME;
}

export type Actions = SetThemeAction | ToogleThemeAction;

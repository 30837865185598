import ApiService from 'services/api';
import { defaultStatsQuery } from 'utils/dateQueries';
import { ICoinsSalesResponse } from 'types/coins';

class CoinsService {
	loadCoinsSales(): Promise<ICoinsSalesResponse> {
		return ApiService.get<ICoinsSalesResponse>('admin/dashboard/sales/coin', {
			query: defaultStatsQuery,
			authorized: true,
		});
	}
}

export default new CoinsService();

import merge from 'lodash/merge';
import queryString from 'query-string';
import { IOptions, IErrorResponse } from 'types/api';
import { USE_MOCKS, BASE_URL } from '@constants';
import { getMocks } from 'services/mocks';
import AuthService from 'services/auth';

const defaultRequestOptions: RequestInit = {
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};

class ApiService {
	get<R>(url: string, options: IOptions = {}): Promise<R> {
		return this.call(url, {}, options);
	}

	post<R>(url: string, requestOptions: RequestInit, options: IOptions = {}): Promise<R> {
		return this.call<R>(url, { method: 'post', ...requestOptions }, options);
	}

	put<R>(url: string, requestOptions: RequestInit, options: IOptions = {}): Promise<R> {
		return this.call<R>(url, { method: 'put', ...requestOptions }, options);
	}

	delete<R>(url: string, requestOptions: RequestInit, options: IOptions = {}): Promise<R> {
		return this.call<R>(url, { method: 'delete', ...requestOptions }, options);
	}

	private call<R>(
		url: string,
		requestOptions: RequestInit = {},
		{ authorized, query, workspaceName }: IOptions = {},
	): Promise<R> {
		const fetchOptions = merge({}, defaultRequestOptions, requestOptions);
		const finalUrl = BASE_URL + (query ? `${url}?${queryString.stringify(query)}` : url);

		if (authorized) {
			const token = AuthService.getAuthToken();
			const workspace = AuthService.getWorkspace();

			if (workspace && workspace !== 'null') {
				fetchOptions.headers = {
					...fetchOptions.headers,
					workspace: workspaceName ? workspaceName : workspace,
				};
			}

			if (token) {
				fetchOptions.headers = {
					...fetchOptions.headers,
					Authorization: token,
					client: 'admin',
				};
			}
		}

		if (USE_MOCKS) {
			const mock = getMocks<R & IErrorResponse>({
				url,
				method: fetchOptions.method,
				body: requestOptions.body,
			});
			return Promise.resolve(mock).then(this.handleErrors);
		}

		return (
			fetch(finalUrl, fetchOptions)
				.then(r => r.json())
				// .then(this.handleRawErrors)
				.then(this.handleErrors)
		);
	}

	// private handleRawErrors = (response: Response): Response => {
	// 	if (response.status > 399) {
	// 		throw Error();
	// 	}

	// 	return response;
	// };

	private handleErrors = <R extends IErrorResponse>(response: R): R => {
		if (response.code || response.message) {
			throw response.message;
		}
		if (response.error && response.error.status !== 200) {
			window.location.href = '/';
			AuthService.clearLocalStorage();
		}
		return response;
	};
}

export default new ApiService();

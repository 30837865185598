import React from 'react';
import logo from 'assets/logo.svg';
import { Layout, Logo, Title } from './styles';
import ForgotPasswordForm from 'modules/ForgotPassword/ForgotPasswordForm';

export default () => (
	<Layout>
		<Logo src={logo} />
		<Title>Forgot Password</Title>
		<ForgotPasswordForm />
	</Layout>
);

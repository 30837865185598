import styled from 'styled-components';
import { Form, Field, ErrorMessage } from 'formik';
import { atTablet } from 'utils/theme';

export const StyledForm = styled(Form)`
	display: flex;
	flex-direction: column;
	width: 100%;
	${atTablet(`
		max-width: 430px;
	`)}
`;

export const FormFieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1.25rem;
`;

export const FormField = styled(Field)`
	background-color: rgba(255, 255, 255, 0.015);
	color: ${({ theme }) => theme.colors.grey};
	border: none;
	height: 45px;
	border-radius: 45px;
	padding: 0 1.25rem;
	margin-bottom: 5px;
`;

export const FormFieldErrorMessage = styled(ErrorMessage)`
	color: ${({ theme }) => theme.colors.red};
	font-size: 0.625rem;
	padding: 0 1.25rem;
`;

export const ActionContainer = styled.div`
	margin: 1.25rem auto;
`;
import React, { Component, Fragment } from 'react';
import { Container } from 'scenes/Users/styles';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import { IAuthAddProductPayload } from 'types/product';
import { ActionContainer } from 'modules/SignIn/SignInForm/styles';
import {
	FormField,
	PropertyName,
	FormFieldErrorMessage,
	PropertyArea,
	FormFeildArea,
	InputArea,
	FieldLabel,
	MessageArea,
	ImageArea,
} from '../AddProduct/style';
import Switch from '@material-ui/core/Switch';
import Button, { BUTTON_TYPE } from 'components/Button';
import Avatar from '@material-ui/core/Avatar';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';
import SubHeader from 'components/SubHeader';
import { FormContainer } from './style';
import {
	getGroupName,
	getGroupDescription,
	getGroupArchive,
	getGroupId,
	getGroupLoading,
	getGroupImage,
	getSaveType,
} from 'store/selectors/group';
import {
	updateGroupName,
	updateGroupId,
	updateGroupImage,
	updateGroupDescription,
	updateGroupArchive,
	CreateGroupAction,
	showLoader,
	UpdateGroupAction,
} from 'store/actions/group';
import { CreateGroupParams } from 'types/group';
import * as upload from '../../utils/upload';
import { SAVE_TYPE } from 'store/reducers/group';

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.min(2)
		.required(),
	description: Yup.string().default(''),
	groupId: Yup.string()
		.required()
		.matches(/^\S+$/, 'Group Id must not contain space in between'),
	archived: Yup.boolean().default(false),
});

interface IProps {}

interface IStoreProps {
	name: ReturnType<typeof getGroupName>;
	description: ReturnType<typeof getGroupDescription>;
	image: ReturnType<typeof getGroupImage>;
	groupId: ReturnType<typeof getGroupId>;
	archived: ReturnType<typeof getGroupArchive>;
	loading: ReturnType<typeof getGroupLoading>;
	saveType: ReturnType<typeof getSaveType>;
}

interface IDispatchProps {
	updateGroupName: (value: string) => void;
	updateGroupDescription: (value: string) => void;
	updateGroupId: (value: string) => void;
	updateGroupImage: (value: string) => void;
	updateGroupArchive: (value: boolean) => void;
	createGroup: (param: any) => void;
	showLoader: (param: any) => void;
	updateGroup: (param: any) => void;
}

interface IProps extends IStoreProps, IDispatchProps {}

class AddGroup extends Component<IProps> {
	state = {
		file: null,
	};

	renderForm(formikProps: any) {
		const {
			image,
			name,
			description,
			groupId,
			updateGroupName,
			updateGroupDescription,
			updateGroupId,
			updateGroupImage,
			updateGroupArchive,
			archived,
			loading,
			saveType,
		} = this.props;
		return (
			<Fragment>
				<FormFeildArea style={{ justifyContent: 'center', alignItems: 'center' }}>
					<p>Group Details : </p>
				</FormFeildArea>
				<FormFeildArea style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}>
					<PropertyArea>
						<PropertyName>Photo/Icon:</PropertyName>
					</PropertyArea>
					<InputArea style={{ flexDirection: 'row' }}>
						<ImageArea>
							<Avatar
								alt="Remy Sharpp"
								src={image}
								style={{ width: 80, height: 80, borderRadius: 0 }}
							/>
							<input
								onChange={async (e: any) => {
									const file = e.target.files[0];
									updateGroupImage(URL.createObjectURL(file));
									this.setState({ file });
								}}
								type="file"
							/>
						</ImageArea>
					</InputArea>
				</FormFeildArea>
				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Name: *</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="name"
								name="name"
								value={name}
								onChange={e => {
									updateGroupName(e.target.value);
									return formikProps.handleChange(e);
								}}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>
				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Description:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="description"
								name="description"
								value={description}
								onChange={e => {
									updateGroupDescription(e.target.value);
									return formikProps.handleChange(e);
								}}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="description" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>
				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Group Id: *</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="vyooPlus"
								name="groupId"
								disabled={saveType === SAVE_TYPE.UPDATE}
								value={groupId}
								onChange={e => {
									updateGroupId(e.target.value);
									return formikProps.handleChange(e);
								}}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="groupId" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Archived:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<Switch
								checked={archived}
								value="archived"
								onChange={() => updateGroupArchive(!archived)}
							/>
						</FieldLabel>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<ActionContainer
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'flex-end',
							marginRight: 10,
						}}
					>
						<Button
							disabled={false}
							kind={BUTTON_TYPE.Primary}
							loading={loading}
							onClick={() => formikProps.handleSubmit()}
							type="submit"
						>
							{saveType === SAVE_TYPE.NEW ? 'Submit' : 'Update'}
						</Button>
					</ActionContainer>
				</FormFeildArea>
			</Fragment>
		);
	}

	onSubmitForm = async (param: CreateGroupParams) => {
		const { createGroup, showLoader, saveType, updateGroup } = this.props;
		const { file } = this.state;

		// check if image is uploaded?
		if (file) {
			showLoader(true);
			const data = await upload.handleUpload(file);
			param.image = data.url;
		}
		return saveType === SAVE_TYPE.NEW ? createGroup(param) : updateGroup(param);
	};

	render() {
		const { name, description, groupId, archived, image } = this.props;
		return (
			<Container>
				<div style={{ flex: 0.1, display: 'flex' }}>
					<DashboardSceneHeader
						title={''}
						shadow={false}
						style={{ flex: 1, backgroundColor: 'white' }}
					/>
				</div>
				<SubHeader title={'Groups'} subTitle={'Edit Group'} />
				<div style={{ display: 'flex', flex: 0.8, margin: 15 }}>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
					<div style={{ display: 'flex', flex: 0.6 }}>
						<FormContainer>
							<Formik
								initialValues={{ name, description, groupId, archived }}
								onSubmit={() =>
									this.onSubmitForm({
										name,
										description,
										subscriptionId: groupId,
										archived,
										image,
									})
								}
								validationSchema={validationSchema}
							>
								{formikProps => this.renderForm(formikProps)}
							</Formik>
						</FormContainer>
					</div>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
				</div>
			</Container>
		);
	}
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		name: getGroupName(state),
		description: getGroupDescription(state),
		archived: getGroupArchive(state),
		groupId: getGroupId(state),
		loading: getGroupLoading(state),
		image: getGroupImage(state),
		saveType: getSaveType(state),
	}),
	dispatch => ({
		updateGroupName: (value: string) => dispatch(updateGroupName(value)),
		updateGroupId: (value: string) => dispatch(updateGroupId(value)),
		updateGroupImage: (value: string) => dispatch(updateGroupImage(value)),
		updateGroupArchive: (value: boolean) => dispatch(updateGroupArchive(value)),
		updateGroupDescription: (value: string) => dispatch(updateGroupDescription(value)),
		showLoader: (value: boolean) => dispatch(showLoader(value)),
		createGroup: (param: any) => dispatch(new CreateGroupAction(param)),
		updateGroup: (param: any) => dispatch(new UpdateGroupAction(param)),
	}),
)(AddGroup);

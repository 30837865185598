import React, { createRef, Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Container } from 'scenes/Users/styles';
import SubHeader from 'components/SubHeader';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import { Formik, FormikProps } from 'formik';
import { throttle, last } from 'lodash';
import * as Yup from 'yup';
import Button, { BUTTON_TYPE } from 'components/Button';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ActionContainer } from 'modules/SignIn/SignInForm/styles';
import moment from 'moment';

import {
	FormField,
	PropertyName,
	FormFieldErrorMessage,
	PropertyArea,
	FormFeildArea,
	InputArea,
	FieldLabel,
	FieldLabel2,
	MessageArea,
} from './style';

import { getCreated, jobNames, getWorkspaceDetail } from 'store/selectors/workspace';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';

import { IUpdateWorkspaceParams } from 'types/workspaces';
// import { CreateAdminAction, UpdateAdminAction } from 'store/actions/admin';
import {
	UpdateWorkspaceAction,
	CreateWorkspaceAction,
	LoadAgendaJobNames,
	LoadWorkspaceDetail,
} from 'store/actions/workspace';

const initialFormValues: IUpdateWorkspaceParams = {
	_id: '',
	name: '',
	features: [],
	isActive: false,
	s3BucketName: '',
	transcribeBucketName: '',
	nodeBBAdminToken: '',
	nodeBBMasterToken: '',
	nodeBBBaseUrl: '',
	termsAndConditionUrl: '',
	privacyPolicyUrl: '',
	appVersion: '',
	cdnUrl: '',
	shopifyDomain: '',
	storefrontAccessToken: '',
	smsHash: '',
	emails: [],
	firebaseUrl: '',
	blackListJobs: [],
	whiteListNodebbUsers: [],
	promotionText: '',
	conversationPersonaIds: [],
	automatedConnectionPersonaIds: [],
	hubspotKey: '',
	firebaseWebApiKey: '',
	rssFeedUrl: [],
	loyaltyPrefix: '',
	gifApiKey: '',
	androidStoreUrl: '',
	iOSStoreUrl: '',
	androidId: '',
	iosId: '',
	vyooServiceFees: 0,
	iosAppStoreId: '',
	bannerAdConfig: '{}',
};

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.min(2)
		.required(),
	features: Yup.array(),
	s3BucketName: Yup.string(),
	transcribeBucketName: Yup.string(),
	nodeBBAdminToken: Yup.string(),
	nodeBBMasterToken: Yup.string(),
	nodeBBBaseUrl: Yup.string(),
	termsAndConditionUrl: Yup.string(),
	privacyPolicyUrl: Yup.string(),
	appVersion: Yup.string(),
	cdnUrl: Yup.string(),
	shopifyDomain: Yup.string(),
	storefrontAccessToken: Yup.string(),
	smsHash: Yup.string(),
	emails: Yup.array(),
	firebaseUrl: Yup.string(),
	blackListJobs: Yup.array(),
	whiteListNodebbUsers: Yup.array(),
	promotionText: Yup.array(),
	conversationPersonaIds: Yup.array(),
	automatedConnectionPersonaIds: Yup.array(),
	hubspotKey: Yup.string(),
	firebaseWebApiKey: Yup.string(),
	rssFeedUrl: Yup.array(),
	loyaltyPrefix: Yup.string(),
	gifApiKey: Yup.string(),
	androidStoreUrl: Yup.string(),
	iOSStoreUrl: Yup.string(),
	vyooServiceFees: Yup.number(),
	iosAppStoreId: Yup.string(),
	bannerAdConfig: Yup.string(),
});

interface IState extends IUpdateWorkspaceParams {}

interface IProps {
	saveType: string;
}

interface IStoreProps {
	created: ReturnType<typeof getCreated>;
	jobNames: ReturnType<typeof jobNames>;
	workspaceDetail: ReturnType<typeof getWorkspaceDetail>;
}

interface IDispatchProps {
	createWorkspace: (param: any) => void;
	updateWorkspace: (param: any) => void;
	loadAgendaJobNames: () => void;
	loadWorkspaceDetail: (param: any) => void;
}

interface IProps
	extends IStoreProps,
		IDispatchProps,
		RouteComponentProps<{ id?: string }, any, any> {}

class AddWorkspace extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			...initialFormValues,
		};

		this.isAdd = this.isAdd.bind(this);
	}

	isAdd() {
		return !this.props.match.params.id;
	}

	private formRef = createRef<Formik<IUpdateWorkspaceParams>>();
	private submitForm = throttle(() => console.log('Subbmited'), 1000);

	onSubmitForm = async () => {
		if (this.isAdd()) {
			this.props.createWorkspace({ name: this.state.name, isActive: this.state.isActive });
		} else {
			this.props.updateWorkspace({ ...this.state });
		}
	};

	componentDidMount() {
		this.props.loadAgendaJobNames();
		this.props.loadWorkspaceDetail({ name: this.props.location.state.name });
		if (this.isAdd()) {
		} else {
			const {
				location: { state },
			} = this.props;
			const {
				_id,
				name,
				features,
				active,
				s3BucketName,
				transcribeBucketName,
				nodeBBAdminToken,
				nodeBBMasterToken,
				nodeBBBaseUrl,
				termsAndConditionUrl,
				privacyPolicyUrl,
				appVersion,
				cdnUrl,
				shopifyDomain,
				storefrontAccessToken,
				smsHash,
				emails,
				firebaseUrl,
				blackListJobs,
				whiteListNodebbUsers,
				promotionText,
				conversationPersonaIds,
				automatedConnectionPersonaIds,
				hubspotKey,
				firebaseWebApiKey,
				rssFeedUrl,
				loyaltyPrefix,
				gifApiKey,
				androidStoreUrl,
				iOSStoreUrl,
				iosId,
				androidId,
				vyooServiceFees,
				iosAppStoreId,
				bannerAdConfig,
			} = state;
			this.setState({
				_id,
				name,
				features,
				isActive: active,
				s3BucketName,
				transcribeBucketName,
				nodeBBAdminToken,
				nodeBBMasterToken,
				nodeBBBaseUrl,
				termsAndConditionUrl,
				privacyPolicyUrl,
				appVersion,
				cdnUrl,
				shopifyDomain,
				storefrontAccessToken,
				smsHash,
				emails,
				firebaseUrl,
				blackListJobs,
				whiteListNodebbUsers,
				promotionText,
				conversationPersonaIds,
				automatedConnectionPersonaIds,
				hubspotKey,
				firebaseWebApiKey,
				rssFeedUrl,
				loyaltyPrefix,
				gifApiKey,
				androidStoreUrl,
				iOSStoreUrl,
				iosId,
				androidId,
				vyooServiceFees,
				iosAppStoreId,
				bannerAdConfig,
			});
		}
	}

	componentWillReceiveProps(nextProps: IProps) {
		const { created } = nextProps;
		if (this.props.created === true && created === null) {
			this.props.history.push('/workspaces');
		}
	}

	render() {
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader
						title={''}
						shadow={false}
						style={{ flex: 1, backgroundColor: 'white' }}
					/>
				</div>
				<SubHeader
					title={this.isAdd() ? 'Add Workspace' : 'Update Workspace'}
					link={'/workspaces'}
					subTitle={'Enter workspace details and save'}
					onPressButton={() => this.onSubmitForm()}
					cancelText={'Cancel'}
				/>
				<div style={{ display: 'flex', flex: 0.8, margin: 15 }}>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
					<div style={{ display: 'flex', flex: 0.6 }}>
						<Formik
							initialValues={initialFormValues}
							validationSchema={validationSchema}
							onSubmit={this.submitForm}
							ref={this.formRef}
							render={this.renderScene}
						/>
					</div>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
				</div>
			</Container>
		);
	}

	onChangeFeatureToggle = (name: any) => {
		const { features } = this.state;
		const updatedFeatures = features;
		const index = features.findIndex(f => f.name === name);
		updatedFeatures[index].isEnabled = !updatedFeatures[index].isEnabled;
		this.setState({
			features: updatedFeatures,
		});
	};
	renderFeatures = () => {
		const { features } = this.state;
		if (features && features.length) {
			return (
				<div>
					<FormFeildArea>
						<PropertyArea>
							<PropertyName>Features:</PropertyName>
						</PropertyArea>
					</FormFeildArea>
					{features.map(f => {
						return (
							<div key={f.name}>
								<FormFeildArea>
									<PropertyArea>
										<PropertyName>{f.name}</PropertyName>
									</PropertyArea>
									<InputArea>
										<FieldLabel2>
											<Switch
												checked={f.isEnabled}
												value="isGlobal"
												onChange={() => this.onChangeFeatureToggle(f.name)}
											/>
										</FieldLabel2>
									</InputArea>
								</FormFeildArea>
							</div>
						);
					})}
				</div>
			);
		}
	};

	updateIsActive(value: boolean) {
		this.setState({
			isActive: value,
		});
	}

	renderCommunityStats() {
		const { workspaceDetail } = this.props;
		console.log(workspaceDetail, 'workspace detail is');
		const {
			totalActiveUsers,
			totalSignups,
			createdAt,
			lastActivityDate,
			totalPosts,
			totalTransactions,
			totalSubscriptions,
		} = workspaceDetail;
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
				<PropertyArea>
					<PropertyName>Total Active Users: {totalActiveUsers}</PropertyName>
				</PropertyArea>
				<PropertyArea>
					<PropertyName>Total Signups: {totalSignups}</PropertyName>
				</PropertyArea>
				<PropertyArea>
					<PropertyName>
						Community Created At:{' '}
						{createdAt ? moment(createdAt).format('MMM Do YYYY, h:mm:ss a') : 'NA'}
					</PropertyName>
				</PropertyArea>
				<PropertyArea>
					<PropertyName>
						Last Activity At:{' '}
						{lastActivityDate ? moment(new Date(lastActivityDate)).fromNow() : 'Na'}
					</PropertyName>
				</PropertyArea>
				<PropertyArea>
					<PropertyName>Total Posts: {totalPosts}</PropertyName>
				</PropertyArea>
				<PropertyArea>
					<PropertyName>Total Transactions: {totalTransactions}</PropertyName>
				</PropertyArea>
				<PropertyArea>
					<PropertyName>Total Subscriptions: {totalSubscriptions}</PropertyName>
				</PropertyArea>
			</div>
		);
	}

	private renderScene = ({}: FormikProps<IUpdateWorkspaceParams>) => {
		const { jobNames } = this.props;
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					backgroundColor: 'white',
					flexDirection: 'column',
				}}
			>
				<FormFeildArea style={{ justifyContent: 'center', alignItems: 'center' }}>
					<p>Workspace details: </p>
				</FormFeildArea>
				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Name:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Name"
								name="name"
								value={this.state.name}
								onChange={e => this.setState({ name: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>S3 Bucketname:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Enter bucket name"
								name="Enter bucket name"
								value={this.state.s3BucketName}
								onChange={e => this.setState({ s3BucketName: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Transcribe Bucketname:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Enter transcribe bucket name"
								name="Enter transcribe bucket name"
								value={this.state.transcribeBucketName}
								onChange={e =>
									this.setState({ transcribeBucketName: e.target.value })
								}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Nodebb baseurl:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Enter Base url"
								name="base url"
								value={this.state.nodeBBBaseUrl}
								onChange={e => this.setState({ nodeBBBaseUrl: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Nodebb Admin token:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Enter token"
								name="Enter token"
								value={this.state.nodeBBAdminToken}
								onChange={e => this.setState({ nodeBBAdminToken: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Nodebb Master token:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Enter token"
								name="Enter token"
								value={this.state.nodeBBMasterToken}
								onChange={e => this.setState({ nodeBBMasterToken: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Black list jobs :</PropertyName>
					</PropertyArea>
					<InputArea>
						<Autocomplete
							multiple
							limitTags={2}
							id="multiple-limit-tags"
							options={jobNames}
							onChange={(_, value: any) => this.setState({ blackListJobs: value })}
							getOptionLabel={(option: any) => option.name}
							value={this.state.blackListJobs}
							renderInput={(params: any) => (
								<TextField
									{...params}
									variant="outlined"
									label="Job name"
									placeholder="name"
								/>
							)}
						/>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Terms & condition url:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Enter url"
								name="Enter url"
								value={this.state.termsAndConditionUrl}
								onChange={e =>
									this.setState({ termsAndConditionUrl: e.target.value })
								}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Gif api key:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Gif api key"
								name="Gif api key"
								value={this.state.gifApiKey}
								onChange={e => this.setState({ gifApiKey: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Android Store Url:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Android Store Url"
								name="Android Store Url"
								value={this.state.androidStoreUrl}
								onChange={e => this.setState({ androidStoreUrl: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Ios Store url:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Ios Store url"
								name="Ios Store url"
								value={this.state.iOSStoreUrl}
								onChange={e => this.setState({ iOSStoreUrl: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Firebase web key:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Enter key"
								name="Enter key"
								value={this.state.firebaseWebApiKey}
								onChange={e => this.setState({ firebaseWebApiKey: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Vyoo services fee:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Enter fees"
								name="Enter fees"
								type="number"
								value={this.state.vyooServiceFees}
								onChange={e =>
									this.setState({ vyooServiceFees: Number(e.target.value) })
								}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Loyalty prefix:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="prefix"
								name="prefix"
								value={this.state.loyaltyPrefix}
								onChange={e => this.setState({ loyaltyPrefix: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Rss feed url:</PropertyName>
					</PropertyArea>
					<InputArea>
						<Autocomplete
							multiple
							limitTags={2}
							id="multiple-limit-tags"
							options={[]}
							onChange={(e: any, value: any) => {
								if (
									e.keyCode === 8 ||
									value.length < this.state.rssFeedUrl.length
								) {
									this.setState({ rssFeedUrl: value });
									return;
								}
								const lastValue = last(value);
								this.setState({
									rssFeedUrl: [...this.state.rssFeedUrl, { name: lastValue }],
								});
							}}
							getOptionLabel={(option: any) => option.name}
							value={this.state.rssFeedUrl}
							freeSolo
							renderInput={(params: any) => (
								<TextField
									{...params}
									variant="outlined"
									label="rssFeedUrl"
									placeholder="rssFeedUrl"
								/>
							)}
						/>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>ios app store Id:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="IOS app store id"
								name="store id"
								value={this.state.iosAppStoreId}
								onChange={e => this.setState({ iosAppStoreId: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Privacy url:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Enter url"
								name="Enter url"
								value={this.state.privacyPolicyUrl}
								onChange={e => this.setState({ privacyPolicyUrl: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>App version</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="version"
								name="version"
								value={this.state.appVersion}
								onChange={e => this.setState({ appVersion: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Sms hash</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="hash"
								name="hash"
								value={this.state.smsHash}
								onChange={e => this.setState({ smsHash: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Hubspot key</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="hubspotKey"
								name="hubspotKey"
								value={this.state.hubspotKey}
								onChange={e => this.setState({ hubspotKey: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="hubspotKey" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Feedback Emails</PropertyName>
					</PropertyArea>
					<InputArea>
						<Autocomplete
							multiple
							limitTags={2}
							id="multiple-limit-tags"
							options={[]}
							onChange={(e: any, value: any) => {
								if (e.keyCode === 8 || value.length < this.state.emails.length) {
									this.setState({ emails: value });
									return;
								}
								const lastValue = last(value);
								this.setState({
									emails: [...this.state.emails, { email: lastValue }],
								});
							}}
							getOptionLabel={(option: any) => option.email}
							value={this.state.emails}
							freeSolo
							renderInput={(params: any) => (
								<TextField
									{...params}
									variant="outlined"
									label="email"
									placeholder="email"
								/>
							)}
						/>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Chat promotion text </PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="text"
								name="text"
								value={this.state.promotionText}
								onChange={e => this.setState({ promotionText: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="text" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Chat Promotion PersonaIds</PropertyName>
					</PropertyArea>
					<InputArea>
						<Autocomplete
							multiple
							limitTags={3}
							id="multiple-limit-tags"
							options={[]}
							onChange={(e: any, value: any) => {
								if (
									e.keyCode === 8 ||
									value.length < this.state.conversationPersonaIds.length
								) {
									this.setState({ conversationPersonaIds: value });
									return;
								}
								const lastValue = last(value);
								this.setState({
									conversationPersonaIds: [
										...this.state.conversationPersonaIds,
										{ name: lastValue },
									],
								});
							}}
							getOptionLabel={(option: any) => option.name}
							value={this.state.conversationPersonaIds}
							freeSolo
							renderInput={(params: any) => (
								<TextField
									{...params}
									variant="outlined"
									label="id"
									placeholder="id"
								/>
							)}
						/>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Automated connection ids</PropertyName>
					</PropertyArea>
					<InputArea>
						<Autocomplete
							multiple
							limitTags={3}
							id="multiple-limit-tags"
							options={[]}
							onChange={(e: any, value: any) => {
								if (
									e.keyCode === 8 ||
									value.length < this.state.automatedConnectionPersonaIds.length
								) {
									this.setState({ automatedConnectionPersonaIds: value });
									return;
								}
								const lastValue = last(value);
								this.setState({
									automatedConnectionPersonaIds: [
										...this.state.automatedConnectionPersonaIds,
										{ name: lastValue },
									],
								});
							}}
							getOptionLabel={(option: any) => option.name}
							value={this.state.automatedConnectionPersonaIds}
							freeSolo
							renderInput={(params: any) => (
								<TextField
									{...params}
									variant="outlined"
									label="id"
									placeholder="id"
								/>
							)}
						/>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Node bb white list users</PropertyName>
					</PropertyArea>
					<InputArea>
						<Autocomplete
							multiple
							limitTags={2}
							id="multiple-limit-tags"
							options={[]}
							onChange={(e: any, value: any) => {
								if (
									e.keyCode === 8 ||
									value.length < this.state.whiteListNodebbUsers.length
								) {
									this.setState({ whiteListNodebbUsers: value });
									return;
								}
								const lastValue = last(value);
								this.setState({
									whiteListNodebbUsers: [
										...this.state.whiteListNodebbUsers,
										{ name: lastValue },
									],
								});
							}}
							getOptionLabel={(option: any) => option.name}
							value={this.state.whiteListNodebbUsers}
							freeSolo
							renderInput={(params: any) => (
								<TextField
									{...params}
									variant="outlined"
									label="email"
									placeholder="email"
								/>
							)}
						/>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>CDN url</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="url"
								name="url"
								value={this.state.cdnUrl}
								onChange={e => this.setState({ cdnUrl: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="cdnUrl" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Shopify Domain</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="domain"
								name="domain"
								value={this.state.shopifyDomain}
								onChange={e => this.setState({ shopifyDomain: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="cdnUrl" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>storefrontAccessToken</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="token"
								name="token"
								value={this.state.storefrontAccessToken}
								onChange={e =>
									this.setState({ storefrontAccessToken: e.target.value })
								}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="token" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>firebase function Url</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="url"
								name="url"
								value={this.state.firebaseUrl}
								onChange={e => this.setState({ firebaseUrl: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="token" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Is Active:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<Switch
								checked={this.state.isActive}
								value="active"
								onChange={() => this.updateIsActive(!this.state.isActive)}
							/>
						</FieldLabel>
					</InputArea>
				</FormFeildArea>
				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Android Id</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Android ID.. i.e. com.example.app"
								name="androidId"
								value={this.state.androidId}
								onChange={e => this.setState({ androidId: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="token" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>
				<FormFeildArea>
					<PropertyArea>
						<PropertyName>iOS Id</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="iOS ID.. i.e. com.example.app"
								name="iosId"
								value={this.state.iosId}
								onChange={e => this.setState({ iosId: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="token" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Banner Add config</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="bannerAdConfig"
								name="bannerAdConfig"
								value={this.state.bannerAdConfig}
								onChange={e => this.setState({ bannerAdConfig: e.target.value })}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="token" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>
				{this.renderCommunityStats()}

				{this.renderFeatures()}
				<FormFeildArea>
					<ActionContainer
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'flex-end',
							marginRight: 10,
						}}
					>
						<Button
							disabled={false}
							loading={this.props.created === true}
							kind={BUTTON_TYPE.Primary}
							type="submit"
							onClick={() => this.onSubmitForm()}
						>
							Save
						</Button>
					</ActionContainer>
				</FormFeildArea>
			</div>
		);
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		created: getCreated(state),
		jobNames: jobNames(state),
		workspaceDetail: getWorkspaceDetail(state),
	}),
	dispatch => ({
		createWorkspace: (param: any) => dispatch(new CreateWorkspaceAction(param)),
		updateWorkspace: (param: any) => dispatch(new UpdateWorkspaceAction(param)),
		loadAgendaJobNames: () => dispatch(new LoadAgendaJobNames()),
		loadWorkspaceDetail: (param: any) => dispatch(new LoadWorkspaceDetail(param)),
	}),
)(withRouter(AddWorkspace));

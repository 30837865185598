import {
	LoadFundraiserDonationsAction,
	LoadFundraiserDonationsSuccessAction,
	LoadFundraiserDonationsFailAction,
	ActionTypes,
} from '../actions/fundraiserDonations';
import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import FundraiserDonationsService from 'services/fundraiserDonations';

export const getFundraiserDonations: Epic<
	Actions,
	LoadFundraiserDonationsSuccessAction | LoadFundraiserDonationsFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadFundraiserDonationsAction>(ActionTypes.LOAD_FUNDRAISER_DONATIONS),
		switchMap(() =>
			from(FundraiserDonationsService.getFundraiserDonations()).pipe(
				map(response => {
					return new LoadFundraiserDonationsSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadFundraiserDonationsFailAction(error));
				}),
			),
		),
	);

export default [getFundraiserDonations];

import { Middleware } from 'redux';
import { createLogger } from 'redux-logger';

import { isProduction } from '@constants';
import toPlaneObject from 'store/middlewares/toPlaneObject';
import epicMiddleware from 'store/middlewares/epic';
import routerMiddleware from 'store/middlewares/router';

let middlewares: Middleware[] = [toPlaneObject, epicMiddleware, routerMiddleware];

if (!isProduction) {
	const logger: Middleware = createLogger({});
	middlewares = [...middlewares, logger];
}

export default middlewares;

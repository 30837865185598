import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { CACHE_VERSION, PERSIST_TIMEOUT } from '@constants';
import middlewares from 'store/middlewares';
import { reducer as rootReducer, state as initialState } from 'store/reducers';
import epicMiddleware from 'store/middlewares/epic';
import epics from 'store/effects';

const mainPersistConfig = {
	storage,
	key: 'root',
	whitelist: ['settings', 'user'],
	timeout: PERSIST_TIMEOUT,
	keyPrefix: CACHE_VERSION,
};

const reducer = combineReducers(rootReducer);

const mainReducer = persistReducer(mainPersistConfig, reducer);

export const store = createStore(
	mainReducer,
	initialState,
	compose(applyMiddleware(...middlewares)),
);
export const persistor = persistStore(store);

epicMiddleware.run(epics);

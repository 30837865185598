import styled from 'styled-components';

export const Container = styled.div`
	height: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1.5rem;
	background-color: white;
`;

export const Title = styled.p`
	color: ${({ theme }) => theme.colors.shipGray};
`;

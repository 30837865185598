import React, { Component } from 'react';
import { Container } from 'scenes/Users/styles';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import ProductItem from 'components/ProductItem';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';
import {
	getLoading,
	getProducts,
	getSearchedData,
	getSearchedValue,
	getCheckedValue,
} from 'store/selectors/product';
import {
	FetchAllProducts,
	updateProductSaveType,
	updateProductDescription,
	updateProductArchived,
	updateProductBadge,
	updateProductCoins,
	updateProductMonths,
	updateProductName,
	updateProductPrice,
	updateProductStore,
	updateProductType,
	updateProductId,
	updateProductImage,
	updateSearchedValue,
	updateChecked,
	updateSubscriptionId,
} from 'store/actions/product';
import { theme } from 'utils/theme';
import { Notice } from 'modules/Dashboard/Widgets/NewUsersWidget/styles';
import ContainerCenter from 'components/ContainerCenter';
import { CircleSpinner } from 'react-spinners-kit';
import SubHeader from 'components/SubHeader';
import { IProductPayload } from 'types/product';

interface IProps {}

interface IStoreProps {
	loading: ReturnType<typeof getLoading>;
	products: ReturnType<typeof getProducts>;
	data: ReturnType<typeof getSearchedData>;
	searchValue: ReturnType<typeof getSearchedValue>;
	checked: ReturnType<typeof getCheckedValue>;
}

interface IDispatchProps {
	fetchAllProducts: () => void;
	updateProductSaveType: (value: string) => void;
	updateProductName: (value: string) => void;
	updateProductDescription: (value: string) => void;
	updateProductPrice: (value: number) => void;
	updateProductMonths: (value: number) => void;
	updateProductType: (value: string) => void;
	updateProductStore: (value: string) => void;
	updateProductArchived: (value: boolean) => void;
	updateProductBadge: (value: boolean) => void;
	updateProductCoins: (value: number) => void;
	updateProductId: (value: string) => void;
	updateProductImage: (value: string) => void;
	updateSearchedValue: (value: string) => void;
	updateCheckedValue: (value: boolean) => void;
	updateSubscriptionId: (value: string) => void;
}

interface IProps extends IStoreProps, IDispatchProps {}

class ProductScene extends Component<IProps> {
	componentDidMount() {
		this.props.fetchAllProducts();
	}

	render() {
		const {
			updateProductSaveType,
			searchValue,
			updateSearchedValue,
			checked,
			updateCheckedValue,
			data,
		} = this.props;
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader title={''} shadow={false} style={{ flex: 1 }} />
				</div>
				<div style={{ width: '100%' }}>
					<SubHeader
						title={'Products'}
						searchbar
						searchPlaceHolder={'Search'}
						searchValue={searchValue}
						buttonText={'Add products'}
						total={data.length || null}
						link={'/add/product'}
						showCheckbox
						checkBoxText={'Include archived products'}
						checkboxValue={checked}
						onChangeCheckbox={() => {
							if (!checked) updateCheckedValue(true);
							if (checked) updateCheckedValue(false);
						}}
						onChangeSearchBarText={e => updateSearchedValue(e.target.value)}
						onPressButton={() => updateProductSaveType('new')}
					/>
				</div>
				<div style={{ display: 'flex', flex: 0.8 }}>
					<div
						style={{
							display: 'flex',
							flex: 1,
							// margin: 15,
							backgroundColor: '#f2f3f8',
							flexWrap: 'wrap',
						}}
					>
						{this.renderContent()}
					</div>
				</div>
			</Container>
		);
	}

	private renderContent = () => {
		const { loading, data } = this.props;
		if (loading) {
			return (
				<ContainerCenter>
					<CircleSpinner size={25} color={theme.colors.cornflowerBlue} />
				</ContainerCenter>
			);
		}
		if (!data || !data.length) {
			return (
				<ContainerCenter>
					<Notice>Don't have any products</Notice>
				</ContainerCenter>
			);
		}
		return this.renderProductList();
	};

	private renderProductList = () => {
		const {
			updateProductSaveType,
			updateProductName,
			updateProductArchived,
			updateProductBadge,
			updateProductCoins,
			updateProductDescription,
			updateProductMonths,
			updateProductPrice,
			updateProductStore,
			updateProductType,
			updateProductId,
			updateProductImage,
			data,
			updateSubscriptionId,
		} = this.props;
		return data.map(p => (
			<ProductItem
				item={p}
				onPressEdit={() => updateProductSaveType('update')}
				selectedItem={(item: IProductPayload) => {
					const {
						name,
						price,
						archived,
						popular,
						coins,
						months,
						description,
						store,
						type,
						productId,
						image,
						subscriptionId,
					} = item;
					updateSubscriptionId(subscriptionId);
					updateProductName(name);
					updateProductArchived(archived);
					updateProductPrice(price);
					updateProductBadge(popular);
					updateProductCoins(coins);
					updateProductMonths(months);
					updateProductDescription(description);
					updateProductStore(store);
					updateProductType(type);
					updateProductId(productId);
					updateProductImage(image);
				}}
				path={'/add/product'}
			/>
		));
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getLoading(state),
		products: getProducts(state),
		data: getSearchedData(state),
		searchValue: getSearchedValue(state),
		checked: getCheckedValue(state),
	}),
	dispatch => ({
		fetchAllProducts: () => dispatch(new FetchAllProducts()),
		updateProductSaveType: (value: string) => dispatch(updateProductSaveType(value)),
		updateProductName: (value: string) => dispatch(updateProductName(value)),
		updateProductDescription: (value: string) => dispatch(updateProductDescription(value)),
		updateProductPrice: (value: number) => dispatch(updateProductPrice(value)),
		updateProductMonths: (value: number) => dispatch(updateProductMonths(value)),
		updateProductType: (value: string) => dispatch(updateProductType(value)),
		updateProductStore: (value: string) => dispatch(updateProductStore(value)),
		updateProductArchived: (value: boolean) => dispatch(updateProductArchived(value)),
		updateProductBadge: (value: boolean) => dispatch(updateProductBadge(value)),
		updateProductCoins: (value: number) => dispatch(updateProductCoins(value)),
		updateProductId: (value: string) => dispatch(updateProductId(value)),
		updateProductImage: (value: string) => dispatch(updateProductImage(value)),
		updateSearchedValue: (value: string) => dispatch(updateSearchedValue(value)),
		updateCheckedValue: (value: boolean) => dispatch(updateChecked(value)),
		updateSubscriptionId: (value: string) => dispatch(updateSubscriptionId(value)),
	}),
)(ProductScene);

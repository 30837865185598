declare var process: any;

const {
	NODE_ENV,
	REACT_APP_USE_MOCKS,
	REACT_APP_BASE_URL: BASE_URL,
	REACT_APP_ALLOW_DELETE_WORKSPACE,
} = process.env;

export const CACHE_VERSION = '2';
export const PERSIST_TIMEOUT = 600000;
export const isProduction = NODE_ENV === 'production';
export const USE_MOCKS = REACT_APP_USE_MOCKS === 'true';
export const ACCESS_KEY_ID = 'AKIAIHKJ2MNUIOWVIF7Q';
export const SECRET_ACCESS_KEY = 'pDFoRRVMmMcUQv3aqKJDIwUpovH8uec/N/lInG/X';
export const REGION = 'us-west-2';
export const VYOO_S3_BUCKET_URL = 'yourvyoo-backgrounds.s3.us-west-2.amazonaws.com';
export const VYOO_S3_CLOUD_FRONT_URL = 'dpezmwwy93z3l.cloudfront.net';
export const BUCKET_NAME = 'yourvyoo-backgrounds';
export const ALLOW_DELETE_WORKSPACE = REACT_APP_ALLOW_DELETE_WORKSPACE
	? JSON.parse(REACT_APP_ALLOW_DELETE_WORKSPACE)
	: false;

export const colors = {
	darkPurple: '#1e1e2c',
	white: '#ffffff',
};

export { BASE_URL };

import { IFundraisersResponse } from '../types/fundraisers';
import ApiService from 'services/api';

class FundraisersService {
	getFundraisers(): Promise<IFundraisersResponse> {
		return ApiService.get<IFundraisersResponse>(
			'fundraising/allfundraisers?returnAllWorkspaces=true',
			{
				authorized: true,
			},
		);
	}
}

export default new FundraisersService();

import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import {
	IUsersResponse,
	IActiveUserResponse,
	RequestLoadActiveUserParams,
	IAppUserArchiveResponse,
	RequestMarkUserArchiveParams,
	IActiveUser,
	UserSearchRequestParams,
	IUserSearchResponse,
	RequestNewEmailUserParams,
} from 'types/users';

export enum ActionTypes {
	LOAD_USERS = '[users] LOAD_USERS',
	LOAD_USERS_SUCCESS = '[users] LOAD_USERS_SUCCESS',
	LOAD_USERS_FAIL = '[users] LOAD_USERS_FAIL',

	LOAD_ACTIVE_USERS = '[activeUsers] LOAD_ACTIVE_USERS',
	LOAD_ACTIVE_USERS_SUCCESS = '[activeUsers] LOAD_ACTIVE_USERS_SUCCESS',
	LOAD_ACTIVE_USERS_FAIL = '[activeUsers] LOAD_ACTIVE_USERS_FAIL',

	USER_SEARCH = '[activeUsers] USER_SEARCH',
	USER_SEARCH_SUCCESS = '[activeUsers] USER_SEARCH_SUCCESS',
	USER_SEARCH_FAIL = '[activeUsers] USER_SEARCH_FAIL',

	MARK_USER_ARCHIVE = '[activeUsers] MARK_USER_ARCHIVE',
	MARK_USER_ARCHIVE_SUCCESS = '[activeUsers] MARK_USER_ARCHIVE_SUCCESS',
	MARK_USER_ARCHIVE_FAIL = '[activeUsers] MARK_USER_ARCHIVE_FAIL',

	CHANGE_ACTIVE_PAGE_NUMBER = '[activeUsers] CHANGE_ACTIVE_PAGE_NUMBER',
	CHANGE_PAGE_LIMIT = '[activeUsers] CHANGE_PAGE_LIMIT',

	CHANGE_MODAL_VISIBILITY = '[activeUsers] CHANGE_MODAL_VISIBILITY',
	UPDATE_ACTIVE_USERS = '[activeUsers] UPDATE_ACTIVE_USERS',

	ON_CHANGE_SELECTED_ITEMS = '[activeUsers] ON_CHANGE_SELECTED_ITEMS',
	ON_CHANGE_SELECTED_ITEMS_ARCHIVE_VALUE = '[activeUsers] ON_CHANGE_SELECTED_ITEMS_ARCHIVE_VALUE',

	UPDATE_SEARCH_VALUE = '[activeUsers] UPDATE_SEARCH_VALUE',
	UPDATE_CHECKED_VALUE = '[activeUsers] UPDATE_CHECKED_VALUE',

	ADD_EMAIL_USER = '[user] ADD_EMAIL_USER',
	ADD_EMAIL_USER_SUCCESS = '[user] ADD_EMAIL_USER_SUCCESS',
	ADD_EMAIL_USER_FAIL = '[user] ADD_EMAIL_USER_FAIL',

	UPDATE_USER = '[user] UPDATE_USER',
	UPDATE_USER_SUCCESS = '[user] UPDATE_USER_SUCCESS',
	UPDATE_USER_FAIL = '[user] UPDATE_USER_FAIL',
}

// load users.
export class LoadUsersAction implements Action {
	readonly type = ActionTypes.LOAD_USERS;
}

type LoadUserSuccessPayload = IUsersResponse['users'];

export class LoadUsersSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_USERS_SUCCESS;
	constructor(public payload: LoadUserSuccessPayload) {}
}

export class LoadUsersFailAction implements Action {
	readonly type = ActionTypes.LOAD_USERS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// MARK user archive
export class MarkUserArchiveAction implements Action {
	readonly type = ActionTypes.MARK_USER_ARCHIVE;
	constructor(public params: RequestMarkUserArchiveParams) {}
}

type MarkUserArchiveSuccessPayload = IAppUserArchiveResponse['success'];

export class MarkUserArchiveSuccessAction implements Action {
	readonly type = ActionTypes.MARK_USER_ARCHIVE_SUCCESS;
	constructor(public payload: MarkUserArchiveSuccessPayload) {}
}

export class MarkUserArchiveFailAction implements Action {
	readonly type = ActionTypes.MARK_USER_ARCHIVE_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// load active users.
export class loadActiveUserAction implements Action {
	readonly type = ActionTypes.LOAD_ACTIVE_USERS;
	constructor(public params: RequestLoadActiveUserParams) {}
}
type LoadActiveUserSuccessPayload = IActiveUserResponse;

export class LoadActiveUsersSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_ACTIVE_USERS_SUCCESS;
	constructor(public payload: LoadActiveUserSuccessPayload) {}
}

export class LoadActiveUsersFailAction implements Action {
	readonly type = ActionTypes.LOAD_ACTIVE_USERS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// create new email user
export class CreateEmailUserAction implements Action {
	readonly type = ActionTypes.ADD_EMAIL_USER;
	constructor(public params: RequestNewEmailUserParams) {}
}
type CreateEmailUserActionSuccessPayload = IAppUserArchiveResponse['success'];

export class CreateEmailUserActionSuccessAction implements Action {
	readonly type = ActionTypes.ADD_EMAIL_USER_SUCCESS;
	constructor(public payload: CreateEmailUserActionSuccessPayload) {}
}

export class CreateEmailUserActionFailAction implements Action {
	readonly type = ActionTypes.ADD_EMAIL_USER_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// update email user
export class UpdateUserAction implements Action {
	readonly type = ActionTypes.UPDATE_USER;
	constructor(public params: RequestNewEmailUserParams) {}
}
type UpdateUserActionSuccessPayload = IAppUserArchiveResponse['success'];

export class UpdateUserSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_USER_SUCCESS;
	constructor(public payload: UpdateUserActionSuccessPayload) {}
}

export class UpdateUserFailAction implements Action {
	readonly type = ActionTypes.UPDATE_USER_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// get searched users.
export class UserSearchAction implements Action {
	readonly type = ActionTypes.USER_SEARCH;
	constructor(public params: UserSearchRequestParams) {}
}
type UserSearchSuccessPayload = IUserSearchResponse;

export class UserSearchSuccessAction implements Action {
	readonly type = ActionTypes.USER_SEARCH_SUCCESS;
	constructor(public payload: UserSearchSuccessPayload) {}
}

export class UserSearchFailAction implements Action {
	readonly type = ActionTypes.USER_SEARCH_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// change active page number
export interface ChangeActivePageNumberAction {
	type: ActionTypes.CHANGE_ACTIVE_PAGE_NUMBER;
	payload: { page: number };
}

export function changePage(page: number): ChangeActivePageNumberAction {
	return {
		type: ActionTypes.CHANGE_ACTIVE_PAGE_NUMBER,
		payload: { page },
	};
}

// change modal visibility
export interface ChangeModalVisibilityAction {
	type: ActionTypes.CHANGE_MODAL_VISIBILITY;
	payload: { visible: boolean };
}

export function changeModalVisibility(visible: boolean): ChangeModalVisibilityAction {
	return {
		type: ActionTypes.CHANGE_MODAL_VISIBILITY,
		payload: { visible },
	};
}

// on change selected Items
export interface ChangeSelectedItems {
	type: ActionTypes.ON_CHANGE_SELECTED_ITEMS;
	payload: { ids: string[] };
}

export function changeSelectedItems(ids: string[]): ChangeSelectedItems {
	return {
		type: ActionTypes.ON_CHANGE_SELECTED_ITEMS,
		payload: { ids },
	};
}

// on change selected item  archived status
export interface ChangeSelectedItemArchivedStatus {
	type: ActionTypes.ON_CHANGE_SELECTED_ITEMS_ARCHIVE_VALUE;
	payload: { archived: boolean | null };
}

export function changeSelectedItemsArchiveValue(
	archived: boolean | null,
): ChangeSelectedItemArchivedStatus {
	return {
		type: ActionTypes.ON_CHANGE_SELECTED_ITEMS_ARCHIVE_VALUE,
		payload: { archived },
	};
}

// change page limit
export interface ChangePageLimit {
	type: ActionTypes.CHANGE_PAGE_LIMIT;
	payload: { limit: number };
}

export function changePageLimit(limit: number): ChangePageLimit {
	return {
		type: ActionTypes.CHANGE_PAGE_LIMIT,
		payload: { limit },
	};
}

// on update search value
export interface ChangeSearchedValue {
	type: ActionTypes.UPDATE_SEARCH_VALUE;
	payload: { value: string };
}

export function changeSearchedValue(value: string): ChangeSearchedValue {
	return {
		type: ActionTypes.UPDATE_SEARCH_VALUE,
		payload: { value },
	};
}

// update active users
export interface UpdateActiveUsers {
	type: ActionTypes.UPDATE_ACTIVE_USERS;
	payload: { data: IActiveUser[] };
}

export function updateActiveUsers(data: IActiveUser[]): UpdateActiveUsers {
	return {
		type: ActionTypes.UPDATE_ACTIVE_USERS,
		payload: { data },
	};
}

// update checked value
export interface UpdateCheckedValue {
	type: ActionTypes.UPDATE_CHECKED_VALUE;
	payload: { value: boolean };
}

export function updateCheckedValue(value: boolean): UpdateCheckedValue {
	return {
		type: ActionTypes.UPDATE_CHECKED_VALUE,
		payload: { value },
	};
}

export type Actions =
	| LoadUsersAction
	| LoadUsersSuccessAction
	| LoadUsersFailAction
	| loadActiveUserAction
	| LoadActiveUsersSuccessAction
	| LoadActiveUsersFailAction
	| ChangeActivePageNumberAction
	| ChangePageLimit
	| UpdateActiveUsers
	| ChangeModalVisibilityAction
	| MarkUserArchiveAction
	| MarkUserArchiveSuccessAction
	| ChangeSelectedItems
	| ChangeSearchedValue
	| ChangeSelectedItemArchivedStatus
	| UpdateCheckedValue
	| UserSearchAction
	| UserSearchSuccessAction
	| UserSearchFailAction
	| MarkUserArchiveFailAction
	| CreateEmailUserAction
	| CreateEmailUserActionSuccessAction
	| CreateEmailUserActionFailAction
	| UpdateUserAction
	| UpdateUserSuccessAction
	| UpdateUserFailAction;

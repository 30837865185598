import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import {
	ActionTypes,
	AuthorizeAction,
	AuthorizeSuccessAction,
	AuthorizeFailAction,
	ForgotPasswordAction,
	ForgotPasswordFailAction,
	ForgotPasswordSuccessAction,
	ConfirmForgotPasswordAction,
	ConfirmForgotPasswordFailAction,
	ConfirmForgotPasswordSuccessAction,
	ConfirmActivationAction,
	ConfirmActivationFailAction,
	ConfirmActivationSuccessAction,
} from 'store/actions/user';
import AuthService from 'services/auth';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';

export const authorizeEpic: Epic<
	Actions,
	AuthorizeSuccessAction | AuthorizeFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, AuthorizeAction>(ActionTypes.AUTHORIZE),
		switchMap(action =>
			from(AuthService.authorize(action.payload)).pipe(
				map(response => new AuthorizeSuccessAction(response)),
				tap(_ => toast.success('Authorization success', { position: 'top-right' })),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage =
						typeof error === 'string'
							? error
							: error.message || 'Please check your credentials and try again';

					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});

					return of(new AuthorizeFailAction(error));
				}),
			),
		),
	);

export const forgotPasswordEpic: Epic<
	Actions,
	ForgotPasswordFailAction | ForgotPasswordSuccessAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, ForgotPasswordAction>(ActionTypes.FORGOT_PASSWORD),
		switchMap(action =>
			from(AuthService.forgotPassword(action.payload)).pipe(
				map(response => new ForgotPasswordSuccessAction(response)),
				tap(_ => toast.success('Reset link sent to your email', { position: 'top-right' })),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage =
						typeof error === 'string'
							? error
							: error.message || 'Please check your username and try again';

					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});

					return of(new ForgotPasswordFailAction(error));
				}),
			),
		),
	);

export const confirmForgotPasswordEpic: Epic<
	Actions,
	ConfirmForgotPasswordFailAction | ConfirmForgotPasswordSuccessAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, ConfirmForgotPasswordAction>(ActionTypes.CONFIRM_FORGOT_PASSWORD),
		switchMap(action =>
			from(AuthService.confirmForgotPassword(action.payload)).pipe(
				map(response => new ConfirmForgotPasswordSuccessAction(response)),
				tap(_ => toast.success('Password updated', { position: 'top-right' })),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage =
						typeof error === 'string'
							? error
							: error.message || 'Please check your code and try again';

					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});

					return of(new ConfirmForgotPasswordFailAction(error));
				}),
			),
		),
	);

export const confirmActivationEpic: Epic<
	Actions,
	ConfirmActivationFailAction | ConfirmActivationSuccessAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, ConfirmActivationAction>(ActionTypes.CONFIRM_ACTIVATION),
		switchMap(action =>
			from(AuthService.confirmActivation(action.payload)).pipe(
				map(response => new ConfirmActivationSuccessAction(response)),
				tap(_ => toast.success('Account activated', { position: 'top-right' })),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage =
						typeof error === 'string'
							? error
							: error.message || 'Please check your code and try again';

					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});

					return of(new ConfirmActivationFailAction(error));
				}),
			),
		),
	);

export default [
	authorizeEpic,
	forgotPasswordEpic,
	confirmActivationEpic,
	confirmForgotPasswordEpic,
];

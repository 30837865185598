import {
	CreateGroupParams,
	IGroupsResponse,
	GroupsPayload,
	AdministratorPayload,
	memberPayload,
} from './../../types/group';
import { IAppCreateProductResponse } from './../../types/users';
import { CommonError } from 'types/common';
import { IErrorResponse } from 'types/api';
import { Action } from 'redux';

export enum ActionTypes {
	UPDATE_GROUP_NAME = '[group] UPDATE_GROUP_NAME',
	UPDATE_GROUP_IMAGE = '[group] UPDATE_GROUP_IMAGE',
	UPDATE_GROUP_DESCRIPTION = '[group] UPDATE_GROUP_DESCRIPTION',
	UPDATE_GROUP_ID = '[group] UPDATE_GROUP_ID',
	SHOW_LOADER = '[group] SHOW_LOADER',
	UPDATE_CHECKED = '[group] UPDATE_CHECKED',
	UPDATE_SEARCH_VALUE = '[group] UPDATE_SEARCH_VALUE',
	UPDATE_SAVE_TYPE = '[group] UPDATE_SAVE_TYPE',

	UPDATE_GROUP_ARCHIVE = '[group] UPDATE_GROUP_ARCHIVE',

	CREATE_GROUP = '[group] CREATE_GROUP',
	CREATE_GROUP_SUCCESS = '[group] CREATE_GROUP_SUCCESS',
	CREATE_GROUP_FAIL = '[group] CREATE_GROUP_FAIL',

	CREATE_ADMINISTRATOR_GROUP = '[group] CREATE_ADMINISTRATOR_GROUP',
	CREATE_ADMINISTRATOR_GROUP_SUCCESS = '[group] CREATE_ADMINISTRATOR_GROUP_SUCCESS',
	CREATE_ADMINISTRATOR_GROUP_FAIL = '[group] CREATE_ADMINISTRATOR_GROUP_FAIL',

	CREATE_GROUP_MEMBERSHIP = '[group] CREATE_GROUP_MEMBERSHIP',
	CREATE_GROUP_MEMBERSHIP_SUCCESS = '[group] CREATE_GROUP_MEMBERSHIP_SUCCESS',
	CREATE_GROUP_MEMBERSHIP_FAIL = '[group] CREATE_GROUP_MEMBERSHIP_FAIL',

	UPDATE_GROUP_MEMBER_ACTION = '[group] UPDATE_GROUP_MEMBER_ACTION',
	UPDATE_GROUP_MEMBER_ACTION_SUCCESS = '[group] UPDATE_GROUP_MEMBER_ACTION_SUCCESS',
	UPDATE_GROUP_MEMBER_ACTION_FAIL = '[group] UPDATE_GROUP_MEMBER_ACTION_FAIL',

	UPDATE_GROUP = '[group] UPDATE_GROUP',
	UPDATE_GROUP_SUCCESS = '[group] UPDATE_GROUP_SUCCESS',
	UPDATE_GROUP_FAIL = '[group] UPDATE_GROUP_FAIL',

	FETCH_GROUPS = '[group] FETCH_GROUPS',
	FETCH_GROUPS_SUCCESS = '[group] FETCH_GROUPS_SUCCESS',
	FETCH_GROUPS_FAIL = '[group] FETCH_GROUPS_FAIL',

	FETCH_GROUP_BY_SLUG = '[group] FETCH_GROUP_BY_SLUG',
	FETCH_GROUP_BY_SLUG_SUCCESS = '[group] FETCH_GROUP_BY_SLUG_SUCCESS',
	FETCH_GROUP_BY_SLUG_FAIL = '[group] FETCH_GROUP_BY_SLUG_FAIL',
}

// show archived
export interface updateChecked {
	type: ActionTypes.UPDATE_CHECKED;
	payload: { value: boolean };
}

export function updateChecked(value: boolean): updateChecked {
	return {
		type: ActionTypes.UPDATE_CHECKED,
		payload: { value },
	};
}

// update Search value
export interface updateSearchValue {
	type: ActionTypes.UPDATE_SEARCH_VALUE;
	payload: { value: string };
}

export function updateSearchValue(value: string): updateSearchValue {
	return {
		type: ActionTypes.UPDATE_SEARCH_VALUE,
		payload: { value },
	};
}

// update saveType
export interface updateSaveType {
	type: ActionTypes.UPDATE_SAVE_TYPE;
	payload: { value: string };
}

export function updateSaveType(value: string): updateSaveType {
	return {
		type: ActionTypes.UPDATE_SAVE_TYPE,
		payload: { value },
	};
}

// create group
export class CreateGroupAction implements Action {
	readonly type = ActionTypes.CREATE_GROUP;
	constructor(public params: CreateGroupParams) {}
}

type CreateGroupSuccessPayload = IAppCreateProductResponse['success'];

export class CreateGroupSuccessAction implements Action {
	readonly type = ActionTypes.CREATE_GROUP_SUCCESS;
	constructor(public payload: CreateGroupSuccessPayload) {}
}

export class CreateGroupFailAction implements Action {
	readonly type = ActionTypes.CREATE_GROUP_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// create administrator group
export class CreateAdministratorGroupAction implements Action {
	readonly type = ActionTypes.CREATE_ADMINISTRATOR_GROUP;
	constructor(public params: AdministratorPayload) {}
}

type CreateAdministratorGroupSuccessPayload = IAppCreateProductResponse['success'];

export class CreateAdministratorGroupSuccessAction implements Action {
	readonly type = ActionTypes.CREATE_ADMINISTRATOR_GROUP_SUCCESS;
	constructor(public payload: CreateAdministratorGroupSuccessPayload) {}
}

export class CreateAdministratorGroupFailAction implements Action {
	readonly type = ActionTypes.CREATE_ADMINISTRATOR_GROUP_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// fetch group by slug
export class FetchGroupBySlugAction implements Action {
	readonly type = ActionTypes.FETCH_GROUP_BY_SLUG;
	constructor(public params: any) {}
}

export class FetchGroupBySlugSuccessAction implements Action {
	readonly type = ActionTypes.FETCH_GROUP_BY_SLUG_SUCCESS;
	constructor(public payload: any) {}
}

export class FetchGroupBySlugFailAction implements Action {
	readonly type = ActionTypes.FETCH_GROUP_BY_SLUG_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// create group membership
export class CreateGroupMembershipAction implements Action {
	readonly type = ActionTypes.CREATE_GROUP_MEMBERSHIP;
	constructor(public params: memberPayload) {}
}

export class CreateGroupMembershipActionSuccessAction implements Action {
	readonly type = ActionTypes.CREATE_GROUP_MEMBERSHIP_SUCCESS;
	constructor() {}
}

export class CreateGroupMembershipActionFailAction implements Action {
	readonly type = ActionTypes.CREATE_GROUP_MEMBERSHIP_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// update group memebers
export class UpdateGroupMemberAction implements Action {
	readonly type = ActionTypes.UPDATE_GROUP_MEMBER_ACTION;
	constructor(public params: any) {}
}

type UpdateGroupMemberActionSuccessPayload = IAppCreateProductResponse['success'];

export class UpdateGroupMemberActionSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_GROUP_MEMBER_ACTION_SUCCESS;
	constructor(public payload: UpdateGroupMemberActionSuccessPayload) {}
}

export class UpdateGroupMemberActionFailAction implements Action {
	readonly type = ActionTypes.UPDATE_GROUP_MEMBER_ACTION_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}
// update groups
export class UpdateGroupAction implements Action {
	readonly type = ActionTypes.UPDATE_GROUP;
	constructor(public params: GroupsPayload) {}
}

type UpdateGroupSuccessPayload = IAppCreateProductResponse['success'];

export class UpdateGroupSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_GROUP_SUCCESS;
	constructor(public payload: UpdateGroupSuccessPayload) {}
}

export class UpdateGroupFailAction implements Action {
	readonly type = ActionTypes.UPDATE_GROUP_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// get all groups
export class FetchGroupAction implements Action {
	readonly type = ActionTypes.FETCH_GROUPS;
	constructor() {}
}

type FetchGroupsSuccessPayload = IGroupsResponse['subscriptions'];

export class FetchGroupSuccessAction implements Action {
	readonly type = ActionTypes.FETCH_GROUPS_SUCCESS;
	constructor(
		public subscriptions: FetchGroupsSuccessPayload,
		public archived: FetchGroupsSuccessPayload,
	) {}
}

export class FetchGroupFailAction implements Action {
	readonly type = ActionTypes.FETCH_GROUPS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// show and hide loader
export interface showLoader {
	type: ActionTypes.SHOW_LOADER;
	payload: { value: boolean };
}

export function showLoader(value: boolean): showLoader {
	return {
		type: ActionTypes.SHOW_LOADER,
		payload: { value },
	};
}

// update group name
export interface updateGroupName {
	type: ActionTypes.UPDATE_GROUP_NAME;
	payload: { value: string };
}

export function updateGroupName(value: string): updateGroupName {
	return {
		type: ActionTypes.UPDATE_GROUP_NAME,
		payload: { value },
	};
}
// update group image
export interface updateGroupImage {
	type: ActionTypes.UPDATE_GROUP_IMAGE;
	payload: { value: string };
}

export function updateGroupImage(value: string): updateGroupImage {
	return {
		type: ActionTypes.UPDATE_GROUP_IMAGE,
		payload: { value },
	};
}

// update group description
export interface updateGroupDescription {
	type: ActionTypes.UPDATE_GROUP_DESCRIPTION;
	payload: { value: string };
}

export function updateGroupDescription(value: string): updateGroupDescription {
	return {
		type: ActionTypes.UPDATE_GROUP_DESCRIPTION,
		payload: { value },
	};
}

// update group ID
export interface updateGroupId {
	type: ActionTypes.UPDATE_GROUP_ID;
	payload: { value: string };
}

export function updateGroupId(value: string): updateGroupId {
	return {
		type: ActionTypes.UPDATE_GROUP_ID,
		payload: { value },
	};
}

// update group description
export interface updateGroupArchive {
	type: ActionTypes.UPDATE_GROUP_ARCHIVE;
	payload: { value: boolean };
}

export function updateGroupArchive(value: boolean): updateGroupArchive {
	return {
		type: ActionTypes.UPDATE_GROUP_ARCHIVE,
		payload: { value },
	};
}

export type Actions =
	| updateGroupName
	| updateGroupImage
	| updateGroupId
	| updateGroupDescription
	| CreateGroupAction
	| CreateGroupSuccessAction
	| CreateGroupFailAction
	| FetchGroupAction
	| FetchGroupSuccessAction
	| FetchGroupFailAction
	| showLoader
	| updateChecked
	| updateSearchValue
	| UpdateGroupAction
	| UpdateGroupSuccessAction
	| UpdateGroupFailAction
	| updateSaveType
	| CreateAdministratorGroupAction
	| CreateAdministratorGroupSuccessAction
	| CreateAdministratorGroupFailAction
	| CreateGroupMembershipActionSuccessAction
	| CreateGroupMembershipAction
	| CreateGroupMembershipActionFailAction
	| UpdateGroupMemberActionFailAction
	| UpdateGroupMemberActionSuccessAction
	| UpdateGroupMemberAction
	| FetchGroupBySlugAction
	| FetchGroupBySlugSuccessAction
	| FetchGroupBySlugFailAction
	| updateGroupArchive;

import { createSelector } from 'reselect';
import * as workspace from 'store/reducers/workspace';
import { IState } from 'store/reducers';

const getWorkSpaceState = (s: IState) => s.workspace;

export const getActiveWorkspaces = createSelector(getWorkSpaceState, workspace.getActiveWorkSpace);

export const getAllWorkSpaces = createSelector(getWorkSpaceState, workspace.getWorkSpace);

export const getLoading = createSelector(getWorkSpaceState, workspace.getLoading);

export const getCreated = createSelector(getWorkSpaceState, workspace.getCreated);

export const jobNames = createSelector(getWorkSpaceState, workspace.jobNames);

export const getWorkspaceDetail = createSelector(getWorkSpaceState, workspace.getWorkspaceDetail);

import { IProductResponse } from './../../types/product';
import { IAppCreateProductResponse, CreateProductParams } from './../../types/users';
import { CommonError } from 'types/common';
import { IErrorResponse } from 'types/api';
import { Action } from 'redux';

export enum ActionTypes {
	UPDATE_NAME = '[product] UPDATE_NAME',
	UPDATE_DESCRIPTION = '[product] UPDATE_DESCRIPTION',
	UPDATE_PRICE = '[product] UPDATE_PRICE',
	UPDATE_COINS = '[product] UPDATE_COINS',
	UPDATE_MONTHS = '[product] UPDATE_MONTHS',
	UPDATE_TYPE = '[product] UPDATE_TYPE',
	UPDATE_STORE = '[product] UPDATE_STORE',
	UPDATE_IMAGE = '[product] UPDATE_IMAGE',
	UPDATE_POPULAR = '[product] UPDATE_POPULAR',
	UPDATE_ARCHIVED = '[product] UPDATE_ARCHIVED',

	UPDATE_SEARCH_VALUE = '[product] UPDATE_SEARCH_VALUE',
	UPDATE_SUBSCRIPTION_ID = '[product] UPDATE_SUBSCRIPTION_ID',
	SHOW_LOADING = '[product] SHOW_LOADING',
	UPDATE_PRODUCT_SAVE_TYPE = '[product] UPDATE_PRODUCT_SAVE_TYPE',

	UPDATE_PRODUCT_ID = '[product] UPDATE_PRODUCT_ID',

	UPDATE_CHECKED = '[product] UPDATE_CHECKED',

	CREATE_PRODUCT = '[product] CREATE_PRODUCT',
	CREATE_PRODUCT_SUCCESS = '[product] CREATE_PRODUCT_SUCCESS',
	CREATE_PRODUCT_FAIL = '[product] CREATE_PRODUCT_FAIL',

	FETCH_PRODUCT = '[product] FETCH_PRODUCT',
	FETCH_PRODUCT_SUCCESS = '[product] FETCH_PRODUCT_SUCCESS',
	FETCH_PRODUCT_FAIL = '[product] FETCH_PRODUCT_FAIL',

	UPDATE_PRODUCT = '[product] UPDATE_PRODUCT',
	UPDATE_PRODUCT_SUCCESS = '[product] UPDATE_PRODUCT_SUCCESS',
	UPDATE_PRODUCT_FAIL = '[product] UPDATE_PRODUCT_FAIL',
}

// create product
export class CreateProductAction implements Action {
	readonly type = ActionTypes.CREATE_PRODUCT;
	constructor(public params: CreateProductParams) {}
}

type CreateProductSuccessPayload = IAppCreateProductResponse['success'];

export class CreateProductSuccessAction implements Action {
	readonly type = ActionTypes.CREATE_PRODUCT_SUCCESS;
	constructor(public payload: CreateProductSuccessPayload) {}
}

export class CreateProductFailAction implements Action {
	readonly type = ActionTypes.CREATE_PRODUCT_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// update product
export class UpdateProductAction implements Action {
	readonly type = ActionTypes.UPDATE_PRODUCT;
	constructor(public params: CreateProductParams) {}
}

type UpdateProductSuccessPayload = IAppCreateProductResponse['success'];

export class UpdateProductSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_PRODUCT_SUCCESS;
	constructor(public payload: UpdateProductSuccessPayload) {}
}

export class UpdateProductFailAction implements Action {
	readonly type = ActionTypes.UPDATE_PRODUCT_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// get all products
export class FetchAllProducts implements Action {
	readonly type = ActionTypes.FETCH_PRODUCT;
}

type FetchAllProductsSuccessPayload = IProductResponse['products'];

export class FetchAllProductsSuccessAction implements Action {
	readonly type = ActionTypes.FETCH_PRODUCT_SUCCESS;
	constructor(
		public payload: FetchAllProductsSuccessPayload,
		public archivedProducts: FetchAllProductsSuccessPayload,
	) {}
}

export class FetchAllProductsFailAction implements Action {
	readonly type = ActionTypes.FETCH_PRODUCT_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

// update product name
export interface updateProductName {
	type: ActionTypes.UPDATE_NAME;
	payload: { value: string };
}

export function updateProductName(value: string): updateProductName {
	return {
		type: ActionTypes.UPDATE_NAME,
		payload: { value },
	};
}

// update SEARCHED VALUE
export interface updateSearchedValue {
	type: ActionTypes.UPDATE_SEARCH_VALUE;
	payload: { value: string };
}

export function updateSearchedValue(value: string): updateSearchedValue {
	return {
		type: ActionTypes.UPDATE_SEARCH_VALUE,
		payload: { value },
	};
}

// update loading
export interface showLoading {
	type: ActionTypes.SHOW_LOADING;
	payload: { value: boolean };
}

export function showLoading(value: boolean): showLoading {
	return {
		type: ActionTypes.SHOW_LOADING,
		payload: { value },
	};
}

// update checked
export interface updateChecked {
	type: ActionTypes.UPDATE_CHECKED;
	payload: { value: boolean };
}

export function updateChecked(value: boolean): updateChecked {
	return {
		type: ActionTypes.UPDATE_CHECKED,
		payload: { value },
	};
}

// update product save type
export interface updateProductSaveType {
	type: ActionTypes.UPDATE_PRODUCT_SAVE_TYPE;
	payload: { value: string };
}

export function updateProductSaveType(value: string): updateProductSaveType {
	return {
		type: ActionTypes.UPDATE_PRODUCT_SAVE_TYPE,
		payload: { value },
	};
}

// update product Id
export interface updateProductId {
	type: ActionTypes.UPDATE_PRODUCT_ID;
	payload: { value: string };
}

export function updateProductId(value: string): updateProductId {
	return {
		type: ActionTypes.UPDATE_PRODUCT_ID,
		payload: { value },
	};
}

// update SUBSCRIPTIONS Id
export interface updateSubscriptionId {
	type: ActionTypes.UPDATE_SUBSCRIPTION_ID;
	payload: { value: string };
}

export function updateSubscriptionId(value: string): updateSubscriptionId {
	return {
		type: ActionTypes.UPDATE_SUBSCRIPTION_ID,
		payload: { value },
	};
}

// update product image
export interface updateProductImage {
	type: ActionTypes.UPDATE_IMAGE;
	payload: { value: string };
}

export function updateProductImage(value: string): updateProductImage {
	return {
		type: ActionTypes.UPDATE_IMAGE,
		payload: { value },
	};
}

// update product coins
export interface updateProductCoins {
	type: ActionTypes.UPDATE_COINS;
	payload: { value: number };
}

export function updateProductCoins(value: number): updateProductCoins {
	return {
		type: ActionTypes.UPDATE_COINS,
		payload: { value },
	};
}

// update product desc
export interface updateProductDescription {
	type: ActionTypes.UPDATE_DESCRIPTION;
	payload: { value: string };
}

export function updateProductDescription(value: string): updateProductDescription {
	return {
		type: ActionTypes.UPDATE_DESCRIPTION,
		payload: { value },
	};
}
// update price
export interface updateProductPrice {
	type: ActionTypes.UPDATE_PRICE;
	payload: { value: number };
}

export function updateProductPrice(value: number): updateProductPrice {
	return {
		type: ActionTypes.UPDATE_PRICE,
		payload: { value },
	};
}

// update price
export interface updateProductMonths {
	type: ActionTypes.UPDATE_MONTHS;
	payload: { value: number };
}

export function updateProductMonths(value: number): updateProductMonths {
	return {
		type: ActionTypes.UPDATE_MONTHS,
		payload: { value },
	};
}

// update type
export interface updateProductType {
	type: ActionTypes.UPDATE_TYPE;
	payload: { value: string };
}

export function updateProductType(value: string): updateProductType {
	return {
		type: ActionTypes.UPDATE_TYPE,
		payload: { value },
	};
}
// update store
export interface updateProductStore {
	type: ActionTypes.UPDATE_STORE;
	payload: { value: string };
}

export function updateProductStore(value: string): updateProductStore {
	return {
		type: ActionTypes.UPDATE_STORE,
		payload: { value },
	};
}

// update popular
export interface updateProductBadge {
	type: ActionTypes.UPDATE_POPULAR;
	payload: { value: boolean };
}

export function updateProductBadge(value: boolean): updateProductBadge {
	return {
		type: ActionTypes.UPDATE_POPULAR,
		payload: { value },
	};
}

// update archived
export interface updateProductArchived {
	type: ActionTypes.UPDATE_ARCHIVED;
	payload: { value: boolean };
}

export function updateProductArchived(value: boolean): updateProductArchived {
	return {
		type: ActionTypes.UPDATE_ARCHIVED,
		payload: { value },
	};
}

export type Actions =
	| updateProductName
	| updateProductPrice
	| updateProductDescription
	| updateProductMonths
	| updateProductType
	| updateProductBadge
	| updateProductArchived
	| CreateProductSuccessAction
	| CreateProductFailAction
	| CreateProductAction
	| updateProductCoins
	| updateProductImage
	| showLoading
	| FetchAllProducts
	| updateProductSaveType
	| FetchAllProductsSuccessAction
	| FetchAllProductsFailAction
	| UpdateProductAction
	| updateChecked
	| updateProductId
	| updateSearchedValue
	| UpdateProductFailAction
	| UpdateProductSuccessAction
	| updateSubscriptionId
	| updateProductStore;

import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import { ICoinsSalesResponse } from 'types/coins';

export enum ActionTypes {
	LOAD_COINS_SALES = '[coinSales] LOAD_COINS_SALES',
	LOAD_COINS_SALES_SUCCESS = '[coinSales] LOAD_COINS_SALES_SUCCESS',
	LOAD_COINS_SALES_FAIL = '[coinSales] LOAD_COINS_SALES_FAIL',
}

export class LoadCoinsSalesAction implements Action {
	readonly type = ActionTypes.LOAD_COINS_SALES;
}

export class LoadCoinsSalesSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_COINS_SALES_SUCCESS;
	constructor(public payload: ICoinsSalesResponse) {}
}

export class LoadCoinsSalesFailAction implements Action {
	readonly type = ActionTypes.LOAD_COINS_SALES_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export type Actions = LoadCoinsSalesAction | LoadCoinsSalesSuccessAction | LoadCoinsSalesFailAction;

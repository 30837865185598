import { LoadHealthStatsSuccessAction, LoadHealthStatsAction } from './../actions/stats';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import {
	ActionTypes,
	LoadUsersStatsAction,
	LoadUsersStatsSuccessAction,
	LoadUsersStatsFailAction,
	LoadSignupsStatsAction,
	LoadSignupsStatsSuccessAction,
	LoadSignupsStatsFailAction,
	LoadSalesStatsAction,
	LoadSalesStatsSuccessAction,
	LoadSalesStatsFailAction,
	LoadRevenueStatsAction,
	LoadRevenueStatsSuccessAction,
	LoadRevenueStatsFailAction,
} from 'store/actions/stats';
import StatsService from 'services/stats';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { notifyUserAboutError } from 'utils/errors';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';

export const loadUsersStatsEpic: Epic<
	Actions,
	LoadUsersStatsSuccessAction | LoadUsersStatsFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadUsersStatsAction>(ActionTypes.LOAD_USERS_STATS),
		switchMap(() =>
			from(StatsService.loadUsersStats()).pipe(
				map(response => new LoadUsersStatsSuccessAction(response)),
				catchError((error: IErrorResponse | CommonError) => {
					notifyUserAboutError(error);
					return of(new LoadUsersStatsFailAction(error));
				}),
			),
		),
	);

export const loadSignupsStatsEpic: Epic<
	Actions,
	LoadSignupsStatsSuccessAction | LoadSignupsStatsFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadSignupsStatsAction>(ActionTypes.LOAD_SIGNUPS_STATS),
		switchMap(() =>
			from(StatsService.loadSignupsStats()).pipe(
				map(response => new LoadSignupsStatsSuccessAction(response)),
				catchError((error: IErrorResponse | CommonError) => {
					notifyUserAboutError(error);
					return of(new LoadSignupsStatsFailAction(error));
				}),
			),
		),
	);

export const loadSalesStatsEpic: Epic<
	Actions,
	LoadSalesStatsSuccessAction | LoadSalesStatsFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadSalesStatsAction>(ActionTypes.LOAD_SALES_STATS),
		switchMap(() =>
			from(StatsService.loadSalesStats()).pipe(
				map(response => new LoadSalesStatsSuccessAction(response)),
				catchError((error: IErrorResponse | CommonError) => {
					notifyUserAboutError(error);
					return of(new LoadSalesStatsFailAction(error));
				}),
			),
		),
	);

export const loadRevenueStatsEpic: Epic<
	Actions,
	LoadRevenueStatsSuccessAction | LoadRevenueStatsFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadRevenueStatsAction>(ActionTypes.LOAD_REVENUE_STATS),
		switchMap(() =>
			from(StatsService.loadRevenueStats()).pipe(
				map(response => new LoadRevenueStatsSuccessAction(response)),
				catchError((error: IErrorResponse | CommonError) => {
					notifyUserAboutError(error);
					return of(new LoadRevenueStatsFailAction(error));
				}),
			),
		),
	);

export const loadHealthStatsEpic: Epic<
	Actions,
	LoadHealthStatsSuccessAction | LoadRevenueStatsFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadHealthStatsAction>(ActionTypes.LOAD_HEALTH_STATS),
		switchMap(() =>
			from(StatsService.LoadHealthStatsAction()).pipe(
				map(response => new LoadHealthStatsSuccessAction(response)),
				catchError((error: IErrorResponse | CommonError) => {
					notifyUserAboutError(error);
					return of(new LoadRevenueStatsFailAction(error));
				}),
			),
		),
	);

export default [
	loadUsersStatsEpic,
	loadSignupsStatsEpic,
	loadSalesStatsEpic,
	loadRevenueStatsEpic,
	loadHealthStatsEpic,
];

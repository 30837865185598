import authMocks from 'services/mocks/auth';
import summaryMocks from 'services/mocks/summary';
import usersMocks from 'services/mocks/users';
import statsMocks from 'services/mocks/stats';

const mocks = {
	...authMocks,
	...summaryMocks,
	...usersMocks,
	...statsMocks,
};

type Mocks = typeof mocks & {
	[key: string]: any;
};

interface IGetMocksPayload {
	url: string;
	method?: string;
	body: any;
}

const findEndpoint = (url: string) => {
	const directly = (mocks as Mocks)[url];

	if (directly) {
		return directly;
	}

	const key = Object.keys(mocks).find(k => url.includes(k));
	return key ? (mocks as Mocks)[key] : null;
};

export const getMocks = <R>({ url, method = 'get', body }: IGetMocksPayload): R => {
	const _method = method.toLowerCase();
	const endpoint = findEndpoint(url);
	const mock = endpoint[_method];
	return typeof mock === 'function' ? mock(body) : mock;
};

import styled from 'styled-components';

export const GroupContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100px;
	background-color: white;
	margin: 10px;
	box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
`;

export const Name = styled.div`
	flex: 0.4;
	display: flex;
	align-items: center;
`;

export const Description = styled.div`
	flex: 0.6;
	display: flex;
`;

export const EditButtonArea = styled.div`
	flex: 0.1;
	display: flex;
	align-items: flex-end;
	padding: 10px;
`;

export const DescriptionArea = styled.div`
	flex: 0.8;
	display: flex;
	flex-direction: column;
`;

export const ImageArea = styled.div`
	flex: 0.1;
	display: flex;
	justify-content: center;
	margin-top: 5px;
`;

import { IProductPayload } from './../../types/product';
import { Reducer } from 'redux';
import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/product';
import * as _ from 'lodash';

export interface IState {
	name: string;
	description: string;
	months: number;
	type: string;
	store: string;
	image: string;
	badge: string;
	price: number;
	coins: number;
	popular: boolean;
	archived: boolean;
	loading: boolean;
	products: IProductPayload[];
	saveType: string;
	productId: string;
	searchedValue: string;
	searchedData: IProductPayload[];
	archivedProducts: IProductPayload[];
	checked: boolean;
	subscriptionId: string;
}

export const initialState: IState = {
	name: '',
	description: '',
	months: 0,
	type: 'consumable',
	store: 'android',
	image:
		'https://images.sg.content-cdn.io/cdn//in-resources/d7048855-742a-406c-a67d-5c2962e69e5e/Images/ProductImages/Source/PNG%20Coin%20-%2010gms_1.jpg',
	badge: '',
	price: 0,
	coins: 0,
	archived: false,
	popular: false,
	loading: false,
	products: [],
	saveType: 'new',
	productId: '',
	searchedValue: '',
	searchedData: [],
	archivedProducts: [],
	checked: false,
	subscriptionId: '',
};

export const reducer: Reducer<IState, Actions> = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_NAME:
			return { ...state, name: action.payload.value };
		case ActionTypes.UPDATE_DESCRIPTION:
			return { ...state, description: action.payload.value };
		case ActionTypes.UPDATE_SEARCH_VALUE:
			return {
				...state,
				searchedValue: action.payload.value,
				searchedData:
					action.payload.value !== ''
						? state.products.filter(p =>
								p.name.toLowerCase().includes(action.payload.value.toLowerCase()),
						  )
						: state.checked
						? [...state.products, ...state.archivedProducts]
						: state.products,
			};
		case ActionTypes.UPDATE_MONTHS:
			return { ...state, months: action.payload.value };
		case ActionTypes.UPDATE_COINS:
			return { ...state, coins: action.payload.value };
		case ActionTypes.UPDATE_SUBSCRIPTION_ID:
			return { ...state, subscriptionId: action.payload.value };
		case ActionTypes.UPDATE_CHECKED:
			const checked = action.payload.value;
			return {
				...state,
				checked,
				searchedData: checked
					? [...state.products, ...state.archivedProducts]
					: _.difference(state.products, state.archivedProducts),
			};
		case ActionTypes.UPDATE_PRODUCT_ID:
			return { ...state, productId: action.payload.value };
		case ActionTypes.UPDATE_PRICE:
			return { ...state, price: action.payload.value };
		case ActionTypes.SHOW_LOADING:
			return { ...state, loading: action.payload.value };
		case ActionTypes.UPDATE_IMAGE:
			return { ...state, image: action.payload.value };
		case ActionTypes.UPDATE_PRODUCT_SAVE_TYPE:
			// if savetype is new it should reset reducer state except products
			const updatedState = initialState;
			delete updatedState.products;
			const isNew = action.payload.value === 'new' ? updatedState : {};
			return { ...state, saveType: action.payload.value, ...isNew };
		case ActionTypes.UPDATE_TYPE:
			return { ...state, type: action.payload.value };
		case ActionTypes.UPDATE_STORE:
			return { ...state, store: action.payload.value };
		case ActionTypes.CREATE_PRODUCT:
			return { ...state, loading: true };
		case ActionTypes.CREATE_PRODUCT_SUCCESS:
			return { ...state, loading: false };
		case ActionTypes.CREATE_PRODUCT_FAIL:
			return { ...state, loading: false };
		case ActionTypes.UPDATE_PRODUCT:
			return { ...state, loading: true };
		case ActionTypes.UPDATE_PRODUCT_SUCCESS:
			return { ...state, loading: false };
		case ActionTypes.UPDATE_PRODUCT_FAIL:
			return { ...state, loading: false };
		case ActionTypes.FETCH_PRODUCT:
			return { ...state, loading: true };
		case ActionTypes.FETCH_PRODUCT_SUCCESS:
			return {
				...state,
				loading: false,
				products: action.payload,
				searchedData: action.payload,
				archivedProducts: action.archivedProducts,
				checked: false,
			};
		case ActionTypes.FETCH_PRODUCT_FAIL:
			return { ...state, loading: false };
		case ActionTypes.UPDATE_ARCHIVED:
			return { ...state, archived: action.payload.value };
		case ActionTypes.UPDATE_POPULAR:
			return { ...state, popular: action.payload.value };
	}
	return state;
};

export const name = (s: IState) => s.name;
export const description = (s: IState) => s.description;
export const months = (s: IState) => s.months;
export const price = (s: IState) => s.price;
export const type = (s: IState) => s.type;
export const store = (s: IState) => s.store;
export const archived = (s: IState) => s.archived;
export const popular = (s: IState) => s.popular;
export const coins = (s: IState) => s.coins;
export const image = (s: IState) => s.image;
export const loading = (s: IState) => s.loading;
export const products = (s: IState) => s.products;
export const saveType = (s: IState) => s.saveType;
export const id = (s: IState) => s.productId;
export const searchData = (s: IState) => s.searchedData;
export const searchValue = (s: IState) => s.searchedValue;
export const checked = (s: IState) => s.checked;
export const subscriptionId = (s: IState) => s.subscriptionId;

import {
	fetchAllAdmins,
	ActionTypes,
	fetchAllAdminsSuccessAction,
	fetchAllAdminsFailAction,
	CreateAdminAction,
	CreateAdminSuccessAction,
	CreateAdminFailAction,
	UpdateAdminAction,
	UpdateAdminSuccessAction,
	UpdateAdminFailAction,
	DeleteAdminSuccessAction,
	DeleteAdminFailAction,
	DeleteAdminAction,
} from './../actions/admin';
import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import AdminService from 'services/admin';

export const GetAllWorkspaces: Epic<
	Actions,
	fetchAllAdminsSuccessAction | fetchAllAdminsFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, fetchAllAdmins>(ActionTypes.FETCH_ALL_ADMINS),
		switchMap(() =>
			from(AdminService.getAllAdmins()).pipe(
				map(response => {
					return new fetchAllAdminsSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new fetchAllAdminsFailAction(error));
				}),
			),
		),
	);

export const CreateAdminEpic: Epic<
	Actions,
	CreateAdminSuccessAction | CreateAdminFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, CreateAdminAction>(ActionTypes.CREATE_ADMIN),
		switchMap(action =>
			from(AdminService.createAdmin(action.params)).pipe(
				map(response => {
					toast.success('Admin added successfully', { position: 'top-right' });
					return new CreateAdminSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new CreateAdminFailAction(error));
				}),
			),
		),
	);

export const UpdateAdminEpic: Epic<
	Actions,
	UpdateAdminSuccessAction | UpdateAdminFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, UpdateAdminAction>(ActionTypes.UPDATE_ADMIN),
		switchMap(action =>
			from(AdminService.updateAdmin(action.params)).pipe(
				map(response => {
					toast.success('Admin updated successfully', { position: 'top-right' });
					return new UpdateAdminSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new UpdateAdminFailAction(error));
				}),
			),
		),
	);

export const DeleteAdminEpic: Epic<
	Actions,
	DeleteAdminSuccessAction | DeleteAdminFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, DeleteAdminAction>(ActionTypes.DELETE_ADMIN),
		switchMap(action =>
			from(AdminService.deleteAdmin(action.params)).pipe(
				map(response => {
					toast.success('Admin deleted successfully', { position: 'top-right' });
					return new DeleteAdminSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new DeleteAdminFailAction(error));
				}),
			),
		),
	);

export default [GetAllWorkspaces, CreateAdminEpic, UpdateAdminEpic, DeleteAdminEpic];

import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import {
	IUpdateWithdrawRequestParams,
	IWithdrawRequestResponse,
	IUpdateWithdrawRequestResponse,
} from 'types/withdrawRequest';

export enum ActionTypes {
	LOAD_WITHDRAW_REQUESTS = '[withdrawRequest] LOAD_WITHDRAW_REQUESTS',
	LOAD_WITHDRAW_REQUESTS_SUCCESS = '[withdrawRequest] LOAD_WITHDRAW_REQUESTS_SUCCESS',
	LOAD_WITHDRAW_REQUESTS_FAIL = '[withdrawRequest] LOAD_WITHDRAW_REQUESTS_FAIL',

	UPDATE_WITHDRAW_REQUEST = '[withdrawRequest] UPDATE_WITHDRAW_REQUEST',
	UPDATE_WITHDRAW_REQUEST_SUCCESS = '[withdrawRequest] UPDATE_WITHDRAW_REQUEST_SUCCESS',
	UPDATE_WITHDRAW_REQUEST_FAIL = '[withdrawRequest] UPDATE_WITHDRAW_REQUEST_FAIL',
}

export class LoadWithdrawRequestsAction implements Action {
	readonly type = ActionTypes.LOAD_WITHDRAW_REQUESTS;
}

export class LoadWithdrawRequestsSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_WITHDRAW_REQUESTS_SUCCESS;
	constructor(public payload: IWithdrawRequestResponse) {}
}

export class LoadWithdrawRequestsFailAction implements Action {
	readonly type = ActionTypes.LOAD_WITHDRAW_REQUESTS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class UpdateWithdrawRequestAction implements Action {
	readonly type = ActionTypes.UPDATE_WITHDRAW_REQUEST;
	constructor(public params: IUpdateWithdrawRequestParams) {}
}

export class UpdateWithdrawRequestActionSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_WITHDRAW_REQUEST_SUCCESS;
	constructor(public payload: IUpdateWithdrawRequestResponse) {}
}

export class UpdateWithdrawRequestActionFailAction implements Action {
	readonly type = ActionTypes.UPDATE_WITHDRAW_REQUEST_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export type Actions =
	| LoadWithdrawRequestsAction
	| LoadWithdrawRequestsSuccessAction
	| LoadWithdrawRequestsFailAction
	| UpdateWithdrawRequestAction
	| UpdateWithdrawRequestActionSuccessAction
	| UpdateWithdrawRequestActionFailAction;

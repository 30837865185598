import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { IState as IStoreState } from 'store/reducers';
import { getTheme } from 'store/selectors/settings';
import { Theme, theme as mainTheme, THEME } from 'utils/theme';

export default connect<{ theme: Theme }, {}, {}, IStoreState>(state => {
	const name = getTheme(state);
	const theme: Theme = {
		name,
		isLight: name === THEME.LIGHT,
		...mainTheme,
	};
	return { theme };
})(ThemeProvider);

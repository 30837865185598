import {
	CreateGroupSuccessAction,
	CreateGroupFailAction,
	CreateGroupAction,
	ActionTypes,
	FetchGroupAction,
	FetchGroupFailAction,
	FetchGroupSuccessAction,
	UpdateGroupFailAction,
	UpdateGroupSuccessAction,
	UpdateGroupAction,
	CreateAdministratorGroupAction,
	CreateAdministratorGroupFailAction,
	CreateAdministratorGroupSuccessAction,
	CreateGroupMembershipAction,
	CreateGroupMembershipActionFailAction,
	CreateGroupMembershipActionSuccessAction,
	UpdateGroupMemberAction,
	UpdateGroupMemberActionFailAction,
	UpdateGroupMemberActionSuccessAction,
	FetchGroupBySlugAction,
	FetchGroupBySlugSuccessAction,
	FetchGroupBySlugFailAction,
} from './../actions/group';
import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import GroupService from 'services/group';

export const CreateGroupEpic: Epic<
	Actions,
	CreateGroupSuccessAction | CreateGroupFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, CreateGroupAction>(ActionTypes.CREATE_GROUP),
		switchMap(action =>
			from(GroupService.createGroup(action.params)).pipe(
				map(response => {
					toast.success('Group added successfully', { position: 'top-right' });
					return new CreateGroupSuccessAction(response.success);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new CreateGroupFailAction(error));
				}),
			),
		),
	);

export const FetchGroupEpic: Epic<
	Actions,
	FetchGroupSuccessAction | FetchGroupFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, FetchGroupAction>(ActionTypes.FETCH_GROUPS),
		switchMap(() =>
			from(GroupService.loadGroups()).pipe(
				map(response => {
					return new FetchGroupSuccessAction(response.subscriptions, response.archived);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new FetchGroupFailAction(error));
				}),
			),
		),
	);

export const UpdateGroupEpic: Epic<
	Actions,
	UpdateGroupSuccessAction | UpdateGroupFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, UpdateGroupAction>(ActionTypes.UPDATE_GROUP),
		switchMap(action =>
			from(GroupService.updateGroups(action.params)).pipe(
				map(response => {
					toast.success('Group updated successfully', { position: 'top-right' });
					return new UpdateGroupSuccessAction(response.success);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new UpdateGroupFailAction(error));
				}),
			),
		),
	);

export const createAdministratorGroupEpic: Epic<
	Actions,
	CreateAdministratorGroupSuccessAction | CreateAdministratorGroupFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, CreateAdministratorGroupAction>(ActionTypes.CREATE_ADMINISTRATOR_GROUP),
		switchMap(action =>
			from(GroupService.createAdministratorGroup(action.params)).pipe(
				map(response => {
					// toast.success('Group updated successfully', { position: 'top-right' });
					return new CreateAdministratorGroupSuccessAction(response.success);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new CreateAdministratorGroupFailAction(error));
				}),
			),
		),
	);

export const createGroupMembershipEpic: Epic<
	Actions,
	CreateGroupMembershipActionSuccessAction | CreateGroupMembershipActionFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, CreateGroupMembershipAction>(ActionTypes.CREATE_GROUP_MEMBERSHIP),
		switchMap(action =>
			from(GroupService.groupMemberShip(action.params)).pipe(
				map(() => {
					// toast.success('Group updated successfully', { position: 'top-right' });
					return new CreateGroupMembershipActionSuccessAction();
				}),
				catchError((error: IErrorResponse | CommonError) => {
					toast.error('Un known error', {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new CreateGroupMembershipActionFailAction(error));
				}),
			),
		),
	);

export const updateGroupMemberAction: Epic<
	Actions,
	UpdateGroupMemberActionSuccessAction | UpdateGroupMemberActionFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, UpdateGroupMemberAction>(ActionTypes.UPDATE_GROUP_MEMBER_ACTION),
		switchMap(action =>
			from(GroupService.updateGroupMembers(action.params)).pipe(
				map(response => {
					const text = action.params.isMember ? 'added' : 'removed';
					toast.success(`Group Administrator ${text}`, { position: 'top-right' });
					return new UpdateGroupMemberActionSuccessAction(response.success);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new UpdateGroupMemberActionFailAction(error));
				}),
			),
		),
	);

export const getGroupBySlug: Epic<
	Actions,
	FetchGroupBySlugSuccessAction | FetchGroupBySlugFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, FetchGroupBySlugAction>(ActionTypes.FETCH_GROUP_BY_SLUG),
		switchMap(action =>
			from(GroupService.fetchGroupBySlug(action.params)).pipe(
				map(response => {
					return new FetchGroupBySlugSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new FetchGroupBySlugFailAction(error));
				}),
			),
		),
	);

export default [
	CreateGroupEpic,
	FetchGroupEpic,
	UpdateGroupEpic,
	createAdministratorGroupEpic,
	createGroupMembershipEpic,
	updateGroupMemberAction,
	getGroupBySlug,
];

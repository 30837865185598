import React, { PureComponent, createRef } from 'react';
import { Formik, FormikProps } from 'formik';
import throttle from 'lodash/throttle';
import * as Yup from 'yup';
import { IConfirmActivationPayload } from 'types/forgotPassword';
import { connect } from 'react-redux';
import { IState as IStoreState } from 'store/reducers';
import { ConfirmActivationAction } from 'store/actions/user';
import {
	getConfirmActivation,
	getConfirmActivationDone,
	getConfirmActivationError,
} from 'store/selectors/user';
import {
	StyledForm,
	FormFieldContainer,
	FormField,
	FormFieldErrorMessage,
	ActionContainer,
} from './styles';
import Button, { BUTTON_TYPE } from 'components/Button';
import { withRouter, RouteComponentProps } from 'react-router-dom';

const initialFormValues: IConfirmActivationPayload = { activationCode: '', password: '' };

const validationSchema = Yup.object().shape({
	activationCode: Yup.string().required(),
	password: Yup.string()
		.min(3)
		.required(),
});

interface IStoreProps {
	loading: ReturnType<typeof getConfirmActivation>;
	accountActivated: ReturnType<typeof getConfirmActivationDone>;
	error: ReturnType<typeof getConfirmActivationError>;
}

interface IDispatchProps {
	confirmActivation: (payload: typeof initialFormValues) => void;
}

interface IProps
	extends IStoreProps,
		IDispatchProps,
		RouteComponentProps<{ activationCode?: string }, any, any> {}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getConfirmActivation(state),
		accountActivated: getConfirmActivationDone(state),
		error: getConfirmActivationError(state),
	}),
	dispatch => ({
		confirmActivation: payload => dispatch(new ConfirmActivationAction(payload)),
	}),
)(
	withRouter(
		class ActivationForm extends PureComponent<IProps> {
			private formRef = createRef<Formik<IConfirmActivationPayload>>();

			private submitForm = throttle(this.props.confirmActivation, 1000);

			componentWillReceiveProps(nextProps: IProps) {
				const { accountActivated } = nextProps;
				if (accountActivated) {
					this.props.history.push('/');
				}

				const isRequestComplete = this.props.loading && !nextProps.loading;

				if (isRequestComplete && this.formRef.current) {
					this.formRef.current.setSubmitting(false);
				}
			}

			render() {
				if (this.props.match.params.activationCode) {
					initialFormValues.activationCode = this.props.match.params.activationCode;
				}

				return (
					<Formik
						initialValues={initialFormValues}
						validationSchema={validationSchema}
						onSubmit={this.submitForm}
						ref={this.formRef}
						render={this.renderScene}
					/>
				);
			}

			private renderScene = ({ isSubmitting }: FormikProps<IConfirmActivationPayload>) => (
				<StyledForm>
					<FormFieldContainer>
						<FormField placeholder="Activation Code" name="activationCode" />
						<FormFieldErrorMessage name="activationCode" component="p" />
					</FormFieldContainer>

					<FormFieldContainer>
						<FormField
							placeholder="Password"
							name="password"
							type="password"
							autoComplete="current-password"
						/>
						<FormFieldErrorMessage name="password" component="p" />
					</FormFieldContainer>

					<ActionContainer>
						<Button
							disabled={isSubmitting}
							loading={isSubmitting}
							kind={BUTTON_TYPE.Primary}
							type="submit"
						>
							Submit
						</Button>
					</ActionContainer>
				</StyledForm>
			);
		},
	),
);

import { Reducer } from 'redux';
import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/user';
import { IUser } from 'types/user';
import { CommonError } from 'types/common';
import { IErrorResponse } from 'types/api';

export interface IState {
	authorized: boolean;
	authorization: boolean;
	authorizationError: IErrorResponse | CommonError | null;
	info: IUser | null;
	forgotPassword: boolean;
	forgotPasswordError: IErrorResponse | CommonError | null;
	confirmForgotPassword: boolean;
	confirmForgotPasswordDone: boolean;
	confirmForgotPasswordError: IErrorResponse | CommonError | null;
	confirmActivation: boolean;
	confirmActivationDone: boolean;
	confirmActivationError: IErrorResponse | CommonError | null;
}

export const initialState: IState = {
	authorized: false,
	authorization: false,
	authorizationError: null,
	info: null,
	forgotPassword: false,
	forgotPasswordError: null,
	confirmForgotPassword: false,
	confirmForgotPasswordDone: false,
	confirmForgotPasswordError: null,
	confirmActivation: false,
	confirmActivationDone: false,
	confirmActivationError: null,
};

export const reducer: Reducer<IState, Actions> = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.AUTHORIZE: {
			return {
				...state,
				authorization: true,
				authorized: false,
				authorizationError: null,
			};
		}
		case ActionTypes.AUTHORIZE_SUCCESS: {
			return {
				...state,
				authorization: false,
				authorized: true,
				info: action.payload,
				authorizationError: null,
			};
		}
		case ActionTypes.AUTHORIZE_FAIL: {
			return {
				...state,
				authorization: false,
				authorized: false,
				authorizationError: action.payload,
			};
		}
		case ActionTypes.FORGOT_PASSWORD: {
			return {
				...state,
				forgotPassword: true,
				forgotPasswordError: null,
			};
		}
		case ActionTypes.FORGOT_PASSWORD_FAIL: {
			return {
				...state,
				forgotPassword: false,
				forgotPasswordError: action.payload,
			};
		}
		case ActionTypes.FORGOT_PASSWORD_SUCCESS: {
			return {
				...state,
				forgotPassword: false,
				confirmForgotPasswordDone: false,
				forgotPasswordError: null,
			};
		}
		case ActionTypes.CONFIRM_FORGOT_PASSWORD: {
			return {
				...state,
				confirmForgotPassword: true,
				confirmForgotPasswordDone: false,
				confirmForgotPasswordError: null,
			};
		}
		case ActionTypes.CONFIRM_FORGOT_PASSWORD_FAIL: {
			return {
				...state,
				confirmForgotPassword: false,
				confirmForgotPasswordDone: false,
				confirmForgotPasswordError: action.payload,
			};
		}
		case ActionTypes.CONFIRM_FORGOT_PASSWORD_SUCCESS: {
			return {
				...state,
				confirmForgotPassword: false,
				confirmForgotPasswordDone: true,
				confirmForgotPasswordError: null,
			};
		}
		case ActionTypes.CONFIRM_ACTIVATION: {
			return {
				...state,
				confirmActivation: true,
				confirmActivationDone: false,
				confirmActivationError: null,
			};
		}
		case ActionTypes.CONFIRM_ACTIVATION_FAIL: {
			return {
				...state,
				confirmActivation: false,
				confirmActivationDone: false,
				confirmActivationError: action.payload,
			};
		}
		case ActionTypes.CONFIRM_ACTIVATION_SUCCESS: {
			return {
				...state,
				confirmActivation: false,
				confirmActivationDone: true,
				confirmActivationError: null,
			};
		}
	}
	return state;
};

export const getAuthorized = (s: IState) => s.authorized;
export const getAuthorization = (s: IState) => s.authorization;
export const getAuthorizationError = (s: IState) => s.authorizationError;
export const getForgotPassword = (s: IState) => s.forgotPassword;
export const getForgotPasswordError = (s: IState) => s.forgotPasswordError;
export const getConfirmForgotPassword = (s: IState) => s.confirmForgotPassword;
export const getConfirmForgotPasswordDone = (s: IState) => s.confirmForgotPasswordDone;
export const getConfirmForgotPasswordError = (s: IState) => s.confirmForgotPasswordError;
export const getConfirmActivation = (s: IState) => s.confirmActivation;
export const getConfirmActivationDone = (s: IState) => s.confirmActivationDone;
export const getConfirmActivationError = (s: IState) => s.confirmActivationError;

export const getInfo = (s: IState) => s.info;

export const getUsername = (user: IUser | null) => user && user.username;
export const getName = (user: IUser | null) => user && user.username;
export const isGlobal = (user: IUser | null) => user && user.isGlobal;

import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import { IAdminsResponse, IAddAdminPayload, IAdminPayload, IDeleteAdminPayload } from 'types/admin';

export enum ActionTypes {
	FETCH_ALL_ADMINS = '[admins] FETCH_ALL_ADMINS',
	FETCH_ALL_ADMINS_SUCCESS = '[admins] FETCH_ALL_ADMINS_SUCCESS',
	FETCH_ALL_ADMINS_FAIL = '[admins] FETCH_ALL_ADMINS_FAIL',

	CREATE_ADMIN = '[admin] CREATE_ADMIN',
	CREATE_ADMIN_SUCCESS = '[admin] CREATE_ADMIN_SUCCESS',
	CREATE_ADMIN_FAIL = '[admin] CREATE_ADMIN_FAIL',

	UPDATE_ADMIN = '[admin] UPDATE_ADMIN',
	UPDATE_ADMIN_SUCCESS = '[admin] UPDATE_ADMIN_SUCCESS',
	UPDATE_ADMIN_FAIL = '[admin] UPDATE_ADMIN_FAIL',

	DELETE_ADMIN = '[admin] DELETE_ADMIN',
	DELETE_ADMIN_SUCCESS = '[admin] DELETE_ADMIN_SUCCESS',
	DELETE_ADMIN_FAIL = '[admin] DELETE_ADMIN_FAIL',
}

export class fetchAllAdmins implements Action {
	readonly type = ActionTypes.FETCH_ALL_ADMINS;
}

export class fetchAllAdminsSuccessAction implements Action {
	readonly type = ActionTypes.FETCH_ALL_ADMINS_SUCCESS;
	constructor(public payload: IAdminsResponse) {}
}

export class fetchAllAdminsFailAction implements Action {
	readonly type = ActionTypes.FETCH_ALL_ADMINS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class CreateAdminAction implements Action {
	readonly type = ActionTypes.CREATE_ADMIN;
	constructor(public params: IAddAdminPayload) {}
}

export class CreateAdminSuccessAction implements Action {
	readonly type = ActionTypes.CREATE_ADMIN_SUCCESS;
	constructor(public payload: IAdminPayload) {}
}

export class CreateAdminFailAction implements Action {
	readonly type = ActionTypes.CREATE_ADMIN_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class UpdateAdminAction implements Action {
	readonly type = ActionTypes.UPDATE_ADMIN;
	constructor(public params: IAddAdminPayload) {}
}

export class UpdateAdminSuccessAction implements Action {
	readonly type = ActionTypes.UPDATE_ADMIN_SUCCESS;
	constructor(public payload: IAdminPayload) {}
}

export class UpdateAdminFailAction implements Action {
	readonly type = ActionTypes.UPDATE_ADMIN_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class DeleteAdminAction implements Action {
	readonly type = ActionTypes.DELETE_ADMIN;
	constructor(public params: IDeleteAdminPayload) {}
}

export class DeleteAdminSuccessAction implements Action {
	readonly type = ActionTypes.DELETE_ADMIN_SUCCESS;
	constructor(public payload: IAdminPayload) {}
}

export class DeleteAdminFailAction implements Action {
	readonly type = ActionTypes.DELETE_ADMIN_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export type Actions =
	| fetchAllAdmins
	| fetchAllAdminsSuccessAction
	| fetchAllAdminsFailAction
	| CreateAdminAction
	| CreateAdminSuccessAction
	| CreateAdminFailAction
	| UpdateAdminAction
	| UpdateAdminSuccessAction
	| UpdateAdminFailAction
	| DeleteAdminAction
	| DeleteAdminSuccessAction
	| DeleteAdminFailAction;

import React, { createRef, Component } from 'react';
import { Container } from 'scenes/Users/styles';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import { Formik, FormikProps } from 'formik';
import throttle from 'lodash/throttle';
import * as Yup from 'yup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { IAuthAddProductPayload } from 'types/product';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
	// StyledForm,
	// FormFieldContainer,
	// FormFieldErrorMessage,
	ActionContainer,
} from 'modules/SignIn/SignInForm/styles';
import {
	FormField,
	PropertyName,
	FormFieldErrorMessage,
	PropertyArea,
	FormFeildArea,
	InputArea,
	FieldLabel,
	MessageArea,
	ImageArea,
} from './style';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';

import Button, { BUTTON_TYPE } from 'components/Button';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import * as upload from '../../utils/upload';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';
import {
	getProductName,
	getProductDescription,
	getProductPrice,
	getProductMonth,
	getProductType,
	getProductStore,
	getProductArchived,
	getProductBadge,
	getProductCoins,
	getProductImage,
	getLoading,
	getSaveType,
	getProductId,
	getSubscriptionId,
} from 'store/selectors/product';
import {
	updateProductName,
	updateProductDescription,
	updateProductPrice,
	updateProductMonths,
	updateProductType,
	updateProductStore,
	updateProductArchived,
	updateProductBadge,
	CreateProductAction,
	updateProductCoins,
	UpdateProductAction,
	updateProductImage,
	showLoading,
	updateProductId,
	updateSubscriptionId,
} from 'store/actions/product';
import { theme } from 'utils/theme';
import SubHeader from 'components/SubHeader';
import { FetchGroupAction } from 'store/actions/group';
import { getAllSubscriptions } from 'store/selectors/group';
import { SAVE_TYPE } from 'store/reducers/group';

const initialFormValues: IAuthAddProductPayload = {
	name: '',
	months: '',
	price: '',
	description: '',
};
const validationSchema = Yup.object().shape({
	name: Yup.string()
		.min(2)
		.required(),
	months: Yup.string().required(),
	price: Yup.string().required(),
	description: Yup.string().required(),
});

interface IProps {}

interface IStoreProps {
	name: ReturnType<typeof getProductName>;
	description: ReturnType<typeof getProductDescription>;
	month: ReturnType<typeof getProductMonth>;
	price: ReturnType<typeof getProductPrice>;
	type: ReturnType<typeof getProductType>;
	store: ReturnType<typeof getProductStore>;
	popular: ReturnType<typeof getProductBadge>;
	archived: ReturnType<typeof getProductArchived>;
	coins: ReturnType<typeof getProductCoins>;
	image: ReturnType<typeof getProductImage>;
	loading: ReturnType<typeof getLoading>;
	saveType: ReturnType<typeof getSaveType>;
	productId: ReturnType<typeof getProductId>;
	subscriptions: ReturnType<typeof getAllSubscriptions>;
	subscriptionId: ReturnType<typeof getSubscriptionId>;
}

interface IDispatchProps {
	updateProductName: (value: string) => void;
	updateProductDescription: (value: string) => void;
	updateProductPrice: (value: number) => void;
	updateProductMonths: (value: number) => void;
	updateProductType: (value: string) => void;
	updateProductStore: (value: string) => void;
	updateProductArchived: (value: boolean) => void;
	updateProductBadge: (value: boolean) => void;
	updateProductCoins: (value: number) => void;
	createProduct: (param: any) => void;
	updateProduct: (param: any) => void;
	updateProductImage: (value: string) => void;
	updateProductId: (value: string) => void;
	showLoading: (value: boolean) => void;
	updateSubscriptionId: (value: string) => void;
	fetchAllGroups: () => void;
}

interface IProps extends IStoreProps, IDispatchProps {}

class AddProduct extends Component<IProps> {
	state = {
		file: null,
	};
	private formRef = createRef<Formik<IAuthAddProductPayload>>();
	private submitForm = throttle(() => console.log('Subbmited'), 1000);

	componentDidMount() {
		const { fetchAllGroups } = this.props;
		fetchAllGroups();
	}

	onSubmitForm = async () => {
		const {
			image,
			name,
			type,
			store,
			coins,
			month,
			price,
			archived,
			popular,
			description,
			createProduct,
			saveType,
			updateProduct,
			productId,
			updateProductImage,
			showLoading,
			subscriptionId,
		} = this.props;
		const data: any = {
			name,
			type,
			store,
			image,
			coins,
			months: month,
			price,
			archived,
			popular,
			description,
			productId,
			subscriptionId,
		};
		const { file } = this.state;
		if (file) {
			showLoading(true);
			const { url } = await upload.handleUpload(file);
			if (!url) return;
			if (url) {
				updateProductImage(url);
			}
			data.image = url;
		}
		if (saveType === 'new') return createProduct(data);
		data.productId = productId;
		// while updating product check if product image is changed?
		// if yes upload new image
		if (saveType === 'update') return updateProduct(data);
	};

	renderGroupIdsDropdown = () => {
		const { subscriptions, subscriptionId, updateSubscriptionId } = this.props;
		return (
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				value={subscriptionId}
				onChange={(e: any) => updateSubscriptionId(e.target.value)}
			>
				{subscriptions &&
					subscriptions.length > 0 &&
					subscriptions.map(s => (
						<MenuItem value={s.subscriptionId}>{s.subscriptionId}</MenuItem>
					))}
			</Select>
		);
	};

	render() {
		const { saveType } = this.props;
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader
						title={''}
						shadow={false}
						style={{ flex: 1, backgroundColor: 'white' }}
					/>
				</div>
				<SubHeader
					title="Edit Product"
					buttonText={saveType == 'new' ? 'Save' : 'Update'}
					subTitle={'Enter product details and save'}
					onPressButton={() => this.onSubmitForm()}
					cancelText={'Cancel'}
				/>
				<div style={{ display: 'flex', flex: 0.8, margin: 15 }}>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
					<div style={{ display: 'flex', flex: 0.6 }}>
						<Formik
							initialValues={initialFormValues}
							validationSchema={validationSchema}
							onSubmit={this.submitForm}
							ref={this.formRef}
							render={this.renderScene}
						/>
					</div>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
				</div>
			</Container>
		);
	}

	private renderScene = ({  }: FormikProps<IAuthAddProductPayload>) => {
		const {
			updateProductName,
			name,
			description,
			price,
			month,
			updateProductDescription,
			updateProductPrice,
			updateProductMonths,
			type,
			updateProductType,
			updateProductStore,
			store,
			archived,
			popular,
			updateProductArchived,
			updateProductBadge,
			coins,
			updateProductCoins,
			image,
			loading,
			saveType,
			updateProductImage,
			updateProductId,
			productId,
		} = this.props;
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					backgroundColor: 'white',
					flexDirection: 'column',
				}}
			>
				<FormFeildArea style={{ justifyContent: 'center', alignItems: 'center' }}>
					<p>Product Details : </p>
				</FormFeildArea>
				<FormFeildArea style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}>
					<PropertyArea>
						<PropertyName>Photo/Icon:</PropertyName>
					</PropertyArea>
					<InputArea style={{ flexDirection: 'row' }}>
						<ImageArea>
							<Avatar
								alt="Remy Sharpp"
								src={image}
								style={{ width: 80, height: 80, borderRadius: 0 }}
							/>
							<input
								onChange={(e: any) => {
									const file = e.target.files[0];
									updateProductImage(URL.createObjectURL(file));
									this.setState({ file });
								}}
								type="file"
							/>
						</ImageArea>
						<InputArea style={{ flexDirection: 'row' }}>
							<PropertyArea style={{ flex: 0.4 }}>
								<PropertyName>Popular badge:</PropertyName>
							</PropertyArea>
							<PropertyArea>
								<Switch
									checked={popular}
									value="badge"
									onChange={() => updateProductBadge(!popular)}
								/>
							</PropertyArea>
						</InputArea>
					</InputArea>
				</FormFeildArea>
				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Name:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="name"
								name="name"
								value={name}
								onChange={event => updateProductName(event.target.value)}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Type:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormControlLabel
								control={
									<Checkbox
										checked={type === 'consumable'}
										value="consumable"
										onChange={e => updateProductType(e.target.value)}
									/>
								}
								label="Coins"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={type === 'subscription'}
										value="subscription"
										onChange={e => updateProductType(e.target.value)}
									/>
								}
								label="Subscription"
							/>
						</FieldLabel>
					</InputArea>
				</FormFeildArea>

				{type === 'subscription' && (
					<FormFeildArea>
						<PropertyArea>
							<PropertyName>Months:</PropertyName>
						</PropertyArea>
						<InputArea>
							<TextField
								value={month}
								onChange={e => updateProductMonths(parseInt(e.target.value))}
								type="number"
								style={styles.input}
							/>
							<MessageArea>
								<FormFieldErrorMessage name="months" component="p" />
							</MessageArea>
						</InputArea>
					</FormFeildArea>
				)}

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Price:</PropertyName>
					</PropertyArea>
					<InputArea>
						<TextField
							value={price}
							type="number"
							style={styles.input}
							onChange={e => updateProductPrice(parseFloat(e.target.value))}
						/>
						<MessageArea>
							<FormFieldErrorMessage name="price" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				{type === 'consumable' && (
					<FormFeildArea>
						<PropertyArea>
							<PropertyName>Coins:</PropertyName>
						</PropertyArea>
						<InputArea>
							<TextField
								value={coins}
								type="number"
								style={styles.input}
								onChange={e => updateProductCoins(parseFloat(e.target.value))}
							/>
							<MessageArea>
								<FormFieldErrorMessage name="coins" component="p" />
							</MessageArea>
						</InputArea>
					</FormFeildArea>
				)}

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Description:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="description"
								name="description"
								autoComplete="description"
								value={description}
								onChange={e => updateProductDescription(e.target.value)}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="description" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Group Id:</PropertyName>
					</PropertyArea>
					<InputArea>{this.renderGroupIdsDropdown()}</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Product Id:*</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="vyoo_plus_1_month"
								name="productId"
								disabled={saveType == SAVE_TYPE.UPDATE}
								autoComplete="productId"
								value={productId}
								onChange={e => updateProductId(e.target.value)}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="description" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Store:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormControlLabel
								control={
									<Checkbox
										checked={store === 'android'}
										value="android"
										onChange={e => updateProductStore(e.target.value)}
									/>
								}
								label="Android"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={store === 'ios'}
										value="ios"
										onChange={e => updateProductStore(e.target.value)}
									/>
								}
								label="iOS"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={store === 'both'}
										value="both"
										onChange={e => updateProductStore(e.target.value)}
									/>
								}
								label="both"
							/>
						</FieldLabel>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Archived:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<Switch
								checked={archived}
								value="archived"
								onChange={() => updateProductArchived(!archived)}
							/>
						</FieldLabel>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<ActionContainer
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'flex-end',
							marginRight: 10,
						}}
					>
						<Button
							disabled={false}
							loading={loading}
							kind={BUTTON_TYPE.Primary}
							type="submit"
							onClick={() => this.onSubmitForm()}
						>
							{saveType === 'new' ? 'Save' : 'Update'}
						</Button>
					</ActionContainer>
				</FormFeildArea>
			</div>
		);
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		name: getProductName(state),
		description: getProductDescription(state),
		month: getProductMonth(state),
		price: getProductPrice(state),
		type: getProductType(state),
		store: getProductStore(state),
		archived: getProductArchived(state),
		popular: getProductBadge(state),
		coins: getProductCoins(state),
		image: getProductImage(state),
		loading: getLoading(state),
		saveType: getSaveType(state),
		productId: getProductId(state),
		subscriptions: getAllSubscriptions(state),
		subscriptionId: getSubscriptionId(state),
	}),
	dispatch => ({
		updateProductName: (value: string) => dispatch(updateProductName(value)),
		showLoading: (value: boolean) => dispatch(showLoading(value)),
		updateProductImage: (value: string) => dispatch(updateProductImage(value)),
		updateProductDescription: (value: string) => dispatch(updateProductDescription(value)),
		updateProductPrice: (value: number) => dispatch(updateProductPrice(value)),
		updateProductMonths: (value: number) => dispatch(updateProductMonths(value)),
		updateProductType: (value: string) => dispatch(updateProductType(value)),
		updateProductStore: (value: string) => dispatch(updateProductStore(value)),
		updateProductArchived: (value: boolean) => dispatch(updateProductArchived(value)),
		updateProductBadge: (value: boolean) => dispatch(updateProductBadge(value)),
		updateProductCoins: (value: number) => dispatch(updateProductCoins(value)),
		updateProductId: (value: string) => dispatch(updateProductId(value)),
		updateSubscriptionId: (value: string) => dispatch(updateSubscriptionId(value)),
		createProduct: (param: any) => dispatch(new CreateProductAction(param)),
		updateProduct: (param: any) => dispatch(new UpdateProductAction(param)),
		fetchAllGroups: () => dispatch(new FetchGroupAction()),
	}),
)(AddProduct);

const styles = {
	input: {
		backgroundColor: 'rgba(255, 255, 255, 0.015)',
		color: theme.colors.grey,
		height: 35,
		width: '100%',
		border: '1px solid lightgray',
	},
};

import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	background-color: #f2f3f8;
`;

export const Header = styled.div`
	// flex: 1;
	width: (100% - 3rem);
	padding: 5px;
	height: 100px;
	background-color: white;
`;

export const PaginationArea = styled.div`
	display: flex;
	flex: 0.5;
	align-items: center;
`;

export const RowPerPageArea = styled.div`
	display: flex;
	flex: 0.5;
	align-items: center;
	justify-content: flex-end;
`;

export const ShowingStyle = styled.p`
	height: 21px;
	font-family: Poppins;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #575962;
`;

import { GroupsPayload } from './../../types/group';
import { Reducer } from 'redux';
import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/group';
import { ActionTypes as UserActionTypes } from 'store/actions/users';
import * as _ from 'lodash';
import { head, get } from 'lodash';
export const SAVE_TYPE = {
	NEW: 'new',
	UPDATE: 'update',
};
export interface IState {
	name: string;
	description: string;
	image: string;
	groupId: string;
	archived: boolean;
	loading: boolean;
	subscriptions: GroupsPayload[];
	archivedSub: GroupsPayload[];
	checked: boolean;
	data: GroupsPayload[];
	searchValue: string;
	saveType: string;
	nodeBBGroupMembers: [];
}

export const initialState: IState = {
	name: '',
	description: '',
	image:
		'https://images.sg.content-cdn.io/cdn//in-resources/d7048855-742a-406c-a67d-5c2962e69e5e/Images/ProductImages/Source/PNG%20Coin%20-%2010gms_1.jpg',
	groupId: '',
	loading: false,
	archived: false,
	subscriptions: [],
	archivedSub: [],
	checked: false,
	data: [],
	searchValue: '',
	saveType: SAVE_TYPE.NEW,
	nodeBBGroupMembers: [],
};

export const reducer: Reducer<IState, Actions> = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_GROUP_NAME:
			return { ...state, name: action.payload.value };
		case ActionTypes.UPDATE_GROUP_IMAGE:
			return { ...state, image: action.payload.value };
		case ActionTypes.UPDATE_GROUP_ID:
			return { ...state, groupId: action.payload.value };
		case ActionTypes.UPDATE_GROUP_ARCHIVE:
			return { ...state, archived: action.payload.value };
		case ActionTypes.UPDATE_GROUP_DESCRIPTION:
			return { ...state, description: action.payload.value };
		case ActionTypes.UPDATE_SEARCH_VALUE:
			const isChecked = state.checked;
			const value = action.payload.value;
			return {
				...state,
				searchValue: value,
				data:
					value !== '' && isChecked
						? state.subscriptions
								.concat(state.archivedSub)
								.filter(s => s.name.toLowerCase().includes(value))
						: value !== '' && !isChecked
						? state.subscriptions.filter(s => s.name.toLowerCase().includes(value))
						: isChecked
						? state.subscriptions.concat(state.archivedSub)
						: state.subscriptions,
			};
		case ActionTypes.CREATE_GROUP:
			return { ...state, loading: true };
		case ActionTypes.SHOW_LOADER:
			return { ...state, loading: true };

		case ActionTypes.FETCH_GROUP_BY_SLUG:
			return { ...state, loading: true };
		case ActionTypes.FETCH_GROUP_BY_SLUG_SUCCESS:
			return {
				...state,
				loading: false,
				nodeBBGroupMembers: get(head(action.payload.groups), 'members', []),
			};
		case ActionTypes.FETCH_GROUP_BY_SLUG_FAIL:
			return { ...state, loading: false };

		case UserActionTypes.ADD_EMAIL_USER:
			return { ...state, loading: true };
		case UserActionTypes.ADD_EMAIL_USER_SUCCESS:
			return { ...state, loading: false };
		case UserActionTypes.ADD_EMAIL_USER_FAIL:
			return { ...state, loading: false };

		case UserActionTypes.UPDATE_USER:
			return { ...state, loading: true };
		case UserActionTypes.UPDATE_USER_SUCCESS:
			return { ...state, loading: false };
		case UserActionTypes.UPDATE_USER_FAIL:
			return { ...state, loading: false };

		case ActionTypes.UPDATE_GROUP:
			return { ...state, loading: true };
		case ActionTypes.UPDATE_GROUP_SUCCESS:
			return { ...state, loading: false };
		case ActionTypes.UPDATE_GROUP_FAIL:
			return { ...state, loading: false };
		case ActionTypes.CREATE_GROUP_SUCCESS:
			return { ...state, loading: false };
		case ActionTypes.CREATE_GROUP_FAIL:
			return { ...state, loading: false };
		case ActionTypes.FETCH_GROUPS:
			return { ...state, loading: true };
		case ActionTypes.FETCH_GROUPS_SUCCESS:
			return {
				...state,
				loading: false,
				subscriptions: action.subscriptions,
				data: action.subscriptions,
				archivedSub: action.archived,
				checked: false,
			};
		case ActionTypes.FETCH_GROUPS_FAIL:
			return { ...state, loading: false };
		case ActionTypes.UPDATE_SAVE_TYPE:
			const updatedState = initialState;
			delete updatedState.subscriptions;
			delete updatedState.archivedSub;
			const isNew = action.payload.value === SAVE_TYPE.NEW ? updatedState : {};
			return { ...state, saveType: action.payload.value, ...isNew };
		case ActionTypes.UPDATE_CHECKED:
			const checked = action.payload.value;
			return {
				...state,
				checked,
				data: checked
					? [...state.subscriptions, ...state.archivedSub]
					: _.difference(state.subscriptions, state.archivedSub),
			};
	}
	return state;
};

export const name = (s: IState) => s.name;
export const description = (s: IState) => s.description;
export const image = (s: IState) => s.image;
export const archived = (s: IState) => s.archived;
export const groupId = (s: IState) => s.groupId;
export const loading = (s: IState) => s.loading;
export const subscriptions = (s: IState) => s.data;
export const checked = (s: IState) => s.checked;
export const searchValue = (s: IState) => s.searchValue;
export const saveType = (s: IState) => s.saveType;
export const members = (s: IState) => s.nodeBBGroupMembers;

import { createSelector } from 'reselect';
import * as product from 'store/reducers/product';
import { IState } from 'store/reducers';

const getProductState = (s: IState) => s.products;

export const getProductName = createSelector(
	getProductState,
	product.name,
);

export const getProductDescription = createSelector(
	getProductState,
	product.description,
);

export const getProductPrice = createSelector(
	getProductState,
	product.price,
);

export const getProductMonth = createSelector(
	getProductState,
	product.months,
);

export const getProductType = createSelector(
	getProductState,
	product.type,
);

export const getProductStore = createSelector(
	getProductState,
	product.store,
);

export const getProductArchived = createSelector(
	getProductState,
	product.archived,
);
export const getProductBadge = createSelector(
	getProductState,
	product.popular,
);

export const getProductCoins = createSelector(
	getProductState,
	product.coins,
);
export const getProductImage = createSelector(
	getProductState,
	product.image,
);

export const getLoading = createSelector(
	getProductState,
	product.loading,
);

export const getProducts = createSelector(
	getProductState,
	product.products,
);

export const getSaveType = createSelector(
	getProductState,
	product.saveType,
);

export const getProductId = createSelector(
	getProductState,
	product.id,
);

export const getSearchedData = createSelector(
	getProductState,
	product.searchData,
);

export const getSearchedValue = createSelector(
	getProductState,
	product.searchValue,
);

export const getCheckedValue = createSelector(
	getProductState,
	product.checked,
);

export const getSubscriptionId = createSelector(
	getProductState,
	product.subscriptionId,
);

import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const GlobalStyles = createGlobalStyle`
    ${reset};

    body {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
    }

    #root {
        width: 100vw;
        height: 100vh;
        display: flex;
    }
`;

import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import { IFundraisersResponse } from 'types/fundraisers';

export enum ActionTypes {
	LOAD_FUNDRAISERS = '[fundraisers] LOAD_FUNDRAISERS',
	LOAD_FUNDRAISERS_SUCCESS = '[fundraisers] LOAD_FUNDRAISERS_SUCCESS',
	LOAD_FUNDRAISERS_FAIL = '[fundraisers] LOAD_FUNDRAISERS_FAIL',
}

export class LoadFundraisersAction implements Action {
	readonly type = ActionTypes.LOAD_FUNDRAISERS;
}

export class LoadFundraisersSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_FUNDRAISERS_SUCCESS;
	constructor(public payload: IFundraisersResponse) {}
}

export class LoadFundraisersFailAction implements Action {
	readonly type = ActionTypes.LOAD_FUNDRAISERS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export type Actions =
	| LoadFundraisersAction
	| LoadFundraisersSuccessAction
	| LoadFundraisersFailAction;

import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/summary';
import { CommonError } from 'types/common';
import { IErrorResponse } from 'types/api';
import { ISummary, SummaryAverage } from 'types/summary';

export interface IState {
	loading: boolean;
	loadingError: IErrorResponse | CommonError | null;
	summaryToday: ISummary | null;
	summaryMonthly: SummaryAverage | null;
}

export const initialState: IState = {
	loading: false,
	loadingError: null,
	summaryToday: null,
	summaryMonthly: null,
};

export const reducer = (state: IState = initialState, action: Actions): IState => {
	switch (action.type) {
		case ActionTypes.LOAD_SUMMARY: {
			return {
				...state,
				loading: true,
				loadingError: null,
			};
		}
		case ActionTypes.LOAD_SUMMARY_SUCCESS: {
			return {
				...state,
				loading: false,
				summaryToday: action.payload.today,
				summaryMonthly: action.payload.monthlySummaryAverage,
			};
		}
		case ActionTypes.LOAD_SUMMARY_FAIL: {
			return {
				...state,
				loading: false,
				loadingError: action.payload,
			};
		}
	}
	return state;
};

export const getLoading = (s: IState) => s.loading;
export const getLoadingError = (s: IState) => s.loadingError;
export const getSummaryToday = (s: IState) => s.summaryToday;
export const getSummaryMonthly = (s: IState) => s.summaryMonthly;

import {
	IAdminsResponse,
	IAddAdminPayload,
	IAdminPayload,
	IDeleteAdminPayload,
} from './../types/admin';
import ApiService from 'services/api';

class AdminService {
	getAllAdmins(): Promise<IAdminsResponse> {
		return ApiService.get<IAdminsResponse>('admin/account', { authorized: true });
	}

	createAdmin(params: IAddAdminPayload): Promise<IAdminPayload> {
		return ApiService.post<IAdminPayload>(
			'admin/account',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}

	updateAdmin(params: IAddAdminPayload): Promise<IAdminPayload> {
		return ApiService.put<IAdminPayload>(
			'admin/account',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}

	deleteAdmin(params: IDeleteAdminPayload): Promise<IAdminPayload> {
		return ApiService.delete<IAdminPayload>(
			`admin/account/${params._id}`,
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}
}

export default new AdminService();

import React, { Component, Fragment } from 'react';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';
import { Container } from 'scenes/Users/styles';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import SubHeader from 'components/SubHeader';
import { withRouter, RouteComponentProps } from 'react-router';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, DialogActions } from '@material-ui/core';

import useredit from 'assets/useredit.svg';
import trashIcon from 'assets/trash.png';
import { UtilIcon } from 'modules/User/Tables/UserListing/styles';
import {
	LoadWorkspacesAction,
	AddFeatureAction,
	RemoveWorkspaceAction,
} from 'store/actions/workspace';
import { getAllWorkSpaces, getLoading } from 'store/selectors/workspace';
import { CircleSpinner } from 'react-spinners-kit';
import ContainerCenter from 'components/ContainerCenter';
import { theme } from 'utils/theme';
import { Notice } from 'modules/Dashboard/Widgets/NewUsersWidget/styles';

import { ALLOW_DELETE_WORKSPACE } from '../../../src/@constants';

interface IProps {}

interface IStoreProps {
	loading: ReturnType<typeof getLoading>;
	workspaces: ReturnType<typeof getAllWorkSpaces>;
}

interface IDispatchProps {
	fetchAllWorkspaces: () => void;
	addFeatureWorkspace: (name: any) => void;
	removeWorkspace: (value: any) => void;
}

interface IProps extends IStoreProps, IDispatchProps, RouteComponentProps {}

class WorkspaceScene extends Component<IProps> {
	state = {
		featureModalShown: false,
		featureName: '',
		deleteWorkspaceModalShown: false,
		removableWorkspace: {},
	};
	componentDidMount() {
		this.props.fetchAllWorkspaces();
	}

	public renderActionsButton = (item: any) => {
		return (
			<Fragment>
				<Button style={{ minWidth: 0 }}>
					<UtilIcon
						src={useredit}
						onClick={() => {
							this.props.history.push('/edit/workspace/' + item._id, item);
						}}
					/>
				</Button>
				{ALLOW_DELETE_WORKSPACE && (
					<Button style={{ minWidth: 0 }}>
						<UtilIcon
							src={trashIcon}
							onClick={() => {
								this.setState({
									deleteWorkspaceModalShown: true,
									removableWorkspace: item,
								});
							}}
						/>
					</Button>
				)}
			</Fragment>
		);
	};

	onAddNewFeature = async () => {
		const { featureName } = this.state;
		const { addFeatureWorkspace } = this.props;

		if (featureName === '') return;
		this.setState({ featureModalShown: false });

		await addFeatureWorkspace({ name: featureName });
		this.props.fetchAllWorkspaces();
	};

	onDeleteWorkspace = async () => {
		const { removableWorkspace } = this.state;
		const { removeWorkspace } = this.props;

		if (!(removableWorkspace as any)._id) return;
		this.setState({ deleteWorkspaceModalShown: false });

		await removeWorkspace({ id: (removableWorkspace as any)._id });
		this.props.fetchAllWorkspaces();
	};

	renderAddFeatureDialogBox = () => {
		const { featureModalShown, featureName } = this.state;
		return (
			<div>
				<Dialog open={featureModalShown} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">Feature</DialogTitle>
					<DialogContent>
						<DialogContentText>
							By Default feature will be added to all workspaces, you can turn off /
							on feature by editing a workspace example name : emailEnabled
						</DialogContentText>
						<TextField
							margin="dense"
							label="Name"
							fullWidth
							value={featureName}
							onChange={e => this.setState({ featureName: e.target.value })}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							onClick={() => this.setState({ featureModalShown: false })}
						>
							Cancel
						</Button>
						<Button color="primary" onClick={this.onAddNewFeature}>
							Add
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	};

	renderDeleteWorkspaceDialogBox = () => {
		const { deleteWorkspaceModalShown, removableWorkspace } = this.state;
		return (
			<div>
				<Dialog open={deleteWorkspaceModalShown} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">Delete Workspace</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to permanent delete "
							{(removableWorkspace as any).name}" community?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							onClick={() => this.setState({ deleteWorkspaceModalShown: false })}
						>
							Cancel
						</Button>
						<Button color="primary" onClick={this.onDeleteWorkspace}>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	};

	render() {
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader title={''} shadow={false} style={{ flex: 1 }} />
				</div>
				<div style={{ width: '100%' }}>
					<SubHeader
						title={'Workspaces'}
						// link={'/add/product'}
						buttonText={'Add Feature'}
						onPressButton={() => this.setState({ featureModalShown: true })}
						button2Text={'Add Workspace'}
						linkButton2={'add/workspace'}
						// onPressButton={() => updateProductSaveType('new')}
					/>
				</div>
				{this.renderContent()}
				{this.renderAddFeatureDialogBox()}
				{this.renderDeleteWorkspaceDialogBox()}
			</Container>
		);
	}

	private renderContent = () => {
		const { loading, workspaces } = this.props;
		if (loading) {
			return (
				<ContainerCenter>
					<CircleSpinner size={25} color={theme.colors.cornflowerBlue} />
				</ContainerCenter>
			);
		}
		if (!workspaces || !workspaces.length) {
			return (
				<ContainerCenter>
					<Notice>Don't have any workspaces</Notice>
				</ContainerCenter>
			);
		}
		return this.workSpacesList();
	};

	private workSpacesList = () => {
		const { workspaces } = this.props;
		return (
			<Paper style={{ width: '100%', flex: 1, display: 'flex' }}>
				<TableContainer style={{ flex: 1, display: 'flex' }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell style={{ paddingLeft: 0, minWidth: 170 }} align="left">
									Name
								</TableCell>
								<TableCell style={{ minWidth: 100 }} align="left">
									Active
								</TableCell>
								<TableCell style={{ minWidth: 150 }} align="center">
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{workspaces.map((item: any) => (
								<TableRow>
									<TableCell align="left">{item.name}</TableCell>
									<TableCell align="left">{item.active.toString()}</TableCell>
									<TableCell align="center">
										{this.renderActionsButton(item)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		);
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getLoading(state),
		workspaces: getAllWorkSpaces(state),
	}),
	dispatch => ({
		fetchAllWorkspaces: () => dispatch(new LoadWorkspacesAction()),
		addFeatureWorkspace: (value: any) => dispatch(new AddFeatureAction(value)),
		removeWorkspace: (value: any) => dispatch(new RemoveWorkspaceAction(value)),
	}),
)(withRouter(WorkspaceScene));

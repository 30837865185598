import styled from 'styled-components';
import { BUTTON_TYPE } from './type';

interface IProps {
	kind: BUTTON_TYPE;
	disabled?: boolean;
}

export const Container = styled.button<IProps>`
	height: 48px;
	border-radius: 24px;
	font-weight: 400;
	cursor: pointer;
	outline: none;
	text-align: center;
	user-select: none;
	font-size: 0.75rem;
	color: ${({ theme }) => theme.colors.white};
	padding: 0 1.5rem;
	border: ${({ theme }) => `1px solid ${theme.colors.blueDodgerLight}`};
	background-color: ${({ theme }) => theme.colors.blueDodgerLight};
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

	&:hover {
		background-color: ${({ theme }) => theme.colors.blueDodger};
		border-color: ${({ theme }) => theme.colors.blueRibbon};
	}

	&:active {
		opacity: 0.85;
	}
`;

import { createSelector } from 'reselect';
import * as users from 'store/reducers/users';
import { IState } from 'store/reducers';

const getUsersState = (s: IState) => s.users;

export const getUsersLoading = createSelector(
	getUsersState,
	users.getLoading,
);

export const getUsersLoadingError = createSelector(
	getUsersState,
	users.getLoadingError,
);

export const getUsersToday = createSelector(
	getUsersState,
	users.getUsersToday,
);

export const getUsersMonthly = createSelector(
	getUsersState,
	users.getUsersMonthly,
);

export const getActiveUsers = createSelector(
	getUsersState,
	users.getActiveUsers,
);

export const getActivePageNumber = createSelector(
	getUsersState,
	users.activePageNumber,
);

export const getPageLimit = createSelector(
	getUsersState,
	users.pageLimit,
);

export const getModalState = createSelector(
	getUsersState,
	users.modal,
);

export const getArchiveUsers = createSelector(
	getUsersState,
	users.archivedUsers,
);

export const getSelectedItemsArchiveValue = createSelector(
	getUsersState,
	users.archivedValue,
);

export const getSelectedItems = createSelector(
	getUsersState,
	users.selectedItems,
);

export const getSearchedData = createSelector(
	getUsersState,
	users.searchedData,
);

export const getSearchedValue = createSelector(
	getUsersState,
	users.searchedString,
);

export const getCheckedValue = createSelector(
	getUsersState,
	users.checked,
);

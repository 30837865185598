import styled from 'styled-components';

export const LeftContainer = styled.div`
    flex: 0.5
    align-items:center 
    display:flex
`;
export const CheckBoxText = styled.p`
	width: 147px;
	align-self: center;
	font-family: Poppins;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #434349;
`;

import {
	CreateProductAction,
	ActionTypes,
	CreateProductSuccessAction,
	CreateProductFailAction,
	FetchAllProductsFailAction,
	FetchAllProductsSuccessAction,
	FetchAllProducts,
	UpdateProductSuccessAction,
	UpdateProductFailAction,
	UpdateProductAction,
} from './../actions/product';
import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import ProductService from 'services/product';

export const CreateProductEpic: Epic<
	Actions,
	CreateProductSuccessAction | CreateProductFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, CreateProductAction>(ActionTypes.CREATE_PRODUCT),
		switchMap(action =>
			from(ProductService.createProduct(action.params)).pipe(
				map(response => {
					toast.success('Product added successfully', { position: 'top-right' });
					return new CreateProductSuccessAction(response.success);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new CreateProductFailAction(error));
				}),
			),
		),
	);

export const fetchAllProductsEpic: Epic<
	Actions,
	FetchAllProductsSuccessAction | FetchAllProductsFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, FetchAllProducts>(ActionTypes.FETCH_PRODUCT),
		switchMap(() =>
			from(ProductService.loadProducts()).pipe(
				map(
					response =>
						new FetchAllProductsSuccessAction(response.products, response.archived),
				),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new FetchAllProductsFailAction(error));
				}),
			),
		),
	);

export const UpdateProductsEpic: Epic<
	Actions,
	UpdateProductSuccessAction | UpdateProductFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, UpdateProductAction>(ActionTypes.UPDATE_PRODUCT),
		switchMap(action =>
			from(ProductService.updateProducts(action.params)).pipe(
				map(response => {
					toast.success('Product updated successfully', { position: 'top-right' });
					return new UpdateProductSuccessAction(response.success);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new UpdateProductFailAction(error));
				}),
			),
		),
	);

export default [CreateProductEpic, fetchAllProductsEpic, UpdateProductsEpic];

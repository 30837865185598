export const usersSuccessResponse = {
	total: 3,
	byDay: [
		{
			_id: '5d31af53f3988e0020a17d68',
			date: '2019-07-19T11:53:55.856Z',
			users: 0,
		},
		{
			_id: '5d3318444fa8b40021061587',
			date: '2019-07-20T13:33:56.758Z',
			users: 0,
		},
		{
			_id: '5d35232e19dafb0020cca14d',
			date: '2019-07-22T02:45:02.956Z',
			users: 1,
		},
		{
			_id: '5d3695aaab347200202d07d4',
			date: '2019-07-23T05:05:46.727Z',
			users: 2,
		},
	],
};

export const signupsSuccessResponse = {
	total: 3,
	byDay: [
		{
			_id: '5d31af53f3988e0020a17d68',
			date: '2019-07-19T11:53:55.856Z',
			signups: 0,
		},
		{
			_id: '5d3318444fa8b40021061587',
			date: '2019-07-20T13:33:56.758Z',
			signups: 0,
		},
		{
			_id: '5d35232e19dafb0020cca14d',
			date: '2019-07-22T02:45:02.956Z',
			signups: 1,
		},
		{
			_id: '5d3695aaab347200202d07d4',
			date: '2019-07-23T05:05:46.727Z',
			signups: 2,
		},
	],
};

export const salesSuccessResponse = {
	total: 0,
	byDay: [
		{
			_id: '5d31af53f3988e0020a17d68',
			date: '2019-07-19T11:53:55.856Z',
		},
		{
			_id: '5d3318444fa8b40021061587',
			date: '2019-07-20T13:33:56.758Z',
		},
		{
			_id: '5d35232e19dafb0020cca14d',
			date: '2019-07-22T02:45:02.956Z',
		},
		{
			_id: '5d3695aaab347200202d07d4',
			date: '2019-07-23T05:05:46.727Z',
			sales: 0,
		},
	],
};

export const revenueSuccessResponse = {
	total: 602,
	byDay: [
		{
			_id: '5d31af53f3988e0020a17d68',
			date: '2019-07-19T11:53:55.856Z',
		},
		{
			_id: '5d3318444fa8b40021061587',
			date: '2019-07-20T13:33:56.758Z',
		},
		{
			_id: '5d35232e19dafb0020cca14d',
			date: '2019-07-22T02:45:02.956Z',
		},
		{
			_id: '5d3695aaab347200202d07d4',
			date: '2019-07-23T05:05:46.727Z',
			revenue: 602,
		},
	],
};

export default {
	totalusers: {
		get: usersSuccessResponse,
	},
	signups: {
		get: signupsSuccessResponse,
	},
	sales: {
		get: salesSuccessResponse,
	},
	revenue: {
		get: revenueSuccessResponse,
	},
};

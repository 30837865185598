import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IState as IStoreState } from 'store/reducers';
import { getSignupsLoading } from 'store/selectors/stats';
import { LoadSignupsStatsAction } from 'store/actions/stats';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/icons/Menu';
import drawerItems from './items';
import { Link } from 'react-router-dom';
import { Content } from './style';
import { colors } from '@constants';
import { getIsGlobal } from 'store/selectors/user';
import { getAllWorkSpaces } from 'store/selectors/workspace';
import { SCENE } from '@constants/scenes';

import { withRouter, RouteComponentProps } from 'react-router';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import AuthService from 'services/auth';
import { LoadWorkspacesAction } from 'store/actions/workspace';

interface IProps {}

interface IStoreProps {
	loading: ReturnType<typeof getSignupsLoading>;
	isGlobal: ReturnType<typeof getIsGlobal>;
	workspaces: ReturnType<typeof getAllWorkSpaces>;
}

interface IDispatchProps {
	load: () => void;
	fetchAllWorkspaces: () => void;
}
interface drawerItem {
	name: string;
	path: string;
	img: any;
	active: boolean;
}
interface IState {
	items: drawerItem[];
}

interface IProps extends IStoreProps, IDispatchProps, RouteComponentProps {}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getSignupsLoading(state),
		isGlobal: getIsGlobal(state),
		workspaces: getAllWorkSpaces(state),
	}),
	dispatch => ({
		load: () => dispatch(new LoadSignupsStatsAction()),
		fetchAllWorkspaces: () => dispatch(new LoadWorkspacesAction()),
	}),
)(
	withRouter(
		class SideDrawer extends Component<IProps, IState> {
			constructor(props: any) {
				super(props);
				this.state = {
					items: drawerItems,
				};
			}

			componentDidMount() {
				const { isGlobal } = this.props;
				if (isGlobal) return this.props.fetchAllWorkspaces();
			}

			renderMenuIcon(value: string) {
				if (value === 'Vyoo') {
					return (
						<ListItemIcon style={{ justifyContent: 'flex-end' }}>
							<Menu style={{ color: colors.white }} />
						</ListItemIcon>
					);
				}
			}

			renderIcon(logo: string, name: string) {
				const ShouldApplyWidthStyle = name === 'Vyoo' ? 38 : 25;
				return <img src={logo} style={{ width: ShouldApplyWidthStyle }} />;
			}

			onWorkspaceChange(value: any) {
				AuthService.saveWorkspace({ workspace: value });
				window.location.href = '/dashboard';
			}

			renderWorkspaceSelector = () => {
				if (!this.props.workspaces) {
					return;
				}

				const { isGlobal } = this.props;

				if (isGlobal) {
					return (
						<div style={{ width: '100%', textAlign: 'center', marginBottom: '1rem' }}>
							<Select
								style={{ width: '100%', color: '#FFF' }}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={AuthService.getWorkspace()}
								onChange={e => this.onWorkspaceChange(e.target.value)}
							>
								{this.props.workspaces.map((x: any) => (
									<MenuItem key={x._id} value={x.name}>
										workspace: {x.name}
									</MenuItem>
								))}
							</Select>
						</div>
					);
				}
			};

			renderMenuItem = (item: any) => {
				return (
					<ListItem button key={item.name} style={{ opacity: item.active ? 1 : 0.5 }}>
						{this.renderIcon(item.img, item.name)}
						<ListItemText style={{ marginLeft: 10 }} primary={item.name}></ListItemText>
						{this.renderMenuIcon(item.name)}
					</ListItem>
				);
			};

			renderGlobalUserItems = (item: any) => {
				const { isGlobal } = this.props;
				const { name } = item;
				if (isGlobal && (name === SCENE.Admins || name === SCENE.Workspaces))
					return this.renderMenuItem(item);
			};

			renderLocalUserItems = (item: any) => {
				const { name } = item;
				if (!(name === SCENE.Admins || name === SCENE.Workspaces))
					return this.renderMenuItem(item);
			};

			sideList = () => (
				<div
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<List>
						{this.state.items.map((item, index) => (
							<Link
								to={item.path}
								onClick={() => this.onPressMenuIcon(index)}
								style={{ color: colors.white, textDecoration: 'none' }}
							>
								{this.renderGlobalUserItems(item)}
								{this.renderLocalUserItems(item)}
							</Link>
						))}
					</List>
					{this.renderWorkspaceSelector()}
				</div>
			);

			render() {
				return <Content>{this.sideList()}</Content>;
			}

			private onPressMenuIcon(targetedIndex: number) {
				const { items } = this.state;
				const currentItem = items.filter(i => i.active && i.name !== 'Holliblu');
				items.map((i, index) => {
					if (i.name === currentItem[0].name) {
						items[index].active = false;
					}
					if (targetedIndex === index) {
						items[index].active = true;
					}
				});
				this.setState({
					items,
				});
			}
		},
	),
);

import {
	IWorkspacesResponse,
	IAddFeatureResponse,
	IAddFeatureRequestParams,
	IUpdateWorkspaceParams,
	ICreateWorkspaceParams,
	IAgendaJobResponse,
	IRemoveWorkspaceRequestParams,
	IRemoveWorkspaceResponse,
	IWorkspaceResponse,
	IWorkspaceDetailParams,
} from './../types/workspaces';
import ApiService from 'services/api';

class WorkspaceService {
	getWorkspaces(): Promise<IWorkspacesResponse> {
		return ApiService.get<IWorkspacesResponse>('admin/workspace', { authorized: true });
	}
	addFeatureWorkspace(params: IAddFeatureRequestParams): Promise<IAddFeatureResponse> {
		return ApiService.post<IAddFeatureResponse>(
			'admin/workspace/feature',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}

	createWorkspace(params: ICreateWorkspaceParams): Promise<IAddFeatureResponse> {
		return ApiService.post<IAddFeatureResponse>(
			'admin/workspace',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}

	getAgendaJobNames(): Promise<IAgendaJobResponse> {
		return ApiService.get<IAgendaJobResponse>('admin/workspace/agenda/jobs', {
			authorized: true,
		});
	}

	updateWorkspace(params: IUpdateWorkspaceParams): Promise<IAddFeatureResponse> {
		return ApiService.put<IAddFeatureResponse>(
			'admin/workspace',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true, workspaceName: params.name },
		);
	}

	getWorkspaceDetail(params: IWorkspaceDetailParams): Promise<IWorkspaceResponse> {
		console.log('Here??');
		return ApiService.get<IWorkspaceResponse>('admin/workspace/detail', {
			authorized: true,
			workspaceName: params.name,
		});
	}

	removeWorkspace(params: IRemoveWorkspaceRequestParams): Promise<IRemoveWorkspaceResponse> {
		return ApiService.delete<IRemoveWorkspaceResponse>(
			`admin/workspace/${params.id}`,
			{},
			{
				authorized: true,
			},
		);
	}
}

export default new WorkspaceService();

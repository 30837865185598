import {
	LoadWorkspacesAction,
	ActionTypes,
	LoadWorkspacesSuccessAction,
	LoadWorkspacesFailAction,
	AddFeatureAction,
	AddFeatureSuccessAction,
	AddFeatureFailAction,
	RemoveWorkspaceAction,
	RemoveWorkspaceSuccessAction,
	RemoveWorkspaceFailAction,
	CreateWorkspaceAction,
	CreateWorkspaceActionSuccessAction,
	CreateWorkspaceActionFailAction,
	UpdateWorkspaceAction,
	UpdateWorkspaceActionSuccessAction,
	UpdateWorkspaceActionFailAction,
	LoadAgendaJobNames,
	LoadAgendaJobNamesSuccessAction,
	LoadAgendaJobNamesFailAction,
	LoadWorkspaceDetailSuccessAction,
	LoadWorkspaceDetailFailAction,
	LoadWorkspaceDetail,
} from './../actions/workspace';
import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import WorkspaceService from 'services/workspace';

export const GetAllWorkspaces: Epic<
	Actions,
	LoadWorkspacesSuccessAction | LoadWorkspacesFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadWorkspacesAction>(ActionTypes.LOAD_WORKSPACES),
		switchMap(() =>
			from(WorkspaceService.getWorkspaces()).pipe(
				map(response => {
					return new LoadWorkspacesSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadWorkspacesFailAction(error));
				}),
			),
		),
	);

export const AddFeatureWorkspace: Epic<
	Actions,
	AddFeatureSuccessAction | AddFeatureFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, AddFeatureAction>(ActionTypes.ADD_WORKSPACE_FEATURE),
		switchMap(action =>
			from(WorkspaceService.addFeatureWorkspace(action.params)).pipe(
				map(response => {
					toast.success('Feature added successfully', { position: 'top-right' });
					return new AddFeatureSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new AddFeatureFailAction(error));
				}),
			),
		),
	);

export const RemoveWorkspace: Epic<
	Actions,
	RemoveWorkspaceSuccessAction | RemoveWorkspaceFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, RemoveWorkspaceAction>(ActionTypes.REMOVE_WORKSPACE),
		switchMap(action =>
			from(WorkspaceService.removeWorkspace(action.params)).pipe(
				map(response => {
					toast.success('Workspace deleted successfully', { position: 'top-right' });
					return new RemoveWorkspaceSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new RemoveWorkspaceFailAction(error));
				}),
			),
		),
	);

export const updateWorkspace: Epic<
	Actions,
	UpdateWorkspaceActionSuccessAction | UpdateWorkspaceActionFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, UpdateWorkspaceAction>(ActionTypes.UPDATE_WORKSPACE),
		switchMap(action =>
			from(WorkspaceService.updateWorkspace(action.params)).pipe(
				map(response => {
					toast.success('Workspace updated successfully', { position: 'top-right' });
					return new UpdateWorkspaceActionSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new UpdateWorkspaceActionFailAction(error));
				}),
			),
		),
	);

export const createWorkspace: Epic<
	Actions,
	CreateWorkspaceActionSuccessAction | CreateWorkspaceActionFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, CreateWorkspaceAction>(ActionTypes.CREATE_WORKSPACE),
		switchMap(action =>
			from(WorkspaceService.createWorkspace(action.params)).pipe(
				map(response => {
					toast.success('Workspace updated successfully', { position: 'top-right' });
					return new CreateWorkspaceActionSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new CreateWorkspaceActionFailAction(error));
				}),
			),
		),
	);

export const getAgendaJobNames: Epic<
	Actions,
	LoadAgendaJobNamesSuccessAction | LoadAgendaJobNamesFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadAgendaJobNames>(ActionTypes.GET_AGENDA_JOB_NAMES),
		switchMap(() =>
			from(WorkspaceService.getAgendaJobNames()).pipe(
				map(response => {
					return new LoadAgendaJobNamesSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadAgendaJobNamesFailAction(error));
				}),
			),
		),
	);

export const getWorkspaceDetail: Epic<
	Actions,
	LoadWorkspaceDetailSuccessAction | LoadWorkspaceDetailFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadWorkspaceDetail>(ActionTypes.LOAD_WORKSPACE_DETAIL),
		switchMap(action =>
			from(WorkspaceService.getWorkspaceDetail(action.params)).pipe(
				map(response => {
					return new LoadWorkspaceDetailSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadWorkspaceDetailFailAction(error));
				}),
			),
		),
	);

export default [
	GetAllWorkspaces,
	AddFeatureWorkspace,
	updateWorkspace,
	createWorkspace,
	getAgendaJobNames,
	RemoveWorkspace,
	getWorkspaceDetail,
];

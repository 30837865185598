import React, { createRef, Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Container } from 'scenes/Users/styles';
import SubHeader from 'components/SubHeader';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import { Formik, FormikProps } from 'formik';
import throttle from 'lodash/throttle';
import * as Yup from 'yup';
import Button, { BUTTON_TYPE } from 'components/Button';
import Switch from '@material-ui/core/Switch';

import { ActionContainer } from 'modules/SignIn/SignInForm/styles';

import {
	FormField,
	PropertyName,
	FormFieldErrorMessage,
	PropertyArea,
	FormFeildArea,
	InputArea,
	FieldLabel,
	MessageArea,
} from './style';

import { getCreated, getAdmins } from 'store/selectors/admin';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';

import { IAddAdminPayload } from 'types/admin';
import { CreateAdminAction, UpdateAdminAction } from 'store/actions/admin';

const initialFormValues: IAddAdminPayload = {
	name: '',
	username: '',
	password: '',
	email: '',
	workspace: '',
	isGlobal: false,
};

const validationSchema = Yup.object().shape({
	name: Yup.string()
		.min(2)
		.required(),
	username: Yup.string()
		.min(2)
		.required(),
	email: Yup.string()
		.min(2)
		.required(),
	workspace: Yup.string(),
	isGlobal: Yup.boolean().required(),
});

interface IState extends IAddAdminPayload {}

interface IProps {
	saveType: string;
}

interface IStoreProps {
	created: ReturnType<typeof getCreated>;
	admins: ReturnType<typeof getAdmins>;
}

interface IDispatchProps {
	createAdmin: (param: IAddAdminPayload) => void;
	updateAdmin: (param: IAddAdminPayload) => void;
}

interface IProps
	extends IStoreProps,
		IDispatchProps,
		RouteComponentProps<{ id?: string }, any, any> {}

class AddAdmin extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			...initialFormValues,
		};

		this.isAdd = this.isAdd.bind(this);
	}

	isAdd() {
		return !this.props.match.params.id;
	}

	private formRef = createRef<Formik<IAddAdminPayload>>();
	private submitForm = throttle(() => console.log('Subbmited'), 1000);

	onSubmitForm = async () => {
		if (this.isAdd()) {
			this.props.createAdmin({ ...this.state });
		} else {
			this.props.updateAdmin({ ...this.state });
		}
	};

	updateState(name: string, value: string) {
		const newState: any = {};
		newState[name] = value;

		if (name === 'email') {
			newState.username = value;
		}

		this.setState(newState);
	}

	updateIsGlobal(value: boolean) {
		this.setState({
			isGlobal: value,
		});
	}

	componentDidMount() {
		if (!this.isAdd()) {
			const admin = this.props.admins.find(x => x._id === this.props.match.params.id);

			if (!admin) {
				return;
			}

			this.setState({
				_id: admin._id,
				name: admin.name || '',
				email: admin.email || '',
				username: admin.username || '',
				isGlobal: admin.isGlobal || false,
				workspace: admin.workspace || '',
			});
		}
	}

	componentWillReceiveProps(nextProps: IProps) {
		const { created } = nextProps;
		if (this.props.created === true && created === null) {
			this.props.history.push('/admins');
		}
	}

	render() {
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader
						title={''}
						shadow={false}
						style={{ flex: 1, backgroundColor: 'white' }}
					/>
				</div>
				<SubHeader
					title={this.isAdd() ? 'Add Admin' : 'Update Admin'}
					link={'/admins'}
					subTitle={'Enter admin details and save'}
					onPressButton={() => this.onSubmitForm()}
					cancelText={'Cancel'}
				/>
				<div style={{ display: 'flex', flex: 0.8, margin: 15 }}>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
					<div style={{ display: 'flex', flex: 0.6 }}>
						<Formik
							initialValues={initialFormValues}
							validationSchema={validationSchema}
							onSubmit={this.submitForm}
							ref={this.formRef}
							render={this.renderScene}
						/>
					</div>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
				</div>
			</Container>
		);
	}

	private renderScene = ({  }: FormikProps<IAddAdminPayload>) => {
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					backgroundColor: 'white',
					flexDirection: 'column',
				}}
			>
				<FormFeildArea style={{ justifyContent: 'center', alignItems: 'center' }}>
					<p>Admin details: </p>
				</FormFeildArea>
				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Name:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Name"
								name="name"
								value={this.state.name}
								onChange={e => this.updateState(e.target.name, e.target.value)}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Email/Username:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Email/Username"
								name="email"
								value={this.state.username}
								onChange={e => this.updateState(e.target.name, e.target.value)}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="name" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Password:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Password"
								name="password"
								value={this.state.password}
								onChange={e => this.updateState(e.target.name, e.target.value)}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="password" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Workspace:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Workspace"
								name="workspace"
								value={this.state.workspace}
								onChange={e => this.updateState(e.target.name, e.target.value)}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="workspace" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Is Global:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<Switch
								checked={this.state.isGlobal}
								value="isGlobal"
								onChange={() => this.updateIsGlobal(!this.state.isGlobal)}
							/>
						</FieldLabel>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<ActionContainer
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'flex-end',
							marginRight: 10,
						}}
					>
						<Button
							disabled={false}
							loading={this.props.created === true}
							kind={BUTTON_TYPE.Primary}
							type="submit"
							onClick={() => this.onSubmitForm()}
						>
							Save
						</Button>
					</ActionContainer>
				</FormFeildArea>
			</div>
		);
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		created: getCreated(state),
		admins: getAdmins(state),
	}),
	dispatch => ({
		createAdmin: (param: IAddAdminPayload) => dispatch(new CreateAdminAction(param)),
		updateAdmin: (param: IAddAdminPayload) => dispatch(new UpdateAdminAction(param)),
	}),
)(withRouter(AddAdmin));

import React, { PureComponent, createRef } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Formik, FormikProps } from 'formik';
import throttle from 'lodash/throttle';
import * as Yup from 'yup';
import { IConfirmForgotPasswordPayload } from 'types/forgotPassword';
import { connect } from 'react-redux';
import { IState as IStoreState } from 'store/reducers';
import { ConfirmForgotPasswordAction } from 'store/actions/user';
import {
	getConfirmForgotPassword,
	getConfirmForgotPasswordDone,
	getConfirmForgotPasswordError,
} from 'store/selectors/user';
import {
	StyledForm,
	FormFieldContainer,
	FormField,
	FormFieldErrorMessage,
	ActionContainer,
} from './styles';
import Button, { BUTTON_TYPE } from 'components/Button';

const initialFormValues: IConfirmForgotPasswordPayload = { forgotPasswordCode: '', password: '' };

const validationSchema = Yup.object().shape({
	forgotPasswordCode: Yup.string().required(),
	password: Yup.string()
		.min(3)
		.required(),
});

interface IStoreProps {
	loading: ReturnType<typeof getConfirmForgotPassword>;
	error: ReturnType<typeof getConfirmForgotPasswordError>;
	passwordUpdated: ReturnType<typeof getConfirmForgotPasswordDone>;
}

interface IDispatchProps {
	confirmForgotPassword: (payload: typeof initialFormValues) => void;
}

interface IProps
	extends IStoreProps,
		IDispatchProps,
		RouteComponentProps<{ forgotPasswordCode?: string }, any, any> {}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getConfirmForgotPassword(state),
		passwordUpdated: getConfirmForgotPasswordDone(state),
		error: getConfirmForgotPasswordError(state),
	}),
	dispatch => ({
		confirmForgotPassword: payload => dispatch(new ConfirmForgotPasswordAction(payload)),
	}),
)(
	withRouter(
		class ForgotPasswordForm extends PureComponent<IProps> {
			private formRef = createRef<Formik<IConfirmForgotPasswordPayload>>();

			private submitForm = throttle(this.props.confirmForgotPassword, 1000);

			componentWillReceiveProps(nextProps: IProps) {
				const { passwordUpdated } = nextProps;
				if (passwordUpdated) {
					this.props.history.push('/');
				}

				const isRequestComplete = this.props.loading && !nextProps.loading;

				if (isRequestComplete && this.formRef.current) {
					this.formRef.current.setSubmitting(false);
				}
			}

			render() {
				if (this.props.match.params.forgotPasswordCode) {
					initialFormValues.forgotPasswordCode = this.props.match.params.forgotPasswordCode;
				}

				return (
					<Formik
						initialValues={initialFormValues}
						validationSchema={validationSchema}
						onSubmit={this.submitForm}
						ref={this.formRef}
						render={this.renderScene}
					/>
				);
			}

			private renderScene = ({
				isSubmitting,
			}: FormikProps<IConfirmForgotPasswordPayload>) => (
				<StyledForm>
					<FormFieldContainer>
						<FormField placeholder="Forgot Password Code" name="forgotPasswordCode" />
						<FormFieldErrorMessage name="forgotPasswordCode" component="p" />
					</FormFieldContainer>

					<FormFieldContainer>
						<FormField
							placeholder="Password"
							name="password"
							type="password"
							autoComplete="current-password"
						/>
						<FormFieldErrorMessage name="password" component="p" />
					</FormFieldContainer>

					<ActionContainer>
						<Button
							disabled={isSubmitting}
							loading={isSubmitting}
							kind={BUTTON_TYPE.Primary}
							type="submit"
						>
							Submit
						</Button>
					</ActionContainer>
				</StyledForm>
			);
		},
	),
);

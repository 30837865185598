import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/coinsSales';
import { CommonError } from 'types/common';
import { IErrorResponse } from 'types/api';
import { ICoinsSales, ICoinSalesGraph } from 'types/coinsSales';

export interface IState {
	loading: boolean;
	loadingError: IErrorResponse | CommonError | null;
	coinSales: ICoinsSales[];
	byDay: ICoinSalesGraph[];
}

export const initialState: IState = {
	loading: false,
	loadingError: null,
	coinSales: [],
	byDay: [],
};

export const reducer = (state: IState = initialState, action: Actions): IState => {
	switch (action.type) {
		case ActionTypes.LOAD_COINS_SALES: {
			return {
				...state,
				loading: true,
				loadingError: null,
			};
		}
		case ActionTypes.LOAD_COINS_SALES_SUCCESS: {
			return {
				...state,
				loading: false,
				coinSales: action.payload.products,
				byDay: action.payload.byDays,
			};
		}
		case ActionTypes.LOAD_COINS_SALES_FAIL: {
			return {
				...state,
				loading: false,
				loadingError: action.payload,
			};
		}
	}
	return state;
};

export const getLoading = (s: IState) => s.loading;
export const getLoadingError = (s: IState) => s.loadingError;
export const getCoinSales = (s: IState) => s.coinSales;
export const getDataByDay = (s: IState) => s.byDay;

import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import { LoadItemsPeriod } from 'utils/dateQueries';
import { IProductsRevenueResponse } from 'types/revenue';
import { SalesStatsResponse } from 'types/stats';

export enum ActionTypes {
	LOAD_PRODUCTS_REVENUE = '[summary] LOAD_PRODUCTS_REVENUE',
	LOAD_PRODUCTS_REVENUE_SUCCESS = '[summary] LOAD_PRODUCTS_REVENUE_SUCCESS',
	LOAD_PRODUCTS_REVENUE_FAIL = '[summary] LOAD_PRODUCTS_REVENUE_FAIL',
}

export class LoadProductsRevenueAction implements Action {
	readonly type = ActionTypes.LOAD_PRODUCTS_REVENUE;
	constructor(public period: LoadItemsPeriod) {}
}

type LoadProductsRevenueActionSuccessPayload = IProductsRevenueResponse['subscriptions'];

export class LoadProductsRevenueSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_PRODUCTS_REVENUE_SUCCESS;
	constructor(
		public period: LoadItemsPeriod,
		public subscriptions: LoadProductsRevenueActionSuccessPayload,
		public byDay: SalesStatsResponse['byDay'],
	) {}
}

export class LoadProductsRevenueFailAction implements Action {
	readonly type = ActionTypes.LOAD_PRODUCTS_REVENUE_FAIL;
	constructor(public period: LoadItemsPeriod, public error: IErrorResponse | CommonError) {}
}

export type Actions =
	| LoadProductsRevenueAction
	| LoadProductsRevenueSuccessAction
	| LoadProductsRevenueFailAction;

import React from 'react';
import logo from 'assets/logo.svg';
import { Layout, Logo, Title } from './styles';
import SignInForm from 'modules/SignIn/SignInForm';

export default () => (
	<Layout>
		<Logo src={logo} />
		<Title>Sign In To Admin</Title>
		<SignInForm />
	</Layout>
);

import { Reducer } from 'redux';
import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/fundraisers';
import { IFundraiserPayload } from 'types/fundraisers';

export interface IState {
	fundraisers: IFundraiserPayload[];
	loading: boolean;
}

export const initialState: IState = {
	fundraisers: [],
	loading: false,
};

export const reducer: Reducer<IState, Actions> = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LOAD_FUNDRAISERS:
			return { ...state, loading: true };
		case ActionTypes.LOAD_FUNDRAISERS_SUCCESS:
			return {
				...state,
				loading: false,
				fundraisers: action.payload.fundraisers,
			};
		case ActionTypes.LOAD_FUNDRAISERS_FAIL:
			return { ...state, loading: false };
	}
	return state;
};

export const getFundraisers = (s: IState) => s.fundraisers;
export const getLoading = (s: IState) => s.loading;

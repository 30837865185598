import { createSelector } from 'reselect';
import * as group from 'store/reducers/group';
import { IState } from 'store/reducers';

const GroupState = (s: IState) => s.groups;

export const getGroupName = createSelector(
	GroupState,
	group.name,
);

export const getGroupDescription = createSelector(
	GroupState,
	group.description,
);

export const getGroupImage = createSelector(
	GroupState,
	group.image,
);

export const getGroupArchive = createSelector(
	GroupState,
	group.archived,
);

export const getGroupId = createSelector(
	GroupState,
	group.groupId,
);
export const getGroupLoading = createSelector(
	GroupState,
	group.loading,
);

export const getAllSubscriptions = createSelector(
	GroupState,
	group.subscriptions,
);

export const getCheckedValue = createSelector(
	GroupState,
	group.checked,
);

export const getSearchedValue = createSelector(
	GroupState,
	group.searchValue,
);

export const getSaveType = createSelector(
	GroupState,
	group.saveType,
);

export const getNodeBbGroupMembers = createSelector(
	GroupState,
	group.members,
);

import { stringify } from 'query-string';
import { LoadItemsPeriod } from 'utils/dateQueries';

export enum SCENE {
	SignIn = 'SignIn',
	ForgotPassword = 'ForgotPassowrd',
	ConfirmForgotPassword = 'ConfirmForgotPassword',
	ConfirmActivation = 'ConfirmActivation',
	Dashboard = 'Dashboard',
	Users = 'Users',
	Product = 'Product',
	AddProduct = 'AddProduct',
	Subscription = 'Subscription',
	AddSubscription = 'AddSubscription',
	Admins = 'Admins',
	AddAdmin = 'Add Admin',
	EditAdmin = 'Edit Admin',
	Workspaces = 'Workspaces',
	Fundraisers = 'Fundraisers',
	FundraiserDonations = 'Fundraiser Donations',
	AddWorkspace = 'Add Workspace',
	EditWorkspace = 'Edit Workspace',
	AddUser = 'Add User',
	EditUser = 'Edit User',
	WithdrawRequest = 'Withdraw Request',
}

export interface IDashboardQS {
	salesPeriod?: LoadItemsPeriod;
}

export const urls = {
	[SCENE.SignIn]: {
		pattern: '/signin',
		url: '/signin',
	},
	[SCENE.ForgotPassword]: {
		pattern: '/forgotpassword',
		url: '/forgotpassword',
	},
	[SCENE.AddUser]: {
		pattern: '/add/user',
		url: '/add/user',
	},
	[SCENE.EditUser]: {
		pattern: '/edit/user',
		url: '/edit/user',
	},
	[SCENE.Admins]: {
		pattern: '/admins',
		url: '/admins',
	},
	[SCENE.AddAdmin]: {
		pattern: '/add/admin',
		url: '/add/admin',
	},
	[SCENE.EditAdmin]: {
		pattern: '/edit/admin/:id',
		url: '/edit/admin',
	},
	[SCENE.Workspaces]: {
		pattern: '/workspaces',
		url: '/workspaces',
	},
	[SCENE.Fundraisers]: {
		pattern: '/fundraisers',
		url: '/fundraisers',
	},
	[SCENE.FundraiserDonations]: {
		pattern: '/fundraiserDonations/:fundraiser?',
		url: '/fundraiserDonations',
	},
	[SCENE.AddWorkspace]: {
		pattern: '/add/workspace',
		url: '/add/workspace',
	},
	[SCENE.EditWorkspace]: {
		pattern: '/edit/workspace/:id',
		url: '/edit/workspace',
	},
	[SCENE.ConfirmForgotPassword]: {
		pattern: '/confirmForgotPassword/:forgotPasswordCode',
		url: '/confirmForgotPassword',
	},
	[SCENE.ConfirmActivation]: {
		pattern: '/confirmActivation/:activationCode',
		url: '/confirmActivation',
	},
	[SCENE.Product]: {
		pattern: '/product',
		url: '/product',
	},
	[SCENE.AddProduct]: {
		pattern: '/add/product',
		url: '/add/product',
	},
	[SCENE.Subscription]: {
		pattern: '/subscription',
		url: '/subscription',
	},
	[SCENE.AddSubscription]: {
		pattern: '/add/subscription',
		url: '/add/subscription',
	},
	[SCENE.Users]: {
		pattern: '/users',
		url: '/users',
	},
	[SCENE.Dashboard]: {
		pattern: '/dashboard',
		url: (payload: IDashboardQS) => {
			const qs = stringify(payload);
			return `/dashboard?${qs}`;
		},
	},
	[SCENE.WithdrawRequest]: {
		pattern: '/withdrawRequest',
		url: '/withdrawRequest',
	},
};

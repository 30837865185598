import { createSelector } from 'reselect';
import * as withdrawRequest from 'store/reducers/withdrawRequest';
import { IState } from 'store/reducers';

const getWithdrawState = (s: IState) => s.withdrawRequest;

export const getWithdrawRequests = createSelector(
	getWithdrawState,
	withdrawRequest.getWithdrawRequests,
);

export const getLoading = createSelector(getWithdrawState, withdrawRequest.getLoading);

import React, { PureComponent, createRef } from 'react';
import { Formik, FormikProps } from 'formik';
import throttle from 'lodash/throttle';
import * as Yup from 'yup';
import { IForgotPasswordPayload } from 'types/forgotPassword';
import { connect } from 'react-redux';
import { IState as IStoreState } from 'store/reducers';
import { ForgotPasswordAction } from 'store/actions/user';
import { getForgotPassword, getForgotPasswordError } from 'store/selectors/user';
import {
	StyledForm,
	FormFieldContainer,
	FormField,
	FormFieldErrorMessage,
	ActionContainer,
} from './styles';
import Button, { BUTTON_TYPE } from 'components/Button';

const initialFormValues: IForgotPasswordPayload = { username: '' };

const validationSchema = Yup.object().shape({
	username: Yup.string()
		.min(3)
		.required()
});

interface IStoreProps {
	loading: ReturnType<typeof getForgotPassword>;
	error: ReturnType<typeof getForgotPasswordError>;
}

interface IDispatchProps {
	forgotPassword: (payload: typeof initialFormValues) => void;
}

interface IProps extends IStoreProps, IDispatchProps {}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getForgotPassword(state),
		error: getForgotPasswordError(state)
	}),
	dispatch => ({
		forgotPassword: payload => dispatch(new ForgotPasswordAction(payload)),
	}),
)(
	class SignInForm extends PureComponent<IProps> {
		private formRef = createRef<Formik<IForgotPasswordPayload>>();

		private submitForm = throttle(this.props.forgotPassword, 1000);

		componentWillReceiveProps(nextProps: IProps) {
			const isRequestComplete = this.props.loading && !nextProps.loading;

			if (isRequestComplete && this.formRef.current) {
				this.formRef.current.setSubmitting(false);
			}
		}

		render() {
			return (
				<Formik
					initialValues={initialFormValues}
					validationSchema={validationSchema}
					onSubmit={this.submitForm}
					ref={this.formRef}
					render={this.renderScene}
				/>
			);
		}

		private renderScene = ({ isSubmitting }: FormikProps<IForgotPasswordPayload>) => (
			<StyledForm>
				<FormFieldContainer>
					<FormField placeholder="Username" name="username" autoComplete="username" />
					<FormFieldErrorMessage name="username" component="p" />
				</FormFieldContainer>

				<ActionContainer>
					<Button
						disabled={isSubmitting}
						loading={isSubmitting}
						kind={BUTTON_TYPE.Primary}
						type="submit"
					>
						Submit
					</Button>
				</ActionContainer>
			</StyledForm>
		);
	},
);

import React, { HTMLAttributes } from 'react';
import { RotateSpinner } from 'react-spinners-kit';
import { Container } from './styles';
import { BUTTON_TYPE } from './type';

interface IProps extends HTMLAttributes<HTMLButtonElement> {
	kind: BUTTON_TYPE;
	className?: string;
	loading?: boolean;
	disabled?: boolean;
	type?: 'submit' | 'reset' | 'button';
}

export default ({ kind, loading, disabled, children, ...props }: IProps) => (
	<Container disabled={loading || disabled} kind={kind} {...props}>
		{loading ? <RotateSpinner size={20} /> : children}
	</Container>
);

export { BUTTON_TYPE };

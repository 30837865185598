import { createSelector } from 'reselect';
import * as settings from 'store/reducers/settings';
import { IState } from 'store/reducers';

const getSettingsState = (s: IState) => s.settings;

export const getTheme = createSelector(
	getSettingsState,
	settings.getTheme,
);

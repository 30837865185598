import { Reducer } from 'redux';
import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/settings';
import { THEME } from 'utils/theme';

export interface IState {
	theme: THEME;
}

export const initialState: IState = {
	theme: THEME.LIGHT,
};

export const reducer: Reducer<IState, Actions> = (state = initialState, action) => {
	if (action.type === ActionTypes.SET_THEME) {
		return { ...state, theme: action.payload };
	}
	return state;
};

export const getTheme = (s: IState) => s.theme;

import { format, subDays, subWeeks, subMonths } from 'date-fns';
import { IQuery } from 'types/api';

interface IRequestedDateQueriesPayload {
	days?: number;
	weeks?: number;
	months?: number;
}

interface IRequestedDateQueries extends IQuery {
	requestedFrom: string;
	requestedTo: string;
}

export const getRequestedDateQueries = ({
	days,
	weeks,
	months,
}: IRequestedDateQueriesPayload): IRequestedDateQueries => {
	const dateFormat = 'YYYY-MM-DD';
	const currentDate = new Date();
	let requestedDate = new Date();

	if (days) {
		requestedDate = subDays(requestedDate, days);
	}

	if (weeks) {
		requestedDate = subWeeks(requestedDate, weeks);
	}

	if (months) {
		requestedDate = subMonths(currentDate, months);
	}

	return {
		requestedFrom: format(requestedDate, dateFormat),
		requestedTo: format(currentDate, dateFormat),
	};
};

export const defaultStatsQuery = getRequestedDateQueries({ months: 3 });

export enum LoadItemsPeriod {
	'today' = 'today',
	'week1' = 'week1',
	'months1' = 'months1',
	'months3' = 'months3',
	'months6' = 'months6',
}

const periods: string[] = [
	LoadItemsPeriod.today,
	LoadItemsPeriod.week1,
	LoadItemsPeriod.months1,
	LoadItemsPeriod.months3,
	LoadItemsPeriod.months6,
];

export const isPeriodValid = (period: string): boolean => periods.includes(period);

export function getDateQueriesByPeriod(period: LoadItemsPeriod): IRequestedDateQueries {
	switch (period) {
		default:
		case LoadItemsPeriod.today:
			return getRequestedDateQueries({});
		case LoadItemsPeriod.week1:
			return getRequestedDateQueries({ weeks: 1 });
		case LoadItemsPeriod.months1:
			return getRequestedDateQueries({ months: 1 });
		case LoadItemsPeriod.months3:
			return getRequestedDateQueries({ months: 3 });
		case LoadItemsPeriod.months6:
			return getRequestedDateQueries({ months: 6 });
	}
}

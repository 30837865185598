import { Reducer } from 'redux';
import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/admin';
import * as _ from 'lodash';
import { IAdminPayload } from 'types/admin';

export interface IState {
	admins: IAdminPayload[];
	loading: boolean;
	createAdmin: boolean | null;
	deleteAdmin: boolean | null;
	updateAdmin: boolean | null;
}

export const initialState: IState = {
	admins: [],
	loading: false,
	createAdmin: false,
	deleteAdmin: false,
	updateAdmin: false,
};

export const reducer: Reducer<IState, Actions> = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_ALL_ADMINS:
			return { ...state, loading: true };
		case ActionTypes.FETCH_ALL_ADMINS_SUCCESS:
			return {
				...state,
				loading: false,
				admins: action.payload.users,
			};
		case ActionTypes.FETCH_ALL_ADMINS_FAIL:
			return { ...state, loading: false };
		case ActionTypes.CREATE_ADMIN:
			return { ...state, createAdmin: true };
		case ActionTypes.CREATE_ADMIN_SUCCESS:
			return {
				...state,
				admins: [...state.admins, action.payload],
				createAdmin: null,
			};
		case ActionTypes.CREATE_ADMIN_FAIL:
			return { ...state, createAdmin: false };
		case ActionTypes.UPDATE_ADMIN:
			return { ...state, updateAdmin: true };
		case ActionTypes.UPDATE_ADMIN_SUCCESS:
			const index = state.admins.findIndex(x => x._id === action.payload._id);
			return {
				...state,
				admins: [...state.admins, action.payload],
				links: [
					...state.admins.slice(0, index),
					action.payload,
					...state.admins.slice(index + 1),
				],
				updateAdmin: null,
			};
		case ActionTypes.UPDATE_ADMIN_FAIL:
			return { ...state, updateAdmin: false };
		case ActionTypes.DELETE_ADMIN:
			return { ...state, deleteAdmin: true };
		case ActionTypes.DELETE_ADMIN_SUCCESS:
			return {
				...state,
				admins: state.admins.filter(x => x._id !== action.payload._id),
				deleteAdmin: false,
			};
		case ActionTypes.DELETE_ADMIN_FAIL:
			return { ...state, deleteAdmin: false };
	}
	return state;
};

export const getAdmins = (s: IState) => s.admins;
export const getLoading = (s: IState) => s.loading;
export const getCreated = (s: IState) => s.createAdmin;
export const getUpdated = (s: IState) => s.updateAdmin;
export const getDeleted = (s: IState) => s.deleteAdmin;

import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/productsRevenue';
import { CommonError } from 'types/common';
import { IErrorResponse } from 'types/api';
import { IProductsRevenueResponse } from 'types/revenue';
import { LoadItemsPeriod } from 'utils/dateQueries';
import { combineReducers } from 'redux';
import { SalesStatsResponse } from 'types/stats';

interface IPeriodState {
	loading: boolean;
	loadingError: CommonError | IErrorResponse | null;
	data: IProductsRevenueResponse['subscriptions'];
	byDay: SalesStatsResponse['byDay'];
}

export type IState = {
	[key in LoadItemsPeriod]: IPeriodState;
};

const initialPeriodState: IPeriodState = {
	loading: false,
	loadingError: null,
	data: [],
	byDay: [],
};

const periodReducer = (period: LoadItemsPeriod) => (
	state: IPeriodState = initialPeriodState,
	action: Actions,
): IPeriodState => {
	if (!('period' in action) || action.period !== period) {
		return state;
	}

	switch (action.type) {
		case ActionTypes.LOAD_PRODUCTS_REVENUE:
			return {
				...state,
				loading: true,
				loadingError: null,
			};
		case ActionTypes.LOAD_PRODUCTS_REVENUE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.subscriptions,
				byDay: action.byDay,
			};
		case ActionTypes.LOAD_PRODUCTS_REVENUE_FAIL:
			return {
				...state,
				loading: false,
				loadingError: action.error,
			};
		default:
			return state;
	}
};

export const initialState: IState = {
	[LoadItemsPeriod.today]: initialPeriodState,
	[LoadItemsPeriod.week1]: initialPeriodState,
	[LoadItemsPeriod.months1]: initialPeriodState,
	[LoadItemsPeriod.months3]: initialPeriodState,
	[LoadItemsPeriod.months6]: initialPeriodState,
};

export const reducer = combineReducers({
	[LoadItemsPeriod.today]: periodReducer(LoadItemsPeriod.today),
	[LoadItemsPeriod.week1]: periodReducer(LoadItemsPeriod.week1),
	[LoadItemsPeriod.months1]: periodReducer(LoadItemsPeriod.months1),
	[LoadItemsPeriod.months3]: periodReducer(LoadItemsPeriod.months3),
	[LoadItemsPeriod.months6]: periodReducer(LoadItemsPeriod.months6),
});

export const getLoading = (state: IPeriodState) => state.loading;
export const getLoadingError = (state: IPeriodState) => state.loadingError;
export const getData = (state: IPeriodState) => state.data;
export const getByDayData = (state: IPeriodState) => state.byDay;

import { Action } from 'redux';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import {
	UsersStatsResponse,
	SignupsStatsResponse,
	SalesStatsResponse,
	RevenueStatsResponse,
	IHealthResponse,
} from 'types/stats';

export enum ActionTypes {
	LOAD_USERS_STATS = '[stats] LOAD_USERS_STATS',
	LOAD_USERS_STATS_SUCCESS = '[stats] LOAD_USERS_STATS_SUCCESS',
	LOAD_USERS_STATS_FAIL = '[stats] LOAD_USERS_STATS_FAIL',

	LOAD_SIGNUPS_STATS = '[stats] LOAD_SIGNUPS_STATS',
	LOAD_SIGNUPS_STATS_SUCCESS = '[stats] LOAD_SIGNUPS_STATS_SUCCESS',
	LOAD_SIGNUPS_STATS_FAIL = '[stats] LOAD_SIGNUPS_STATS_FAIL',

	LOAD_HEALTH_STATS = '[stats] LOAD_HEALTH_STATS',
	LOAD_HEALTH_STATS_SUCCESS = '[stats] LOAD_HEALTH_STATS_SUCCESS',
	LOAD_HEALTH_STATS_FAIL = '[stats] LOAD_HEALTH_STATS_FAIL',

	LOAD_SALES_STATS = '[stats] LOAD_SALES_STATS',
	LOAD_SALES_STATS_SUCCESS = '[stats] LOAD_SALES_STATS_SUCCESS',
	LOAD_SALES_STATS_FAIL = '[stats] LOAD_SALES_STATS_FAIL',

	LOAD_REVENUE_STATS = '[stats] LOAD_REVENUE_STATS',
	LOAD_REVENUE_STATS_SUCCESS = '[stats] LOAD_REVENUE_STATS_SUCCESS',
	LOAD_REVENUE_STATS_FAIL = '[stats] LOAD_REVENUE_STATS_FAIL',
}

/* Users */

export class LoadUsersStatsAction implements Action {
	readonly type = ActionTypes.LOAD_USERS_STATS;
}

export class LoadUsersStatsSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_USERS_STATS_SUCCESS;
	constructor(public payload: UsersStatsResponse) {}
}

export class LoadUsersStatsFailAction implements Action {
	readonly type = ActionTypes.LOAD_USERS_STATS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

/* Signups */

export class LoadSignupsStatsAction implements Action {
	readonly type = ActionTypes.LOAD_SIGNUPS_STATS;
}

export class LoadSignupsStatsSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_SIGNUPS_STATS_SUCCESS;
	constructor(public payload: SignupsStatsResponse) {}
}

export class LoadSignupsStatsFailAction implements Action {
	readonly type = ActionTypes.LOAD_SIGNUPS_STATS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

/* HEALTH */

export class LoadHealthStatsAction implements Action {
	readonly type = ActionTypes.LOAD_HEALTH_STATS;
}

export class LoadHealthStatsSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_HEALTH_STATS_SUCCESS;
	constructor(public payload: IHealthResponse) {}
}

export class LoadHealthStatsFailAction implements Action {
	readonly type = ActionTypes.LOAD_HEALTH_STATS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

/* Sales */

export class LoadSalesStatsAction implements Action {
	readonly type = ActionTypes.LOAD_SALES_STATS;
}

export class LoadSalesStatsSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_SALES_STATS_SUCCESS;
	constructor(public payload: SalesStatsResponse) {}
}

export class LoadSalesStatsFailAction implements Action {
	readonly type = ActionTypes.LOAD_SALES_STATS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

/* Revenue */

export class LoadRevenueStatsAction implements Action {
	readonly type = ActionTypes.LOAD_REVENUE_STATS;
}

export class LoadRevenueStatsSuccessAction implements Action {
	readonly type = ActionTypes.LOAD_REVENUE_STATS_SUCCESS;
	constructor(public payload: RevenueStatsResponse) {}
}

export class LoadRevenueStatsFailAction implements Action {
	readonly type = ActionTypes.LOAD_REVENUE_STATS_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export type Actions =
	| LoadUsersStatsAction
	| LoadUsersStatsSuccessAction
	| LoadUsersStatsFailAction
	| LoadSignupsStatsAction
	| LoadSignupsStatsSuccessAction
	| LoadSignupsStatsFailAction
	| LoadSalesStatsAction
	| LoadSalesStatsSuccessAction
	| LoadSalesStatsFailAction
	| LoadRevenueStatsAction
	| LoadRevenueStatsSuccessAction
	| LoadRevenueStatsFailAction
	| LoadHealthStatsFailAction
	| LoadHealthStatsAction
	| LoadHealthStatsSuccessAction;

export interface IPaypalPaymentDetail {
	email: string;
	confirmEmail: string;
}

export interface IStripePaymentDetail {
	firstname: string;
	lastname: string;
	address: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
	dob: string;
	ssn: string;
	bankRoutingNumber: string;
	bankAccountNumber: string;
	corporateEin?: string;
	corporationName?: string;
}

export enum WithdrawStatuses {
	pending = 'pending',
	success = 'success',
	cancelled = 'cancelled',
}

export enum PaymentProcessors {
	paypal = 'paypal',
	stripe = 'stripe',
}

export interface IPaymentDetail {
	paymentDetails: IPaypalPaymentDetail | IStripePaymentDetail;
	initiatorName: string;
	email: string;
}

export interface IWithdrawRequestPayload {
	_id: string;
	workspaceId: string;
	amount: number;
	processor: PaymentProcessors;
	status: WithdrawStatuses;
	description: string;
	updatedAt: string;
	createdAt: string;
	workspace: string;
	paymentDetails: IPaymentDetail;
}

export interface IWithdrawRequestResponse {
	funds: IWithdrawRequestPayload[];
}

export interface IUpdateWithdrawRequestResponse {
	withdrawRequest: IWithdrawRequestPayload;
}

export interface IUpdateWithdrawRequestParams {
	fundId: string;
	status: WithdrawStatuses;
	workspace: string;
	description?: string;
}

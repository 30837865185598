import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Container } from 'scenes/Users/styles';
import SubHeader from 'components/SubHeader';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button, { BUTTON_TYPE } from 'components/Button';

import { ActionContainer } from 'modules/SignIn/SignInForm/styles';
import { UpdateUserAction } from 'store/actions/users';
import {
	FormField,
	PropertyName,
	FormFieldErrorMessage,
	PropertyArea,
	FormFeildArea,
	InputArea,
	FieldLabel,
	MessageArea,
} from './style';

import { getGroupLoading } from 'store/selectors/group';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';

import { IAddUserPayload } from 'types/admin';
import { get } from 'lodash';

const initialFormValues: IAddUserPayload = {
	password: '',
	email: '',
	firstName: '',
};

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.min(2)
		.email('must be a valid email'),
	password: Yup.string().min(3),
	firstName: Yup.string(),
});

interface IState extends IAddUserPayload {}

interface IProps {
	saveType: string;
}

interface IStoreProps {
	loading: ReturnType<typeof getGroupLoading>;
}

interface IDispatchProps {
	updateUser: (param: IAddUserPayload) => void;
}

interface IProps
	extends IStoreProps,
		IDispatchProps,
		RouteComponentProps<{ id?: string }, any, any> {}

class EditUser extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			...initialFormValues,
		};
	}

	componentDidMount() {
		const {
			location: { state },
		} = this.props;
		this.setState({ email: get(state, 'email', ''), firstName: get(state, 'firstName', '') });
	}

	render() {
		const {
			updateUser,
			location: { state },
		} = this.props;
		const { email, password, firstName } = this.state;
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader
						title={''}
						shadow={false}
						style={{ flex: 1, backgroundColor: 'white' }}
					/>
				</div>
				<SubHeader
					title={'Edit User'}
					link={'/users'}
					subTitle={'Update user details and save'}
					onPressButton={() => console.log('Clicked')}
					cancelText={'Cancel'}
				/>
				<div style={{ display: 'flex', flex: 0.8, margin: 15 }}>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
					<div style={{ display: 'flex', flex: 0.6 }}>
						<Formik
							initialValues={initialFormValues}
							validationSchema={validationSchema}
							onSubmit={() =>
								updateUser({
									email: email === '' ? null : email,
									password: password === '' ? null : password,
									firstName,
									userId: get(state, '_id', ''),
								})
							}
						>
							{formikProps => this.renderScene(formikProps)}
						</Formik>
					</div>
					<div style={{ display: 'flex', flex: 0.2, backgroundColor: 'white' }}></div>
				</div>
			</Container>
		);
	}

	private renderScene = (formikProps: any) => {
		const { email, password, firstName } = this.state;
		return (
			<div
				style={{
					flex: 1,
					display: 'flex',
					backgroundColor: 'white',
					flexDirection: 'column',
				}}
			>
				<FormFeildArea style={{ justifyContent: 'center', alignItems: 'center' }}>
					<p>User details: </p>
				</FormFeildArea>
				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Email:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Email"
								name="email"
								value={email}
								onChange={e => {
									this.setState({ email: e.target.value });
									return formikProps.handleChange(e);
								}}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="email" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>firstName:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="firstName"
								name="firstName"
								value={firstName}
								type="text"
								onChange={e => {
									this.setState({ firstName: e.target.value });
									return formikProps.handleChange(e);
								}}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="firstName" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>

				<FormFeildArea>
					<PropertyArea>
						<PropertyName>Password:</PropertyName>
					</PropertyArea>
					<InputArea>
						<FieldLabel>
							<FormField
								placeholder="Password"
								name="password"
								value={password}
								type="password"
								onChange={e => {
									this.setState({ password: e.target.value });
									return formikProps.handleChange(e);
								}}
							/>
						</FieldLabel>
						<MessageArea>
							<FormFieldErrorMessage name="password" component="p" />
						</MessageArea>
					</InputArea>
				</FormFeildArea>
				<p style={{ fontSize: 10, margin: 10 }}>
					If password is updated user current session will be expire
				</p>
				<FormFeildArea>
					<ActionContainer
						style={{
							display: 'flex',
							flex: 1,
							justifyContent: 'flex-end',
							marginRight: 10,
						}}
					>
						<Button
							disabled={false}
							loading={this.props.loading}
							kind={BUTTON_TYPE.Primary}
							type="submit"
							onClick={() => formikProps.handleSubmit()}
						>
							Save
						</Button>
					</ActionContainer>
				</FormFeildArea>
			</div>
		);
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getGroupLoading(state),
	}),
	dispatch => ({
		updateUser: (param: IAddUserPayload) => dispatch(new UpdateUserAction(param)),
	}),
)(withRouter(EditUser));

import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const Layout = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: 1.5rem;
	padding-left: 1.5rem;
	background-color: ${({ theme }) => theme.colors.whisper};
	flex: 1;
`;

export const Column = styled.div`
	flex: 1;
	min-width: 320px;
`;
export const UtilIcon = styled.img`
	width: 20px
	height: 20px
`;

export const UserContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const Header = styled.div`
	display: flex;
	width: (100% - 3rem);
	margin-left: 15px;
	margin-right: 15px;
	justify-content: center;
	align-items: center;
	padding: 10px;
	background-color: red;
	height: 100px;
`;

export const TableContainer = styled.div`
	display: flex;
	padding-top: 10px;
`;

export const Badge = styled.div`
	border-radius: 50%;
	width: 8px;
	height: 8px;
	margin-top: 6px;
`;

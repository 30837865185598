export interface IStatsResponse<T> {
	total: number;
	byDay: T[];
}

interface IStatItemCommon {
	_id: string;
	date: string;
}

export type IStatItem<K extends string> = IStatItemCommon &
	Partial<
		{
			[key in K]: number;
		}
	>;

export enum StatsItemKey {
	users = 'users',
	signups = 'signups',
	abandonedSignups = 'abandonedSignups',
	sales = 'sales',
	revenue = 'revenue',
}

interface IHealthStats {
	totalRevenue: number;
	totalSubscriptions: number;
	totalUsers: number;
	totalInAppPurchases: number;
	communitiesCreated24hours: number;
	communitiesCreatedInWeek: number;
	communitiesCreatedInMonth: number;
	communitiesCreatedAllTime: number;
}

export interface IHealthResponse {
	health: IHealthStats;
}

export type UsersStatsItem = IStatItem<StatsItemKey.users>;
export type UsersStatsResponse = IStatsResponse<UsersStatsItem>;

export type SignupsStatsItem = IStatItem<StatsItemKey.signups>;
type AbandonedSignupsStatsItem = IStatItem<StatsItemKey.abandonedSignups>;
export type SignupsStatsResponse = IStatsResponse<SignupsStatsItem> & {
	abandonedSignups: number;
	abandonedSignupsByDay: AbandonedSignupsStatsItem[];
};

export type SalesStatsItem = IStatItem<StatsItemKey.sales>;
export type SalesStatsResponse = IStatsResponse<SalesStatsItem>;

export type RevenueStatsItem = IStatItem<StatsItemKey.revenue>;
export type RevenueStatsResponse = IStatsResponse<RevenueStatsItem>;

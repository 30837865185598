import React, { Component, Fragment } from 'react';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';
import { Container } from 'scenes/Users/styles';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import SubHeader from 'components/SubHeader';
import { RouteComponentProps, withRouter } from 'react-router';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button, DialogActions } from '@material-ui/core';

import {
	LoadWithdrawRequestsAction,
	UpdateWithdrawRequestAction,
} from 'store/actions/withdrawRequest';
import { getLoading, getWithdrawRequests } from 'store/selectors/withdrawRequest';
import { CircleSpinner } from 'react-spinners-kit';
import ContainerCenter from 'components/ContainerCenter';
import { theme } from 'utils/theme';
import { Notice } from 'modules/Dashboard/Widgets/NewUsersWidget/styles';
import Select from 'react-select';
import { Styles } from 'react-select/src/styles';
import {
	IPaypalPaymentDetail,
	IStripePaymentDetail,
	IWithdrawRequestPayload,
	PaymentProcessors,
	WithdrawStatuses,
} from '../../types/withdrawRequest';

interface IProps {}

interface IStatusOption {
	value: WithdrawStatuses;
	label: string;
}

interface IStoreProps {
	loading: ReturnType<typeof getLoading>;
	withdrawRequests: ReturnType<typeof getWithdrawRequests>;
}

interface IDispatchProps {
	fetchAllWithdrawRequests: () => void;
	updateWithdrawRequest: (param: any) => void;
}

interface IProps extends IStoreProps, IDispatchProps, RouteComponentProps {}

class WithdrawRequestScene extends Component<IProps> {
	state = {
		cancelReason: '',
		cancelWithdrawRequestModalShown: false,
		cancellingWithdrawRequest: {},
	};

	componentDidMount() {
		this.props.fetchAllWithdrawRequests();
	}

	public renderActionsButton = (item: IWithdrawRequestPayload) => {
		const items: IStatusOption[] = [
			{ value: WithdrawStatuses.pending, label: WithdrawStatuses.pending },
			{ value: WithdrawStatuses.success, label: WithdrawStatuses.success },
			{ value: WithdrawStatuses.cancelled, label: WithdrawStatuses.cancelled },
		];

		// @ts-ignore
		const styles: Styles = {
			container: (base: any) => ({
				...base,
				width: 120,
				height: 35,
			}),
			control: (base: any) => ({
				...base,
				minHeight: 'auto',
				border: 'none',
				backgroundColor: theme.colors.titanWhite,
			}),
			indicatorSeparator: (base: any) => ({
				...base,
				display: 'none',
			}),
			dropdownIndicator: (base: any) => ({
				...base,
				color: theme.colors.cornflowerBlue,
			}),
			singleValue: (base: any) => ({
				...base,
				color: theme.colors.cornflowerBlue,
			}),
		};

		const onChange = async (option: any) => {
			if (option.value === WithdrawStatuses.cancelled) {
				this.setState({
					cancellingWithdrawRequest: item,
					cancelWithdrawRequestModalShown: true,
				});
			} else {
				await this.props.updateWithdrawRequest({
					fundId: item._id,
					status: option.value,
					workspace: item.workspace,
				});

				this.props.fetchAllWithdrawRequests();
			}
		};

		return (
			<Fragment>
				<Select
					options={items}
					isDisabled={item.status === 'success' || item.status === 'cancelled'}
					isSearchable={false}
					value={{ value: item.status, label: item.status }}
					styles={styles}
					onChange={onChange}
				/>
			</Fragment>
		);
	};

	onCancelWithdrawRequest = async () => {
		const { cancellingWithdrawRequest, cancelReason } = this.state;

		if (!(cancellingWithdrawRequest as IWithdrawRequestPayload)._id) return;
		this.setState({ cancelWithdrawRequestModalShown: false });

		const { workspace, _id } = cancellingWithdrawRequest as IWithdrawRequestPayload;

		await this.props.updateWithdrawRequest({
			workspace,
			fundId: _id,
			status: WithdrawStatuses.cancelled,
			description: cancelReason,
		});

		this.props.fetchAllWithdrawRequests();
	};

	renderCancelWithdrawRequestDialogBox = () => {
		const { cancelReason, cancelWithdrawRequestModalShown } = this.state;
		return (
			<div>
				<Dialog open={cancelWithdrawRequestModalShown} aria-labelledby="form-dialog-title">
					<DialogTitle id="form-dialog-title">Cancel Withdraw Request</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to cancel withdraw request? Please, describe the
							reason.
						</DialogContentText>
						<TextField
							margin="dense"
							label="Reason"
							fullWidth
							value={cancelReason}
							onChange={e => this.setState({ cancelReason: e.target.value })}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							color="primary"
							onClick={() =>
								this.setState({ cancelWithdrawRequestModalShown: false })
							}
						>
							Keep
						</Button>
						<Button color="primary" onClick={this.onCancelWithdrawRequest}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	};

	render() {
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader title={''} shadow={false} style={{ flex: 1 }} />
				</div>
				<div style={{ width: '100%' }}>
					<SubHeader title={'Withdraw Request'} />
				</div>
				{this.renderContent()}
				{this.renderCancelWithdrawRequestDialogBox()}
			</Container>
		);
	}

	private renderContent = () => {
		const { loading, withdrawRequests } = this.props;
		if (loading) {
			return (
				<ContainerCenter>
					<CircleSpinner size={25} color={theme.colors.cornflowerBlue} />
				</ContainerCenter>
			);
		}
		if (!withdrawRequests || !withdrawRequests.length) {
			return (
				<ContainerCenter>
					<Notice>Don't have any withdraw requests</Notice>
				</ContainerCenter>
			);
		}
		return this.workWithdrawRequestsList();
	};

	private workWithdrawRequestsList = () => {
		const { withdrawRequests } = this.props;
		return (
			<Paper style={{ width: '100%', flex: 1, display: 'flex' }}>
				<TableContainer style={{ flex: 1, display: 'flex' }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell style={{ paddingLeft: 0, minWidth: 170 }} align="left">
									Community
								</TableCell>
								<TableCell style={{ minWidth: 100 }} align="left">
									Description
								</TableCell>
								<TableCell style={{ minWidth: 100 }} align="left">
									Amount
								</TableCell>
								<TableCell style={{ minWidth: 100 }} align="left">
									Details
								</TableCell>
								<TableCell style={{ minWidth: 100 }} align="center">
									Status
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{withdrawRequests.map((item, index) => (
								<TableRow key={index}>
									<TableCell align="left">{item.workspace}</TableCell>
									<TableCell align="left">{item.description}</TableCell>
									<TableCell align="left">${item.amount}</TableCell>
									<TableCell align="left">
										{item.processor === PaymentProcessors.paypal ? (
											<Fragment>
												{
													(item.paymentDetails
														.paymentDetails as IPaypalPaymentDetail)
														.email
												}{' '}
												(paypal)
											</Fragment>
										) : (
											<Fragment>
												{
													(item.paymentDetails
														.paymentDetails as IStripePaymentDetail)
														.bankAccountNumber
												}{' '}
												(stripe)
											</Fragment>
										)}
									</TableCell>
									<TableCell align="center">
										{this.renderActionsButton(item)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		);
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getLoading(state),
		withdrawRequests: getWithdrawRequests(state),
	}),
	dispatch => ({
		fetchAllWithdrawRequests: () => dispatch(new LoadWithdrawRequestsAction()),
		updateWithdrawRequest: (param: any) => dispatch(new UpdateWithdrawRequestAction(param)),
	}),
)(withRouter(WithdrawRequestScene));

import React, { memo } from 'react';
import { connect } from 'react-redux';
import { IState as IStoreState } from 'store/reducers';
import { getName } from 'store/selectors/user';
import { Profile, Greeting, AvatarPlaceholder, Name } from './styles';
import Header from 'components/Header';
import AuthService from '../../../services/auth';

interface IStoreProps {
	username: ReturnType<typeof getName>;
}

interface IProps extends IStoreProps {
	title: string;
	shadow: boolean;
	style?: any;
}

export default connect<IStoreProps, {}, {}, IStoreState>(state => ({
	username: getName(state),
}))(
	memo(({ username, title, shadow, style }: IProps) => (
		<Header title={title} shadow={shadow} style={style}>
			{username && (
				<Profile>
					<Greeting>Hi,</Greeting>
					<Name>{username.charAt(0).toUpperCase() + username.slice(1)}</Name>
					<AvatarPlaceholder>{username[0].toLocaleUpperCase()}</AvatarPlaceholder>
					<AvatarPlaceholder
						style={{ width: 70, marginLeft: 5 }}
						onClick={() => {
							AuthService.clearLocalStorage();
							window.location.href = '/';
						}}
					>
						Log Out
					</AvatarPlaceholder>
				</Profile>
			)}
		</Header>
	)),
);

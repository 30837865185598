import { Epic, ofType } from 'redux-observable';
import { empty } from 'rxjs';
import { REHYDRATE } from 'redux-persist/es/constants';
import { IState } from 'store/reducers';
import { Actions } from 'store/actions';
import { IRehydrateAction } from 'types/external';
import { mergeMap, filter, map } from 'rxjs/operators';

const PERSISTORS_COUNT = 1;

export const onAppReadyEpic: Epic<Actions | IRehydrateAction, never, IState> = action$ =>
	action$.pipe(
		ofType(REHYDRATE),
		map((_, index: number) => index + 1),
		filter(count => count === PERSISTORS_COUNT),
		// NOTE: make some initializer logic
		mergeMap(_ => empty()),
	);

export default [onAppReadyEpic];

import styled from 'styled-components';
import { ErrorMessage } from 'formik';

export const Logo = styled.div`
	width: 65px;
	height: 45px;
	margin-bottom: 2.5rem;
`;

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const FormField = styled.input`
	background-color: rgba(255, 255, 255, 0.015);
	color: ${({ theme }) => theme.colors.grey};
	border: 1px;
	height: 35px;
	padding: 0 1.25rem;
	margin-bottom: 5px;
	width: 100%;
	border: 1px solid lightgray;
	outline-width: 0px;
`;

export const PropertyName = styled.div`
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #575962;
	padding-top: 10px;
`;

export const FormFieldErrorMessage = styled(ErrorMessage)`
	color: ${({ theme }) => theme.colors.red};
	font-size: 0.625rem;
`;

export const PropertyArea = styled.div`
	flex: 0.2;
	display: flex;
	justify-content: center;
	align-items: baseline;
`;

export const FormFeildArea = styled.div`
	flex: 0.1;
	display: flex;
`;

export const InputArea = styled.div`
	flex: 0.8;
	display: flex;
	flex-direction: column;
	margin-right: 10px;
`;

export const FieldLabel = styled.div`
	flex: 0.7;
	display: flex;
`;

export const FieldLabel2 = styled.div`
	flex: 0.7;
	display: flex;
	padding-top: 0px;
`;

export const MessageArea = styled.div`
	flex: 0.3;
	display: flex;
`;

export const ImageArea = styled.div`
	flex: 0.3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px dotted gray;
`;

import { combineEpics } from 'redux-observable';
import rootEpics from 'store/effects/root';
import settingsEpics from 'store/effects/settings';
import userEpics from 'store/effects/user';
import usersEpics from 'store/effects/users';
import summaryEpics from 'store/effects/summary';
import statsEpics from 'store/effects/stats';
import coinsSalesEpics from 'store/effects/coinsSales';
import productsRevenueEpics from 'store/effects/productsRevenue';
import product from 'store/effects/product';
import group from 'store/effects/group';
import workspaces from 'store/effects/workspace';
import admin from 'store/effects/admin';
import withdrawRequest from 'store/effects/withdrawRequest';
import fundraisers from 'store/effects/fundraisers';
import fundraiserDonations from 'store/effects/fundraiserDonations';

const rootEffects = [
	...rootEpics,
	...settingsEpics,
	...userEpics,
	...usersEpics,
	...summaryEpics,
	...statsEpics,
	...coinsSalesEpics,
	...productsRevenueEpics,
	...product,
	...group,
	...workspaces,
	...admin,
	...withdrawRequest,
	...fundraisers,
	...fundraiserDonations,
];

export default combineEpics(...rootEffects);

import toast from 'cogo-toast';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';

export const notifyUserAboutError = (error: IErrorResponse | CommonError) => {
	const errorMessage = typeof error === 'string' ? error : error.message;
	toast.error(errorMessage, {
		heading: 'An error has occurred',
		position: 'top-right',
	});
};

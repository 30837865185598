import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/users';
import { CommonError } from 'types/common';
import { IErrorResponse } from 'types/api';
import { IAppUser, IActiveUser } from 'types/users';

export interface IState {
	loading: boolean;
	loadingError: IErrorResponse | CommonError | null;
	usersToday: IAppUser[];
	usersMonthly: IAppUser[];
	activeUsers: IActiveUser[];
	archivedUsers: IActiveUser[];
	page: number;
	limit: number;
	showModal: boolean;
	selectedItems: string[];
	selectItemsArchiveValue: boolean | null;
	searchedValue: string;
	searchedData: IActiveUser[];
	checked: boolean;
}

export const initialState: IState = {
	loading: false,
	loadingError: null,
	usersToday: [],
	usersMonthly: [],
	activeUsers: [],
	archivedUsers: [],
	page: 0,
	limit: 10,
	showModal: false,
	selectedItems: [],
	selectItemsArchiveValue: null,
	searchedValue: '',
	searchedData: [],
	checked: false,
};

export const reducer = (state: IState = initialState, action: Actions): IState => {
	switch (action.type) {
		case ActionTypes.CHANGE_ACTIVE_PAGE_NUMBER: {
			return {
				...state,
				page: action.payload.page,
				searchedValue: '',
			};
		}

		case ActionTypes.UPDATE_CHECKED_VALUE: {
			return {
				...state,
				checked: action.payload.value,
			};
		}
		case ActionTypes.UPDATE_SEARCH_VALUE: {
			return {
				...state,
				searchedValue: action.payload.value,
			};
		}
		case ActionTypes.ON_CHANGE_SELECTED_ITEMS: {
			return {
				...state,
				selectedItems: action.payload.ids,
			};
		}
		case ActionTypes.ON_CHANGE_SELECTED_ITEMS_ARCHIVE_VALUE: {
			return {
				...state,
				selectItemsArchiveValue: action.payload.archived,
			};
		}
		case ActionTypes.UPDATE_ACTIVE_USERS: {
			return {
				...state,
				activeUsers: action.payload.data,
			};
		}
		case ActionTypes.MARK_USER_ARCHIVE_SUCCESS: {
			return {
				...state,
				selectedItems: [],
				selectItemsArchiveValue: null,
				searchedValue: '',
				checked: false,
			};
		}
		case ActionTypes.CHANGE_MODAL_VISIBILITY: {
			return {
				...state,
				showModal: action.payload.visible,
			};
		}
		case ActionTypes.CHANGE_PAGE_LIMIT: {
			return {
				...state,
				limit: action.payload.limit,
			};
		}
		case ActionTypes.LOAD_ACTIVE_USERS: {
			return {
				...state,
				loading: true,
				loadingError: null,
				checked: false,
			};
		}
		case ActionTypes.LOAD_ACTIVE_USERS_SUCCESS: {
			return {
				...state,
				loading: false,
				activeUsers: action.payload.users,
				archivedUsers: action.payload.archived,
			};
		}
		case ActionTypes.LOAD_ACTIVE_USERS_FAIL: {
			return {
				...state,
				loading: false,
				loadingError: action.payload,
			};
		}
		case ActionTypes.USER_SEARCH: {
			return {
				...state,
				loading: true,
			};
		}
		case ActionTypes.USER_SEARCH_SUCCESS: {
			return {
				...state,
				loading: false,
				searchedData: action.payload.users,
			};
		}
		case ActionTypes.USER_SEARCH_FAIL: {
			return {
				...state,
				loading: false,
			};
		}
		case ActionTypes.LOAD_USERS: {
			return {
				...state,
				loading: true,
				loadingError: null,
			};
		}
		case ActionTypes.LOAD_USERS_SUCCESS: {
			return {
				...state,
				loading: false,
				usersToday: action.payload.today,
				usersMonthly: action.payload.monthly,
			};
		}
		case ActionTypes.LOAD_USERS_FAIL: {
			return {
				...state,
				loading: false,
				loadingError: action.payload,
			};
		}
	}
	return state;
};

export const getLoading = (s: IState) => s.loading;
export const getLoadingError = (s: IState) => s.loadingError;
export const getUsersToday = (s: IState) => s.usersToday;
export const getUsersMonthly = (s: IState) => s.usersMonthly;
export const getActiveUsers = (s: IState) => s.activeUsers;
export const activePageNumber = (s: IState) => s.page;
export const pageLimit = (s: IState) => s.limit;
export const modal = (s: IState) => s.showModal;
export const archivedUsers = (s: IState) => s.archivedUsers;
export const archivedValue = (s: IState) => s.selectItemsArchiveValue;
export const selectedItems = (s: IState) => s.selectedItems;
export const searchedData = (s: IState) => s.searchedData;
export const searchedString = (s: IState) => s.searchedValue;
export const checked = (s: IState) => s.checked;

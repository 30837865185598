import ApiService from 'services/api';
import { defaultStatsQuery, LoadItemsPeriod, getDateQueriesByPeriod } from 'utils/dateQueries';
import {
	UsersStatsResponse,
	SignupsStatsResponse,
	SalesStatsResponse,
	RevenueStatsResponse,
	IStatsResponse,
	IStatItem,
	StatsItemKey,
	IHealthResponse,
} from 'types/stats';

class StatsService {
	loadUsersStats(): Promise<UsersStatsResponse> {
		return ApiService.get<UsersStatsResponse>('admin/dashboard/totalusers', {
			query: defaultStatsQuery,
			authorized: true,
		}).then(this.fillNullableValuesWithZero(StatsItemKey.users));
	}

	loadSignupsStats(): Promise<SignupsStatsResponse> {
		return ApiService.get<SignupsStatsResponse>('admin/dashboard/signups', {
			query: defaultStatsQuery,
			authorized: true,
		}).then(this.fillNullableValuesWithZero(StatsItemKey.signups));
	}

	loadSalesStats(period?: LoadItemsPeriod): Promise<SalesStatsResponse> {
		return ApiService.get<SalesStatsResponse>('admin/dashboard/sales', {
			query: period ? getDateQueriesByPeriod(period) : defaultStatsQuery,
			authorized: true,
		}).then(this.fillNullableValuesWithZero(StatsItemKey.sales));
	}

	loadRevenueStats(): Promise<RevenueStatsResponse> {
		return ApiService.get<RevenueStatsResponse>('admin/dashboard/revenue', {
			query: defaultStatsQuery,
			authorized: true,
		}).then(this.fillNullableValuesWithZero(StatsItemKey.revenue));
	}

	LoadHealthStatsAction(): Promise<IHealthResponse> {
		return ApiService.get<IHealthResponse>('admin/workspace/health', { authorized: true });
	}

	private fillNullableValuesWithZero = <K extends StatsItemKey>(key: K) => <
		T extends IStatsResponse<IStatItem<K>>
	>(
		response: T,
	) => ({
		...response,
		byDay: response.byDay.map(item => ({
			...item,
			[key]: item[key] || 0,
		})),
	});
}

export default new StatsService();

import ApiService from 'services/api';
import { IAuthPayload, IAuthSuccessResponse } from 'types/auth';
import { IUser } from 'types/user';
import {
	IForgotPasswordPayload,
	IForgotPasswordSuccessPayload,
	IConfirmForgotPasswordPayload,
	IConfirmForgotPasswordSuccessPayload,
	IConfirmActivationPayload,
	IConfirmActivationSuccessPayload,
} from 'types/forgotPassword';

class AuthService {
	private tokenStorageKey = 'vyoo-token';
	private workspaceStorageKey = 'vyoo-workspace';

	authorize(payload: IAuthPayload): Promise<IUser> {
		return ApiService.post<IAuthSuccessResponse>('admin/account/auth', {
			body: JSON.stringify(payload),
		})
			.then(this.saveAuthToken)
			.then(x => {
				this.saveWorkspace({ workspace: x.workspace ? x.workspace : 'vyoo' });
				return x;
			})
			.then(this.getUserDataFromAuthResponse);
	}

	getAuthToken(): string | null {
		return localStorage.getItem(this.tokenStorageKey);
	}

	clearLocalStorage(): void {
		return localStorage.clear();
	}

	getWorkspace(): string | null {
		const result = localStorage.getItem(this.workspaceStorageKey);
		return result ? result : 'vyoo';
	}

	forgotPassword(payload: IForgotPasswordPayload): Promise<IForgotPasswordSuccessPayload> {
		return ApiService.post<any>('admin/account/forgotPassword', {
			body: JSON.stringify(payload),
		});
	}

	confirmForgotPassword(
		payload: IConfirmForgotPasswordPayload,
	): Promise<IConfirmForgotPasswordSuccessPayload> {
		return ApiService.post<any>('admin/account/confirmForgotPassword', {
			body: JSON.stringify(payload),
		});
	}

	confirmActivation(
		payload: IConfirmActivationPayload,
	): Promise<IConfirmActivationSuccessPayload> {
		return ApiService.post<any>('admin/account/confirmActivation', {
			body: JSON.stringify(payload),
		});
	}

	saveWorkspace = (data: { workspace: string }) => {
		localStorage.setItem(this.workspaceStorageKey, data.workspace);
	};

	private saveAuthToken = (data: IAuthSuccessResponse): IAuthSuccessResponse => {
		localStorage.setItem(this.tokenStorageKey, data.token);
		return data;
	};

	private getUserDataFromAuthResponse = ({
		email,
		name,
		username,
		workspace,
		isGlobal,
	}: IAuthSuccessResponse): IUser => ({
		name,
		username,
		email,
		workspace,
		isGlobal,
	});
}

export default new AuthService();

import {
	IGroupsResponse,
	GroupsPayload,
	AdministratorPayload,
	memberPayload,
} from './../types/group';
import { IAppCreateProductResponse, INodebbGroupResponse } from './../types/users';
import ApiService from 'services/api';
import { CreateGroupParams } from 'types/group';

class GroupService {
	createGroup(params: CreateGroupParams): Promise<IAppCreateProductResponse> {
		return ApiService.post<IAppCreateProductResponse>(
			'admin/subscriptions',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}
	loadGroups(): Promise<IGroupsResponse> {
		return ApiService.get<IGroupsResponse>('admin/subscriptions', {
			authorized: true,
		});
	}
	updateGroups(params: GroupsPayload): Promise<IAppCreateProductResponse> {
		const { subscriptionId } = params;
		return ApiService.put<IAppCreateProductResponse>(
			'admin/subscriptions/' + subscriptionId,
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}
	createAdministratorGroup(params: AdministratorPayload): Promise<IAppCreateProductResponse> {
		return ApiService.post<IAppCreateProductResponse>(
			'nodebb/group/administrators',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}

	groupMemberShip(params: memberPayload): Promise<INodebbGroupResponse> {
		return ApiService.post<INodebbGroupResponse>(
			'nodebb/groups',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}

	updateGroupMembers(params: any): Promise<IAppCreateProductResponse> {
		return ApiService.put<IAppCreateProductResponse>(
			'nodebb/group/members',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}

	fetchGroupBySlug(params: any): Promise<any> {
		return ApiService.get<any>(`nodebb/groups?slug=${params.slug}&lat=1&lon=2`, {
			authorized: true,
		});
	}
}

export default new GroupService();

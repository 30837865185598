import React, { PropsWithChildren } from 'react';
import Card from 'components/Card';
import { Container, Title } from './styles';

interface IProps {
	title: string;
	shadow?: boolean;
	className?: string;
	style?: any;
}

export default ({ title, shadow, children, ...props }: PropsWithChildren<IProps>) => {
	const content = (
		<Container {...props}>
			<Title>{title}</Title>
			{children}
		</Container>
	);
	return shadow ? <Card>{content}</Card> : content;
};

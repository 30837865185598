import { connectRouter, RouterState } from 'connected-react-router';
import { Reducer } from 'redux';
import { Location } from 'history';
import { history } from 'services/router';
import { Actions } from 'store/actions';

export type IState = RouterState;

export const initialState: IState = {
	location: history.location,
	action: history.action,
};

type RouterReducer = Reducer<IState, Actions>;

export const reducer = connectRouter(history) as RouterReducer;

export const getLocation = (s: IState) => s.location;
export const getPathname = (s: Location) => s.pathname;
export const getSearch = (s: Location) => s.search;

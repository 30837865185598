import { createSelector } from 'reselect';
import * as admin from 'store/reducers/admin';
import { IState } from 'store/reducers';

const getAdminDefaultState = (s: IState) => s.admin;

export const getAdmins = createSelector(
	getAdminDefaultState,
	admin.getAdmins,
);

export const getLoading = createSelector(
	getAdminDefaultState,
	admin.getLoading,
);

export const getCreated = createSelector(
	getAdminDefaultState,
	admin.getCreated,
);

export const getUpdated = createSelector(
	getAdminDefaultState,
	admin.getUpdated,
);

export const getDeleted = createSelector(
	getAdminDefaultState,
	admin.getDeleted,
);

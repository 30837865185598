import React from 'react';
import logo from 'assets/logo.svg';
import { Layout, Logo, Title } from './styles';
import ConfirmActivationForm from 'modules/ConfirmActivation';

export default () => (
	<Layout>
		<Logo src={logo} />
		<Title>Activate your account!</Title>
		<ConfirmActivationForm />
	</Layout>
);

import ApiService from 'services/api';
import { ISummaryResponse } from 'types/summary';
import { getRequestedDateQueries } from 'utils/dateQueries';

class SummaryService {
	loadSummary(): Promise<ISummaryResponse> {
		const query = getRequestedDateQueries({ months: 1 });
		return ApiService.get<ISummaryResponse>('admin/dashboard/summary', {
			query,
			authorized: true,
		});
	}
}

export default new SummaryService();

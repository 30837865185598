import { ThemedStyledProps, css } from 'styled-components';

export enum THEME {
	DARK = 'dark',
	LIGHT = 'light',
}

export const colors = {
	black: '#000',
	white: '#FFF',
	comet: '#595d6e',
	grey: '#74788d',
	brightGrey: '#575962',
	red: '#FD397A',
	blueDodgerLight: '#5d78ff',
	blueDodger: '#3758ff',
	blueRibbon: '#2a4eff',
	shipGray: '#434349',
	cornflowerBlue: '#6173FE',
	titanWhite: '#DFE3FF',
	whisper: '#f2f3f8',
	solitude: '#ebedf2',
	crusta: '#f87e51',
	feijoa: '#98d468',
	sunglow: '#ffc023',
	mistyRose: '#FFE3D8',
	carla: '#e3fcce',
	cornSilk: '#fff5dc',
	aluminium: '#868e96',
	kournikova: '#fcce54',
	goldenGrass: '#da9e21',
	mysin: '#ffb822',
	dimBlack: '#434349',
};

const breakpoints = {
	tablet: 768,
	desktop: 1024,
};

export const theme = {
	colors,
	breakpoints,
};

export type Theme = typeof theme & {
	name: THEME;
	isLight: boolean;
};

export type ThemeWithProps<P = {}> = ThemedStyledProps<P, Theme>;

export const getBackgroundColorByTheme = (th: THEME) =>
	th === THEME.LIGHT ? colors.white : colors.black;

export const mainBackground = ({ theme: _theme }: ThemeWithProps) => `
	background-color: ${getBackgroundColorByTheme(_theme.name)};
`;

export const atMobile = (code: string) => css`
	@media screen and (max-width: ${breakpoints.tablet - 1}px) {
		${code};
	}
`;

export const atTablet = (code: string) => css`
	@media screen and (min-width: ${breakpoints.tablet}px) {
		${code};
	}
`;

export const atDesktop = (code: string) => css`
	@media screen and (min-width: ${breakpoints.desktop}px) {
		${code};
	}
`;

import {
	IWithdrawRequestResponse,
	IUpdateWithdrawRequestParams,
	IUpdateWithdrawRequestResponse,
} from './../types/withdrawRequest';
import ApiService from 'services/api';

class WithdrawRequestService {
	getWithdrawRequests(): Promise<IWithdrawRequestResponse> {
		return ApiService.get<IWithdrawRequestResponse>(
			'admin/workspace/funds?limit=500&offset=1',
			{
				authorized: true,
			},
		);
	}

	updateWithdrawRequest(
		params: IUpdateWithdrawRequestParams,
	): Promise<IUpdateWithdrawRequestResponse> {
		return ApiService.put<IUpdateWithdrawRequestResponse>(
			'admin/workspace/funds',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}
}

export default new WithdrawRequestService();

import { Action } from 'redux';
import { IAuthPayload } from 'types/auth';
import {
	IForgotPasswordPayload,
	IForgotPasswordSuccessPayload,
	IConfirmForgotPasswordPayload,
	IConfirmForgotPasswordSuccessPayload,
	IConfirmActivationPayload,
	IConfirmActivationSuccessPayload,
} from 'types/forgotPassword';
import { IUser } from 'types/user';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';

export enum ActionTypes {
	AUTHORIZE = '[user] AUTHORIZE',
	AUTHORIZE_SUCCESS = '[user] AUTHORIZE_SUCCESS',
	AUTHORIZE_FAIL = '[user] AUTHORIZE_FAIL',
	FORGOT_PASSWORD = '[user] FORGOT_PASSWORD',
	FORGOT_PASSWORD_FAIL = '[user] FORGOT_PASSWORD_FAIL',
	FORGOT_PASSWORD_SUCCESS = '[user] FORGOT_PASSWORD_SUCCESS',
	CONFIRM_FORGOT_PASSWORD = '[user] CONFIRM_FORGOT_PASSWORD',
	CONFIRM_FORGOT_PASSWORD_FAIL = '[user] CONFIRM_FORGOT_PASSWORD_FAIL',
	CONFIRM_FORGOT_PASSWORD_SUCCESS = '[user] CONFIRM_FORGOT_PASSWORD_SUCCESS',
	CONFIRM_ACTIVATION = '[user] CONFIRM_ACTIVATION',
	CONFIRM_ACTIVATION_FAIL = '[user] CONFIRM_ACTIVATION_FAIL',
	CONFIRM_ACTIVATION_SUCCESS = '[user] CONFIRM_ACTIVATION_SUCCESS',
}

export class AuthorizeAction implements Action {
	readonly type = ActionTypes.AUTHORIZE;
	constructor(public payload: IAuthPayload) {}
}

export class AuthorizeSuccessAction implements Action {
	readonly type = ActionTypes.AUTHORIZE_SUCCESS;
	constructor(public payload: IUser) {}
}

export class AuthorizeFailAction implements Action {
	readonly type = ActionTypes.AUTHORIZE_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class ForgotPasswordAction implements Action {
	readonly type = ActionTypes.FORGOT_PASSWORD;
	constructor(public payload: IForgotPasswordPayload) {}
}

export class ForgotPasswordFailAction implements Action {
	readonly type = ActionTypes.FORGOT_PASSWORD_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class ForgotPasswordSuccessAction implements Action {
	readonly type = ActionTypes.FORGOT_PASSWORD_SUCCESS;
	constructor(public payload: IForgotPasswordSuccessPayload) {}
}

export class ConfirmForgotPasswordAction implements Action {
	readonly type = ActionTypes.CONFIRM_FORGOT_PASSWORD;
	constructor(public payload: IConfirmForgotPasswordPayload) {}
}

export class ConfirmForgotPasswordFailAction implements Action {
	readonly type = ActionTypes.CONFIRM_FORGOT_PASSWORD_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class ConfirmForgotPasswordSuccessAction implements Action {
	readonly type = ActionTypes.CONFIRM_FORGOT_PASSWORD_SUCCESS;
	constructor(public payload: IConfirmForgotPasswordSuccessPayload) {}
}

export class ConfirmActivationAction implements Action {
	readonly type = ActionTypes.CONFIRM_ACTIVATION;
	constructor(public payload: IConfirmActivationPayload) {}
}

export class ConfirmActivationFailAction implements Action {
	readonly type = ActionTypes.CONFIRM_ACTIVATION_FAIL;
	constructor(public payload: IErrorResponse | CommonError) {}
}

export class ConfirmActivationSuccessAction implements Action {
	readonly type = ActionTypes.CONFIRM_ACTIVATION_SUCCESS;
	constructor(public payload: IConfirmActivationSuccessPayload) {}
}

export type Actions =
	| AuthorizeAction
	| AuthorizeSuccessAction
	| AuthorizeFailAction
	| ForgotPasswordAction
	| ForgotPasswordFailAction
	| ForgotPasswordSuccessAction
	| ConfirmForgotPasswordAction
	| ConfirmForgotPasswordFailAction
	| ConfirmForgotPasswordSuccessAction
	| ConfirmActivationAction
	| ConfirmActivationFailAction
	| ConfirmActivationSuccessAction;

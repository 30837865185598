import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/stats';
import { CommonError } from 'types/common';
import { IErrorResponse } from 'types/api';
import {
	UsersStatsResponse,
	SignupsStatsResponse,
	SalesStatsResponse,
	RevenueStatsResponse,
	IHealthResponse,
} from 'types/stats';

type ErrorState = IErrorResponse | CommonError | null;

export interface IState {
	usersLoading: boolean;
	usersLoadingError: ErrorState;
	users: UsersStatsResponse | null;
	signupsLoading: boolean;
	signupsLoadingError: ErrorState;
	signups: SignupsStatsResponse | null;
	salesLoading: boolean;
	salesLoadingError: ErrorState;
	sales: SalesStatsResponse | null;
	revenueLoading: boolean;
	revenueLoadingError: ErrorState;
	revenue: RevenueStatsResponse | null;
	healthStats: IHealthResponse;
	healthStatsLoading: boolean;
}

export const initialState: IState = {
	usersLoading: false,
	usersLoadingError: null,
	users: null,
	signupsLoading: false,
	signupsLoadingError: null,
	signups: null,
	salesLoading: false,
	salesLoadingError: null,
	sales: null,
	revenueLoading: false,
	revenueLoadingError: null,
	revenue: null,
	healthStatsLoading: false,
	healthStats: {
		health: {
			totalRevenue: 0,
			totalSubscriptions: 0,
			totalUsers: 0,
			totalInAppPurchases: 0,
			communitiesCreated24hours: 0,
			communitiesCreatedInWeek: 0,
			communitiesCreatedInMonth: 0,
			communitiesCreatedAllTime: 0,
		},
	},
};

export const reducer = (state: IState = initialState, action: Actions): IState => {
	switch (action.type) {
		/* users */
		case ActionTypes.LOAD_USERS_STATS: {
			return { ...state, usersLoading: true, usersLoadingError: null };
		}
		case ActionTypes.LOAD_USERS_STATS_SUCCESS: {
			return { ...state, usersLoading: false, users: action.payload };
		}
		case ActionTypes.LOAD_USERS_STATS_FAIL: {
			return { ...state, usersLoading: false, usersLoadingError: action.payload };
		}

		/* signups */
		case ActionTypes.LOAD_SIGNUPS_STATS: {
			return { ...state, signupsLoading: true, signupsLoadingError: null };
		}
		case ActionTypes.LOAD_SIGNUPS_STATS_SUCCESS: {
			return { ...state, signupsLoading: false, signups: action.payload };
		}
		case ActionTypes.LOAD_SIGNUPS_STATS_FAIL: {
			return { ...state, signupsLoading: false, signupsLoadingError: action.payload };
		}

		/* signups */
		case ActionTypes.LOAD_HEALTH_STATS: {
			return { ...state, healthStatsLoading: true, signupsLoadingError: null };
		}
		case ActionTypes.LOAD_HEALTH_STATS_SUCCESS: {
			const {
				totalInAppPurchases,
				totalRevenue,
				totalSubscriptions,
				totalUsers,
				communitiesCreated24hours,
				communitiesCreatedInWeek,
				communitiesCreatedInMonth,
				communitiesCreatedAllTime,
			} = action.payload.health;
			return {
				...state,
				healthStatsLoading: false,
				healthStats: {
					health: {
						totalInAppPurchases,
						totalRevenue,
						totalSubscriptions,
						totalUsers,
						communitiesCreated24hours,
						communitiesCreatedInWeek,
						communitiesCreatedInMonth,
						communitiesCreatedAllTime,
					},
				},
			};
		}
		case ActionTypes.LOAD_HEALTH_STATS_FAIL: {
			return { ...state, healthStatsLoading: false };
		}

		/* sales */
		case ActionTypes.LOAD_SALES_STATS: {
			return { ...state, salesLoading: true, salesLoadingError: null };
		}
		case ActionTypes.LOAD_SALES_STATS_SUCCESS: {
			return { ...state, salesLoading: false, sales: action.payload };
		}
		case ActionTypes.LOAD_SALES_STATS_FAIL: {
			return { ...state, salesLoading: false, salesLoadingError: action.payload };
		}

		/* revenue */
		case ActionTypes.LOAD_REVENUE_STATS: {
			return { ...state, revenueLoading: true, revenueLoadingError: null };
		}
		case ActionTypes.LOAD_REVENUE_STATS_SUCCESS: {
			return { ...state, revenueLoading: false, revenue: action.payload };
		}
		case ActionTypes.LOAD_REVENUE_STATS_FAIL: {
			return { ...state, revenueLoading: false, revenueLoadingError: action.payload };
		}
	}
	return state;
};

export const usersLoading = (s: IState) => s.usersLoading;
export const usersLoadingError = (s: IState) => s.usersLoadingError;
export const users = (s: IState) => s.users;
export const usersTotal = (s: IState['users']) => s && s.total;
export const usersData = (s: IState['users']) => s && s.byDay;

export const signupsLoading = (s: IState) => s.signupsLoading;
export const signupsLoadingError = (s: IState) => s.signupsLoadingError;
export const signups = (s: IState) => s.signups;
export const signupsTotal = (s: IState['signups']) => s && s.total;
export const signupsAbandonTotal = (s: IState['signups']) => s && s.abandonedSignups;
export const signupsDayData = (s: IState['signups']) => s && s.byDay;
export const signupsAbandonData = (s: IState['signups']) => s && s.abandonedSignupsByDay;

export const salesLoading = (s: IState) => s.salesLoading;
export const salesLoadingError = (s: IState) => s.salesLoadingError;
export const sales = (s: IState) => s.sales;
export const salesTotal = (s: IState['sales']) => s && s.total;
export const salesData = (s: IState['sales']) => s && s.byDay;

export const revenueLoading = (s: IState) => s.revenueLoading;
export const revenueLoadingError = (s: IState) => s.revenueLoadingError;
export const revenue = (s: IState) => s.revenue;
export const revenueTotal = (s: IState['revenue']) => s && s.total;
export const revenueData = (s: IState['revenue']) => s && s.byDay;
export const healthStatsLoading = (s: IState) => s && s.healthStatsLoading;
export const healthStats = (s: IState) => s && s.healthStats;

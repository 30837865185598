import AWS from 'aws-sdk';
import {
	ACCESS_KEY_ID,
	SECRET_ACCESS_KEY,
	REGION,
	VYOO_S3_BUCKET_URL,
	VYOO_S3_CLOUD_FRONT_URL,
	BUCKET_NAME,
} from '../../src/@constants';
import to from 'await-to-js';

AWS.config.update({
	accessKeyId: ACCESS_KEY_ID,
	secretAccessKey: SECRET_ACCESS_KEY,
	region: REGION,
});

export const handleUpload = async (file: any): Promise<any> => {
	const fileName = file.name;
	const upload = new AWS.S3.ManagedUpload({
		params: {
			Bucket: BUCKET_NAME,
			Key: 'admin/' + fileName,
			Body: file,
			ACL: 'public-read',
		},
	});
	const [err, result] = await to(upload.promise());
	if (err) return { url: null };
	const url = result && result.Location.replace(VYOO_S3_BUCKET_URL, VYOO_S3_CLOUD_FRONT_URL);
	if (result) return { url };
};

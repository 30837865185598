import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { IState as IStoreState } from 'store/reducers';
import { connect } from 'react-redux';
import { Container } from 'scenes/Users/styles';
import DashboardSceneHeader from 'modules/Dashboard/DashboardSceneHeader';
import SubHeader from 'components/SubHeader';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';

import trash from 'assets/trash.png';
import useredit from 'assets/useredit.svg';
import { UtilIcon } from 'modules/User/Tables/UserListing/styles';
// import { LoadWorkspacesAction } from 'store/actions/workspace';
import { getAdmins, getLoading } from 'store/selectors/admin';
import { CircleSpinner } from 'react-spinners-kit';
import ContainerCenter from 'components/ContainerCenter';
import { theme } from 'utils/theme';
import { Notice } from 'modules/Dashboard/Widgets/NewUsersWidget/styles';
import { fetchAllAdmins, DeleteAdminAction } from 'store/actions/admin';
import { IDeleteAdminPayload } from 'types/admin';

interface IProps {}

interface IStoreProps {
	loading: ReturnType<typeof getLoading>;
	admins: ReturnType<typeof getAdmins>;
}

interface IDispatchProps {
	fetchAllAdmins: () => void;
	deleteAdmin: (param: IDeleteAdminPayload) => void;
}

interface IProps extends IStoreProps, IDispatchProps, RouteComponentProps {}

class WorkspaceScene extends Component<IProps> {
	componentDidMount() {
		this.props.fetchAllAdmins();
	}

	public renderActionsButton = (_id: string) => {
		return (
			<div>
				<Button style={{ minWidth: 0 }}>
					<UtilIcon
						src={useredit}
						onClick={() => {
							this.props.history.push('/edit/admin/' + _id);
						}}
					/>
				</Button>
				<Button style={{ minWidth: 0 }}>
					<UtilIcon src={trash} onClick={() => this.props.deleteAdmin({ _id })} />
				</Button>
			</div>
		);
	};

	private renderContent = () => {
		const { loading, admins } = this.props;
		if (loading) {
			return (
				<ContainerCenter>
					<CircleSpinner size={25} color={theme.colors.cornflowerBlue} />
				</ContainerCenter>
			);
		}
		if (!admins || !admins.length) {
			return (
				<ContainerCenter>
					<Notice>Don't have any workspaces</Notice>
				</ContainerCenter>
			);
		}
		return this.workSpacesList();
	};

	private workSpacesList = () => {
		const { admins } = this.props;
		return (
			<Paper style={{ width: '100%', flex: 1, display: 'flex' }}>
				<TableContainer style={{ flex: 1, display: 'flex' }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell style={{ paddingLeft: 0, minWidth: 170 }} align="left">
									Email
								</TableCell>
								<TableCell style={{ minWidth: 100 }} align="left">
									Workspace
								</TableCell>
								<TableCell style={{ minWidth: 150 }} align="center">
									Active
								</TableCell>
								<TableCell style={{ minWidth: 150 }} align="center">
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{admins.map((item: any) => (
								<TableRow>
									<TableCell align="left">{item.email}</TableCell>
									<TableCell align="left">
										{!item.workspace ? 'Global' : item.workspace}
									</TableCell>
									<TableCell align="left">{item.isActive.toString()}</TableCell>
									<TableCell align="center">
										{this.renderActionsButton(item._id)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		);
	};

	render() {
		return (
			<Container>
				<div style={{ borderBottomWidth: 2, borderColor: '#f2f3f8', borderStyle: 'solid' }}>
					<DashboardSceneHeader title={''} shadow={false} style={{ flex: 1 }} />
				</div>
				<div style={{ width: '100%' }}>
					<SubHeader
						title={'Admins'}
						link={'add/admin'}
						buttonText={'Add admin'}
						// onPressButton={() => updateProductSaveType('new')}
					/>
				</div>
				{this.renderContent()}
			</Container>
		);
	}
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getLoading(state),
		admins: getAdmins(state),
	}),
	dispatch => ({
		fetchAllAdmins: () => dispatch(new fetchAllAdmins()),
		deleteAdmin: (param: IDeleteAdminPayload) => dispatch(new DeleteAdminAction(param)),
	}),
)(withRouter(WorkspaceScene));

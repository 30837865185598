import styled from 'styled-components';

export const ProductContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 523px;
	height: 465px;
	background-color: white;
	margin-left: 20px;
	margin-top: 10px;
	box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
`;

import { Reducer } from 'redux';
import { Actions } from 'store/actions';
import { ActionTypes } from 'store/actions/withdrawRequest';
import { IWithdrawRequestPayload } from 'types/withdrawRequest';

export interface IState {
	withdrawRequests: IWithdrawRequestPayload[];
	loading: boolean;
}

export const initialState: IState = {
	withdrawRequests: [],
	loading: false,
};

export const reducer: Reducer<IState, Actions> = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LOAD_WITHDRAW_REQUESTS:
			return { ...state, loading: true };
		case ActionTypes.LOAD_WITHDRAW_REQUESTS_SUCCESS:
			return {
				...state,
				loading: false,
				withdrawRequests: action.payload.funds,
			};
		case ActionTypes.LOAD_WITHDRAW_REQUESTS_FAIL:
			return { ...state, loading: false };
		case ActionTypes.UPDATE_WITHDRAW_REQUEST:
			return { ...state, loading: true };
		case ActionTypes.UPDATE_WITHDRAW_REQUEST_SUCCESS:
			return { ...state, loading: false };
		case ActionTypes.UPDATE_WITHDRAW_REQUEST_FAIL:
			return { ...state, loading: false };
	}
	return state;
};

export const getWithdrawRequests = (s: IState) => s.withdrawRequests;
export const getLoading = (s: IState) => s.loading;

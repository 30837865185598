import {
	LoadWithdrawRequestsAction,
	LoadWithdrawRequestsFailAction,
	LoadWithdrawRequestsSuccessAction,
	UpdateWithdrawRequestActionFailAction,
	UpdateWithdrawRequestAction,
	UpdateWithdrawRequestActionSuccessAction,
	ActionTypes,
} from './../actions/withdrawRequest';
import toast from 'cogo-toast';
import { ofType, Epic } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Actions } from 'store/actions';
import { IState } from 'store/reducers';
import { IErrorResponse } from 'types/api';
import { CommonError } from 'types/common';
import WithdrawRequestService from 'services/withdrawRequest';

export const getWithdrawRequests: Epic<
	Actions,
	LoadWithdrawRequestsSuccessAction | LoadWithdrawRequestsFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, LoadWithdrawRequestsAction>(ActionTypes.LOAD_WITHDRAW_REQUESTS),
		switchMap(() =>
			from(WithdrawRequestService.getWithdrawRequests()).pipe(
				map(response => {
					return new LoadWithdrawRequestsSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new LoadWithdrawRequestsFailAction(error));
				}),
			),
		),
	);

export const updateWithdrawRequest: Epic<
	Actions,
	UpdateWithdrawRequestActionSuccessAction | UpdateWithdrawRequestActionFailAction,
	IState
> = action$ =>
	action$.pipe(
		ofType<Actions, UpdateWithdrawRequestAction>(ActionTypes.UPDATE_WITHDRAW_REQUEST),
		switchMap(action =>
			from(WithdrawRequestService.updateWithdrawRequest(action.params)).pipe(
				map(response => {
					toast.success('Withdraw request status updated successfully', {
						position: 'top-right',
					});
					return new UpdateWithdrawRequestActionSuccessAction(response);
				}),
				catchError((error: IErrorResponse | CommonError) => {
					const errorMessage = typeof error === 'string' ? error : error.message;
					toast.error(errorMessage, {
						heading: 'An error has occurred',
						position: 'top-right',
					});
					return of(new UpdateWithdrawRequestActionFailAction(error));
				}),
			),
		),
	);

export default [getWithdrawRequests, updateWithdrawRequest];

import { createSelector } from 'reselect';
import * as user from 'store/reducers/user';
import { IState } from 'store/reducers';

const getUserState = (s: IState) => s.user;

export const getAuthorized = createSelector(
	getUserState,
	user.getAuthorized,
);

export const getAuthorization = createSelector(
	getUserState,
	user.getAuthorization,
);

export const getAuthorizationError = createSelector(
	getUserState,
	user.getAuthorizationError,
);

export const getForgotPassword = createSelector(
	getUserState,
	user.getForgotPassword,
);

export const getForgotPasswordError = createSelector(
	getUserState,
	user.getForgotPasswordError,
);

export const getConfirmForgotPassword = createSelector(
	getUserState,
	user.getConfirmForgotPassword,
);

export const getConfirmForgotPasswordDone = createSelector(
	getUserState,
	user.getConfirmForgotPasswordDone,
);

export const getConfirmForgotPasswordError = createSelector(
	getUserState,
	user.getConfirmForgotPasswordError,
);

export const getConfirmActivation = createSelector(
	getUserState,
	user.getConfirmActivation,
);

export const getConfirmActivationDone = createSelector(
	getUserState,
	user.getConfirmActivationDone,
);

export const getConfirmActivationError = createSelector(
	getUserState,
	user.getConfirmActivationError,
);

const getInfo = createSelector(
	getUserState,
	user.getInfo,
);

export const getUsername = createSelector(
	getInfo,
	user.getUsername,
);

export const getName = createSelector(
	getInfo,
	user.getName,
);

export const getIsGlobal = createSelector(
	getInfo,
	user.isGlobal,
);

import { IProductResponse } from './../types/product';
import { CreateProductParams, IAppCreateProductResponse } from './../types/users';
import ApiService from 'services/api';

class ProductService {
	createProduct(params: CreateProductParams): Promise<IAppCreateProductResponse> {
		return ApiService.post<IAppCreateProductResponse>(
			'admin/products/create',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}
	updateProducts(params: CreateProductParams): Promise<IAppCreateProductResponse> {
		return ApiService.put<IAppCreateProductResponse>(
			'admin/products/update',
			{
				body: JSON.stringify(params),
			},
			{ authorized: true },
		);
	}
	loadProducts(): Promise<IProductResponse> {
		return ApiService.get<IProductResponse>('admin/products', {
			authorized: true,
		});
	}
}

export default new ProductService();

import styled, { css } from 'styled-components';

export const Container = styled.div`
	display: flex;
	padding: 1.1rem 0;
	border-bottom: ${({ theme }) => `1px solid ${theme.colors.solitude}`};
	border-style: dashed;
`;

const avatarCommonStyles = css`
	border-radius: 0.4rem;
	width: 2rem;
	height: 2rem;
	margin-right: 1rem;
`;

export const Avatar = styled.img`
	${avatarCommonStyles};
`;
export const AvatarPlaceholder = styled.div`
	${avatarCommonStyles};
	background-color: ${({ theme }) => theme.colors.titanWhite};
	color: ${({ theme }) => theme.colors.cornflowerBlue};
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const Name = styled.p`
	font-size: 0.8rem;
	font-weight: 600;
	line-height: 1.54;
	color: ${({ theme }) => theme.colors.brightGrey};
`;

export const Position = styled.p`
	font-size: 0.8rem;
	font-weight: 300;
	line-height: 1.54;
	color: ${({ theme }) => theme.colors.grey};
`;

export const PositionSubscription = styled.p`
	font-size: 0.8rem;
	font-weight: 300;
	padding: 0px;
	color: ${({ theme }) => theme.colors.grey};
`;

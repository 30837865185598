import React, { memo } from 'react';
import {
	GroupContainer,
	Name,
	Description,
	EditButtonArea,
	DescriptionArea,
	ImageArea,
} from './style';
import Avatar from '@material-ui/core/Avatar';
import { GroupsPayload } from 'types/group';
import { Link } from 'react-router-dom';
import { colors } from 'utils/theme';
import Button from '@material-ui/core/Button';
import { Edit } from 'components/ProductItem/style';

interface IProps {
	item: GroupsPayload;
	onPressItem: (e: any) => void;
}

export const GroupItem = memo<IProps>(({ item, onPressItem }) => {
	const { image, name, description, archived } = item;
	return (
		<GroupContainer>
			<div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
				<ImageArea>
					<Avatar src={image} style={{ width: 64, height: 64 }} />
				</ImageArea>
				<DescriptionArea>
					<Name style={{ color: archived ? 'red' : 'black' }}>{name}</Name>
					<Description>{description}</Description>
				</DescriptionArea>
				<EditButtonArea>
					<Link
						style={{ color: colors.white, textDecoration: 'none' }}
						to={'/add/subscription'}
					>
						<Button
							style={{ backgroundColor: '#6173fe', width: 104 }}
							onClick={() => {
								onPressItem(item);
							}}
						>
							<Edit>Edit</Edit>
						</Button>
					</Link>
				</EditButtonArea>
			</div>
		</GroupContainer>
	);
});

import styled from 'styled-components';

export const Profile = styled.div`
	display: flex;
	align-items: center;
`;

export const Greeting = styled.span`
	color: ${({ theme }) => theme.colors.shipGray};
	font-family: Poppins;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: #86889e;
	margin-right: 4px;
`;

export const Name = styled.span`
	font-family: Poppins;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #434349;
	margin-right: 4px;
`;

export const AvatarPlaceholder = styled.div`
	background-color: ${({ theme }) => theme.colors.titanWhite};
	color: ${({ theme }) => theme.colors.cornflowerBlue};
	border-radius: 3px;
	font-weight: 500;
	width: 35px;
	height: 35px;
	justify-content: center;
	align-items: center;
	display: flex;
`;

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { IState as IStoreState } from 'store/reducers';
import { UserContainer, UtilIcon, Badge } from './styles';
import { get } from 'lodash';
import {
	loadActiveUserAction,
	changeModalVisibility,
	MarkUserArchiveAction,
	changeSelectedItems,
	changeSelectedItemsArchiveValue,
	updateActiveUsers,
} from 'store/actions/users';
import {
	getActiveUsers,
	getUsersLoadingError,
	getUsersLoading,
	getModalState,
	getSelectedItemsArchiveValue,
	getSelectedItems,
	getSearchedData,
	getSearchedValue,
	getActivePageNumber,
	getPageLimit,
} from 'store/selectors/users';
import ContainerCenter from '../../../../components/ContainerCenter';
import { CircleSpinner } from 'react-spinners-kit';
import { theme } from 'utils/theme';
import { Notice } from 'modules/Dashboard/Widgets/NewUsersWidget/styles';
import Avatar from '@material-ui/core/Avatar';

import trash from 'assets/trash.png';
import edit from 'assets/useredit.svg';
import Checkbox from '@material-ui/core/Checkbox';
import { IActiveUser } from 'types/users';
import Tooltip from '@material-ui/core/Tooltip';
import * as _ from 'lodash';
import { Button } from '@material-ui/core';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Name } from 'modules/Dashboard/NewUsers/NewUsersListItem/styles';
import {
	CreateAdministratorGroupAction,
	CreateGroupMembershipAction,
	UpdateGroupMemberAction,
	FetchGroupBySlugAction,
} from 'store/actions/group';
import { getNodeBbGroupMembers } from 'store/selectors/group';

interface IProps {}

interface IStoreProps {
	loading: ReturnType<typeof getUsersLoading>;
	error: ReturnType<typeof getUsersLoadingError>;
	data: ReturnType<typeof getActiveUsers>;
	isModalOpen: ReturnType<typeof getModalState>;
	archivedValue: ReturnType<typeof getSelectedItemsArchiveValue>;
	selectedItems: ReturnType<typeof getSelectedItems>;
	searchedData: ReturnType<typeof getSearchedData>;
	searchedValue: ReturnType<typeof getSearchedValue>;
	page: ReturnType<typeof getActivePageNumber>;
	limit: ReturnType<typeof getPageLimit>;
	members: ReturnType<typeof getNodeBbGroupMembers>;
}

interface IDispatchProps {
	loadActiveUsers: (offset: number, limit: number) => void;
	changeModalVisibility: (visible: boolean) => void;
	changeSelectedItems: (ids: string[]) => void;
	changeSelectedItemsArchiveValue: (value: boolean | null) => void;
	MarkUserArchive: (userIds: string[], value: boolean) => void;
	updateActiveUsers: (data: IActiveUser[]) => void;
	createAdminGroup: (params: any) => void;
	createGroupMembershipAction: (params: any) => void;
	updateGroupMemberAction: (params: any) => void;
	FetchGroupBySlugAction: (params: any) => void;
}

interface IProps
	extends IStoreProps,
		IDispatchProps,
		RouteComponentProps<{ id?: string }, any, any> {}

interface IState {
	ids: any;
}

class UserListing extends PureComponent<IProps, IState> {
	state: IState = {
		ids: [],
	};

	componentDidMount() {
		this.props.loadActiveUsers(1, 10);
		this.props.FetchGroupBySlugAction({ slug: 'administrators' });
	}

	public renderActionsButton = (item: any) => {
		const { id, active, personaId } = item;
		const { page, limit, members } = this.props;
		const isAdmin = members.find(m => get(m, 'personaId', 0) === personaId);
		return (
			<div>
				<Button
					variant="contained"
					onClick={() => {
						if (isAdmin) {
							this.props.updateGroupMemberAction({
								slug: 'administrators',
								personaId: personaId,
								isMember: false,
								isOwner: false,
								isModerator: false,
								isPending: false,
							});
							setTimeout(
								() =>
									this.props.FetchGroupBySlugAction({
										slug: 'administrators',
									}),
								2000,
							);
							return;
						}
						this.props.createAdminGroup({ personaId });
						this.props.updateGroupMemberAction({
							slug: 'administrators',
							personaId: personaId,
							isMember: true,
							isOwner: true,
							isModerator: false,
							isPending: false,
						});
						setTimeout(
							() => this.props.FetchGroupBySlugAction({ slug: 'administrators' }),
							2000,
						);
					}}
				>
					{isAdmin ? 'Remove Admin' : 'Make admin'}
				</Button>
				<Button style={{ minWidth: 0 }}>
					<UtilIcon
						src={trash}
						onClick={() => {
							this.props.MarkUserArchive([id], active);
							this.props.loadActiveUsers(page + 1, limit);
						}}
					/>
				</Button>
				<Button style={{ minWidth: 0 }}>
					<UtilIcon
						src={edit}
						onClick={() => this.props.history.push('/edit/user', item)}
					/>
				</Button>
			</div>
		);
	};

	render() {
		return <UserContainer>{this.renderContent()}</UserContainer>;
	}

	private renderContent = () => {
		const { loading, data, searchedValue, searchedData } = this.props;
		const rows = searchedValue === '' ? data : searchedData;
		if (loading) {
			return (
				<ContainerCenter>
					<CircleSpinner size={25} color={theme.colors.cornflowerBlue} />
				</ContainerCenter>
			);
		}
		if (!rows || !rows.length) {
			return (
				<ContainerCenter>
					<Notice>Don't have any users</Notice>
				</ContainerCenter>
			);
		}
		return this.renderUserList();
	};

	private renderStatus = (active: boolean) => {
		const color = active ? '#98d468' : 'red';
		const text = active ? 'Active' : 'In Active';
		return (
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<Badge style={{ backgroundColor: color }} />
				<p style={{ color, marginLeft: 8 }}>{text}</p>
			</div>
		);
	};

	private updateUsersCheckboxState = (id: string, checked: boolean, data: IActiveUser[]) => {
		// const { data } = this.props;
		let user: any;
		user = data.find(u => u._id === id);
		if (user) {
			user.ischecked = checked;
		}
	};

	private onClickRowCheckbox = (id: string, active: boolean, checked: any) => {
		const {
			archivedValue,
			selectedItems,
			changeSelectedItemsArchiveValue,
			changeSelectedItems,
			searchedValue,
			data,
			searchedData,
		} = this.props;
		const rows = searchedValue === '' ? data : searchedData;
		if (archivedValue === null && !selectedItems.length) {
			changeSelectedItemsArchiveValue(active);
			changeSelectedItems([...selectedItems, id]);
			this.updateUsersCheckboxState(id, checked, rows);
			return;
		}
		if (active !== archivedValue || !selectedItems.length) {
			changeSelectedItemsArchiveValue(active);
			rows.forEach(i => {
				this.updateUsersCheckboxState(i._id, !checked, rows);
			});
			changeSelectedItems([id]);
			this.updateUsersCheckboxState(id, checked, rows);
			return;
		}
		if (id && !checked) {
			changeSelectedItems([...selectedItems.filter(i => i !== id)]);
			this.updateUsersCheckboxState(id, checked, rows);
			return;
		}
		if (id && archivedValue === active) {
			this.updateUsersCheckboxState(id, checked, rows);
			changeSelectedItems([...selectedItems, id]);
		}
	};

	private onSelectAllUsers = (checked: boolean) => {
		const {
			data,
			changeSelectedItems,
			changeSelectedItemsArchiveValue,
			selectedItems,
			searchedValue,
			searchedData,
		} = this.props;
		const rows = searchedValue === '' ? data : searchedData;
		const ids = rows.map(d => d._id);
		if (checked) {
			changeSelectedItems([...ids]);
			changeSelectedItemsArchiveValue(checked);
		}
		if (!checked) {
			changeSelectedItems([...selectedItems.filter(i => !ids.includes(i))]);
			changeSelectedItemsArchiveValue(null);
		}
		rows.forEach(d => {
			this.updateUsersCheckboxState(d._id, checked, rows);
		});
	};

	private renderUserList = () => {
		const { searchedValue, searchedData, data } = this.props;
		const enableCheckbox =
			_.chain(this.props.data)
				.map(d => d.active)
				.uniq()
				.value().length <= 1;
		const rows = searchedValue === '' ? data : searchedData;
		return (
			<Paper style={{ width: '100%', flex: 1, display: 'flex' }}>
				<TableContainer style={{ flex: 1, display: 'flex' }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell style={{ minWidth: 50 }} padding="checkbox">
									<Tooltip
										title={
											!enableCheckbox
												? "You can't select archive and non archive at same time"
												: ''
										}
										placement="top-start"
									>
										<div>
											<Checkbox
												value="checkedC"
												disabled={!enableCheckbox}
												onChange={(_, checked) =>
													this.onSelectAllUsers(checked)
												}
											/>
										</div>
									</Tooltip>
								</TableCell>
								<TableCell style={{ paddingLeft: 0, minWidth: 170 }} align="left">
									User
								</TableCell>
								<TableCell style={{ minWidth: 100 }} align="left">
									State
								</TableCell>
								<TableCell style={{ minWidth: 100 }} align="left">
									Signup city
								</TableCell>
								<TableCell style={{ minWidth: 80 }} align="left">
									Profile created
								</TableCell>
								<TableCell style={{ minWidth: 80 }} align="left">
									Status
								</TableCell>
								<TableCell style={{ minWidth: 120 }} align="left">
									Persona Id
								</TableCell>
								<TableCell style={{ minWidth: 150 }} align="center">
									Actions
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map(item => (
								<TableRow key={item.personaId}>
									<TableCell padding="checkbox">
										<Checkbox
											// value="checkedC"
											style={{ backgroundColor: 'transparent' }}
											checked={item.ischecked}
											onChange={(_, checked) =>
												this.onClickRowCheckbox(
													item._id,
													item.active,
													checked,
												)
											}
										/>
									</TableCell>
									<TableCell
										align="left"
										style={{
											paddingLeft: 0,
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<Avatar src={item.picture} />
										<Name style={styles.avatarImage}>{item.name}</Name>
									</TableCell>

									<TableCell align="left">{item.state}</TableCell>
									<TableCell align="left">{item.city}</TableCell>
									<TableCell align="left">{item.createdAt}</TableCell>
									<TableCell align="left">
										{this.renderStatus(item.active)}
									</TableCell>
									<TableCell align="left">{item.personaId}</TableCell>
									<TableCell align="center">
										{this.renderActionsButton(item)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		);
	};
}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getUsersLoading(state),
		error: getUsersLoadingError(state),
		data: getActiveUsers(state),
		isModalOpen: getModalState(state),
		archivedValue: getSelectedItemsArchiveValue(state),
		selectedItems: getSelectedItems(state),
		ids: [],
		searchedData: getSearchedData(state),
		searchedValue: getSearchedValue(state),
		page: getActivePageNumber(state),
		limit: getPageLimit(state),
		members: getNodeBbGroupMembers(state),
	}),
	dispatch => ({
		loadActiveUsers: (offset: number, limit: number) =>
			dispatch(new loadActiveUserAction({ offset, limit })),
		createAdminGroup: params => dispatch(new CreateAdministratorGroupAction(params)),
		createGroupMembershipAction: params => dispatch(new CreateGroupMembershipAction(params)),
		updateGroupMemberAction: params => dispatch(new UpdateGroupMemberAction(params)),
		FetchGroupBySlugAction: params => dispatch(new FetchGroupBySlugAction(params)),
		changeModalVisibility: limit => dispatch(changeModalVisibility(limit)),
		MarkUserArchive: (userIds: string[], value: boolean) =>
			dispatch(new MarkUserArchiveAction({ userIds, value })),
		changeSelectedItems: ids => dispatch(changeSelectedItems(ids)),
		changeSelectedItemsArchiveValue: value => dispatch(changeSelectedItemsArchiveValue(value)),
		updateActiveUsers: (data: IActiveUser[]) => dispatch(updateActiveUsers(data)),
	}),
)(withRouter(UserListing));

const styles = {
	avatarImage: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		paddingLeft: 16,
	},
};
